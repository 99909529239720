import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { AuthService } from '../../services/auth';
import { EventsService } from '../../services/events.service';
import * as moment from 'moment';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-dau-metrics',
  templateUrl: './dau-metrics.component.html',
  styleUrls: ['./dau-metrics.component.scss']
})
export class DauMetricsComponent implements OnInit {

  public charts: any = {
    monthly: {
      type: "ColumnChart",
      data: [] as any,
      columnNames: ["Month",'Active Users'],
      options: {
        hAxis: {
          title: "Month",
        },
        vAxis: {
          minValue: 0,
        },
        isStacked: true,
        colors: ["#1B1464"],
      },
      width: 1000,
      height: 450,
    },
  };

  public currentMonthName : string;
  public dateRangeForm: FormGroup;
  public maxDate = new Date();
  public minDate = new Date();
  public eventsList: any[] = [];

  constructor(
    public dataService: DataService,
    public authService: AuthService,
    public eventService: EventsService,
    private fb: FormBuilder,
  ) { }

  async ngOnInit() {

    this.initializeReportForm();     
    this.resetChartData();   
    this.eventsList = await this.eventService.getEventsList(false);
  }

  private initializeReportForm() {
    this.dateRangeForm = this.fb.group({
      startDate: [new Date(), Validators.required],
      endDate: [new Date(), Validators.required],
    });
  }

  public async getDataInRange() {
    this.currentMonthName = moment(this.dateRangeForm.get('startDate')!.value).format('MMMM YYYY') + ' to ' + moment(this.dateRangeForm.get('endDate')!.value).format('MMMM YYYY');
    this.getDAUMetrics(this.dateRangeForm.get('startDate')!.value, this.dateRangeForm.get('endDate')!.value);
  }

  public async resetChartData() {
    this.currentMonthName = moment().format('MMMM YYYY');
    this.dateRangeForm.get('startDate')!.setValue(moment().startOf('month').format('DD-MM-YYYY'));
    this.dateRangeForm.get('endDate')!.setValue(new Date());
    this.getDAUMetrics(moment().startOf('month'), moment().endOf('month'));
  }

  /**
   * getDAUMetrics
   */
  public async getDAUMetrics(startDateRange : any, endDateRange : any) {

    this.charts.monthly.data = [];
      
    const params = {
      entityId: this.authService.getUser().entityId.objectId,
      startDate: startDateRange,
      endDate: endDateRange,
    }

    const activeUsersResponseList = await this.dataService.postToServer(
      "functions/getActiveUsersMetrics",
      params
    );

    activeUsersResponseList.result.forEach((obj: any) => {
      this.charts.monthly.data.push([moment(obj.objectId).format('MMM-DD'), obj.count])
    })
     
  }

}
