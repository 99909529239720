import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {HelperService} from './helper.service';
import {Injectable} from '@angular/core';
import 'rxjs/add/operator/do';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';

@Injectable()
export class CommonInterceptor implements HttpInterceptor {
  constructor(private helperService: HelperService, private route: Router) {
  }

  public intercept<TKey extends keyof {}>(
    req: HttpRequest<{}>,
    next: HttpHandler,
  ): Observable<HttpEvent<{}>> {
    this.helperService.setHeaders('Accept', 'application/json');
    this.helperService.setHeaders('X-Parse-Application-Id', environment.appId);
    this.helperService.setHeaders(
      'X-Parse-REST-API-Key',
      environment.restApiKey,
    );
    const localUser = sessionStorage.getItem('user') || localStorage.getItem('user') || '{}';
    const user = JSON.parse(localUser);
    if (user && user.sessionToken) {
      this.helperService.setHeaders(
        'X-Parse-Session-Token',
        user.sessionToken,
      );
    }
    const httpHeaders = new HttpHeaders(this.helperService.getHeaders());

    let httpParams = new HttpParams();
    const paramsData: object = this.helperService.getParams();

    Object.keys(paramsData).forEach(key => {
      const params =
        typeof paramsData[key as TKey] !== 'object'
          ? paramsData[key as TKey]
          : JSON.stringify(paramsData[key as TKey]);
      httpParams = httpParams.append(key, params);
    });

    const reqCopy = req.clone({headers: httpHeaders, params: httpParams});

    return next.handle(reqCopy).do(
      (event: HttpEvent<{}>) => {
        if (event instanceof HttpResponse) {
          // Do Nothing
        }
      },
      (err: HttpErrorResponse) => {
        if (err instanceof HttpErrorResponse) {
          if (err.error.code === 401 || err.error.code === 403) {
            localStorage.clear();
            sessionStorage.clear();
            this.route.navigateByUrl('/login');
          }
        }
      },
    );
  }
}
