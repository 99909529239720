import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Fields} from '../../../../types/forms';

@Component({
  selector: 'radio',
  template: `
    <div [formGroup]="form" [class]="formField.radio_inline
">
      <label
        class="form-control-label"
        [ngClass]="formField.class"
        [attr.for]="formField.label"
      >
        {{ formField.label }}
      </label>
      <br/>
      <span class="form-check" [class]="formField.radioLabel" *ngFor="let opt of formField.options">
        <input
          class="form-check-input"
          type="radio"
          [name]="formField.name"
          [value]="opt.key"
          [checked]="opt.value"
          [formControlName]="formField.name"
          (change)="onChange($event)"
        />
        <label class="form-check-label">
          {{ opt.label }}
        </label>
      </span>
    </div>
  `,
})
export class RadioComponent {
  @Input() public formField: Fields;
  @Input() public form: FormGroup;

  public onChange(event: Event) {
    if (this.formField.onChange) {
      const radioValue = this.form.get(this.formField.name)!.value;
      this.formField.onChange(event, radioValue);
    }
  }
}
