import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../services/auth";

@Component({
  selector: 'app-mobilemenu',
  templateUrl: './mobilemenu.component.html',
  styleUrls: ['./mobilemenu.component.scss'],
})
export class MobilemenuComponent implements OnInit {
  public currentMenu: string;
  public isAdmin: boolean;
  public isSuperAdmin: boolean;
  constructor(
      private auth: AuthService,
      ) {}

  public ngOnInit() {
    this.currentMenu = 'Home';
    this.isAdmin = this.auth.isAdmin();
    this.isSuperAdmin = this.auth.isSuperAdmin();
  }
  public updateMenu(_menu: string) {
    this.currentMenu = _menu;
  }
}
