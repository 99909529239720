import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {DatatableSettings} from '../../types/datatables';
import {DataService} from '../../services/data.service';
import {Products} from '../../types/products';
import {UserService} from '../../services/user.service';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef, ModalDirective} from 'ngx-bootstrap/modal';
import {NotifierService} from 'angular-notifier';
import {DataTableDirective} from 'angular-datatables';
import { AuthService } from 'src/app/services/auth';
import { track } from '@inleads/event-logger';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
  @ViewChild(DataTableDirective) public datatableElement: DataTableDirective;
  @ViewChild('tableWrapper') public tableWrapperElement: ElementRef;
  @ViewChild('createProductModal') public createProductModal: ModalDirective;
  @ViewChild('editProductModal') public editProductModal: ModalDirective;
  public dtOptions: DatatableSettings = {};
  public dtInstance: DataTables.Api;
  public newProductForm: FormGroup;
  public editProductForm: FormGroup;
  public modalRef: BsModalRef;
  public isLoading: boolean;
  public productsPageData: Products[] = [];
  public image: { url: ''; name: '' };
  public uploadDocumentName: string;
  public editUploadDocumentName: string;
  public currentProduct: string;
  public deleteModal = false;
  public products: Products;
  public disableFields: any;
  public profilePhotoLoader: boolean;
  public imgURL: any;
  public isPrivateUser:boolean;



  constructor(private dataService: DataService, private fb: FormBuilder, public userService: UserService, private notifier: NotifierService,public authService: AuthService) {
  }

  public ngOnInit() {
    this.isPrivateUser = this.authService.isPrivateUser();
    this.loadProducts();
    this.initializeProductForm();
    track('PRODUCTS');
  }

  private initializeProductForm() {
    this.newProductForm = this.fb.group({
      name: ['', Validators.required],
      // website: ['', Validators.required],
      description: ['', Validators.required],
      priceType: ['onetime', Validators.required],
      pricePeriod: ['', Validators.required],
      price: [, Validators.required],
      // noOfSeats: [, Validators.required],
    });
    this.editProductForm = this.fb.group({
      objectId: [''],
      name: ['', Validators.required],
      priceType: ['', Validators.required],
      pricePeriod: ['', Validators.required],
      price: [, Validators.required],
      // noOfSeats: [, Validators.required],
      // website: ['', Validators.required],
      description: ['', Validators.required],
    });
    // this.newProductForm = this.fb.group({
    //   insuranceName: ['', Validators.required],
    //   insuranceType: ['', Validators.required],
    //   planName: ['', Validators.required],
    //   productFeature: ['', Validators.required],
    //   productDocument: this.fb.array([]),
    //   ranking: ['', Validators.required],
    // });
    // this.editProductForm = this.fb.group({
    //   objectId: [''],
    //   insuranceName: ['', Validators.required],
    //   insuranceType: ['', Validators.required],
    //   planName: ['', Validators.required],
    //   productFeature: ['', Validators.required],
    //   productDocument: this.fb.array([]),
    //   ranking: ['', Validators.required],
    // });
  }

  public async loadProducts() {
    this.isLoading = true;
    let requestProductParams;
    requestProductParams = {
      limit: 10000,
      order: '-createdAt',
      where: {
        entity: { __type: 'Pointer', className: 'Entity', objectId: this.authService.getUser().entityId.objectId },
      },
    };
    const productResponse = await this.dataService.getFromServer(
      'classes/Products',
      requestProductParams,
    );
    this.productsPageData = productResponse.results || [];
    // this.imageURL = this.productsPageDa
    this.isLoading = false;

    this.dtOptions = {
      order: [[1, 'desc'], [3, 'desc']],
      dom: '<"appRequestsFilter" lf>tipr',
      columnDefs: [
        {
          orderable: false,
          // className: 'select-checkbox',
          targets: 0,
          responsivePriority: 1,
        },
        {
          responsivePriority: 1,
          targets: 3,
        },
        {
          responsivePriority: 2,
          targets: 2,
        },
        {
          responsivePriority: 1,
          targets: 1,
        },
        {
          defaultContent: 'NA',
          targets: '_all',
        },
      ],
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search...',
        lengthMenu: 'Show _MENU_',
      },
      responsive: true,
      data: this.productsPageData,
      columns: [
        {data: ''},
        {
          data: (row: Products) => {
            return row.name ? row.name : 'NA';
          },
        },
        // {
        //   data: (row: Products) => {
        //     return row.photo ? row.photo.url : 'NA';
        //   },
        // },
        {
          data: (row: Products) => {
            return row.priceType ? row.priceType : 'NA';
          },
        },
        {
          data: (row: Products) => {
            return row.pricePeriod ? row.pricePeriod : 'NA';
          },
        },
        {
          data: (row: Products) => {
            return row.price ? row.price : 'NA';
          },
        },
        // {
        //   data: (row: Products) => {
        //     return row.noOfSeats ? row.noOfSeats : 'NA';
        //   },
        // },
        {
          data: (row: Products) => {
            return row.description ? row.description : 'NA';
          },
        },
        {
          data: () => {
            return !this.isPrivateUser ? '<span class="fa fa-pencil"></span>&nbsp;&nbsp;&nbsp;&nbsp;<span class="fa fa-trash"></span>': null;
          },
        },
      ],
      rowCallback: (row: Node, data: object) => {
        row.removeEventListener('click', () => {
        });
        row.addEventListener('click', e => {
          if ((e.target as HTMLElement).classList.contains('fa-pencil')) {
             // @ts-ignore
            this.editProduct((data as Products).objectId);

            this.imgURL =null;
          }else if ((e.target as HTMLElement).classList.contains('fa-trash')) {
            // @ts-ignore
            this.deleteProduct((data as Products).objectId);
          }
        });
        return row;
      },
    };
    if (productResponse && productResponse.length) {
      setTimeout(this.generateFilters, 100);
    }

    // columns: [
    //   {data: ''},
    //   {
    //     data: (row: Products) => {
    //       return moment(row.createdAt).valueOf();
    //     },
    //   },
    //   {
    //     data: (row: Products) => {
    //       return row.insuranceName ? row.insuranceName : 'NA';
    //     },
    //   },
    //   {
    //     data: (row: Products) => {
    //       return row.insuranceType ? row.insuranceType : 'NA';
    //     },
    //   },
    //   {
    //     data: (row: Products) => {
    //       return row.planName ? row.planName : 'NA';
    //     },
    //   },
    //   {
    //     data: (row: Products) => {
    //       return row.productFeatures ? row.productFeatures : 'NA';
    //     },
    //   },
    //   {
    //     data: (row: Products) => {
    //       if (!row.productDocument || !row.productDocument.length) {
    //         return 'NA';
    //       }
    //       const documents: string[] = [];
    //       row.productDocument.map(doc => {
    //         documents.push(
    //           '<a href="' +
    //           doc +
    //           '" target="_blank" ><span class="fa fa-file-pdf-o"></span></a >',
    //         );
    //       });
    //       return documents.join(', ');
    //     },
    //   },
    //   {
    //     data: () => {
    //       return '<span class="fa fa-pencil"></span>&nbsp;&nbsp;&nbsp;&nbsp;<span class="fa fa-trash"></span>';
    //     },
    //   },
    // ]
  }

  private generateFilters = async () => {
    if (this.tableWrapperElement) {
      const filter = this.tableWrapperElement.nativeElement.querySelector(
        '.appRequestsFilter',
      );
      this.dtInstance = await this.datatableElement.dtInstance;
      const dtInstance = await this.datatableElement.dtInstance;
      filter.querySelectorAll('a').forEach((ele: HTMLElement) =>
        ele.addEventListener('click', (evt: Event) => {
          evt.preventDefault();
          const type = (evt.target as HTMLInputElement)!.dataset.type;
          dtInstance
            .column(6)
            .search(type!)
            .draw();
        }),
      );
    }
  };

  private createUploadDocuments(file: File): FormGroup {
    return this.fb.group({
      document: file,
    });
  }

  get documents(): FormArray {
    return this.newProductForm.get('productDocument') as FormArray;
  }

  public updateFile(event: Event) {
    const files = (event.target as HTMLInputElement).files;
    const filenames: string[] = [];
    if (files) {
      while (0 !== this.documents.length) {
        this.documents.removeAt(0);
      }
      Array.from(files).forEach(file => {
        filenames.push(file.name);
        this.documents.push(this.createUploadDocuments(file));
      });
      this.uploadDocumentName = filenames.join(', ');
    }
  }

  public addProduct() {
    this.createProductModal.show();
    this.imgURL = null;
    const inputElement = document.getElementById('fileInput') as HTMLInputElement;
    inputElement.value = '';
  }

  public async createProduct() {
    const name = this.newProductForm.get('name')!.value;
    // const website = this.newProductForm.get('website')!.value;
    const priceType = this.newProductForm.get('priceType')!.value;
    let pricePeriod = this.newProductForm.get('pricePeriod')!.value;
    const price = this.newProductForm.get('price')!.value;
    // const noOfSeats = this.newProductForm.get('noOfSeats')!.value;
    const description = this.newProductForm.get('description')!.value;

    if(!name){
      this.notifier.notify('error', 'Name cannot be empty!');
      return;
    }
    if(!pricePeriod && priceType === 'recurring'){
      this.notifier.notify('error', 'Price Period cannot be empty');
      return
    }
    if(priceType === 'onetime') {
      pricePeriod = '';
    }
    // if(!website){
    //   this.notifier.notify('error', 'Website cannot be empty!');
    //   return;
    // }
    // if(!description){
    //   this.notifier.notify('error', 'Description cannot be empty!');
    //   return;
    // }
    const productParams: Products = {
      objectId: '',
      name,
      priceType,
      pricePeriod,
      price,
      // noOfSeats,
      // website,
      description,
      entity: {
        __type: 'Pointer',
        className: 'Entity',
        objectId: this.authService.getUser().entityId.objectId,
      },
      website: '',
      // photo:{
      //   name: this.imgURL.name,
      //   url: this.imgURL.url,
      //   __type: 'File',
      // }
    };
    if (this.imgURL) {
      productParams.photo = {
        name: this.imgURL.name,
        url: this.imgURL.url,
        __type: 'File',
      };
    }
    const productResponse = await this.dataService.postToServer('classes/Products/', productParams);
    console.log(productParams);
    if(productResponse){
      this.notifier.notify('success', 'Product created Successfully!!!');
      this.newProductForm.reset();
      this.createProductModal.hide();
      this.loadProducts().then();
    }
    else{
      this.notifier.notify('error', 'Error while creating Product.');
      this.createProductModal.show();
    }
  }
  // public async createProduct() {
  //   const insuranceName = this.newProductForm.get('insuranceName')!.value;
  //   const insuranceType = this.newProductForm.get('insuranceType')!.value;
  //   const planName = this.newProductForm.get('planName')!.value;
  //   const productFeature = this.newProductForm.get('productFeature')!.value;
  //   const productDoc: Documents[] = this.newProductForm.get('productDocument')!.value;
  //   const ranking = this.newProductForm.get('ranking')!.value;

  //   const productParams: Products = {
  //     objectId: this.currentProduct,
  //     insuranceName,
  //     insuranceType,
  //     planName,
  //     ranking,
  //     productFeatures: productFeature,
  //     productDocument: [],
  //   };
  //   if (productDoc) {
  //     const promises = Array.from(productDoc).map(async doc => {
  //       const uploadedDocument = await this.documentService.uploadDocument(
  //         doc.document,
  //       );
  //       productParams.productDocument!.push(uploadedDocument.url);
  //       return uploadedDocument;
  //     });
  //     await Promise.all(promises);
  //   }
  //   const productResponse = await this.dataService.postToServer('classes/Products/', productParams);
  //   if(productResponse){
  //     this.notifier.notify('success', 'Product created Successfully!!!');
  //     this.createProductModal.hide();
  //     this.loadProducts().then();
  //   }
  //   else{
  //     this.notifier.notify('error', 'Error while creating Product.');
  //     this.createProductModal.show();
  //   }
  // }

  public async editProduct(productId : string){
    this.currentProduct = productId;
    this.editProductModal.show();
    const product = await this.dataService.getFromServer(
      'classes/Products/' + productId,
    );
    console.log(product)
    this.imgURL = null;
    this.imgURL = product.photo ? product.photo : null;
    this.editProductForm.patchValue({
      name: product.name || '',
      priceType: product.priceType || '',
      pricePeriod: product.pricePeriod || '',
      price: product.price,
      // noOfSeats: product.noOfSeats,
      // website: product.website || '',
      description: product.description || '',
    });
    // this.editProductForm.patchValue({
    //   insuranceName: product.insuranceName || '',
    //   insuranceType: product.insuranceType || '',
    //   planName: product.planName || '',
    //   productFeature: product.productFeatures || '',
    //   productDocument: product.productDocument || [],
    //   ranking:  product.ranking || '',
    // });
    // this.editUploadDocumentName = '';
  }

  get editDocuments(): FormArray {
    return this.editProductForm.get('productDocument') as FormArray;
  }

  public editUpdateFile(event: Event) {
    const files = (event.target as HTMLInputElement).files;
    const filenames: string[] = [];
    if (files) {
      while (0 !== this.editDocuments.length) {
        this.editDocuments.removeAt(0);
      }
      Array.from(files).forEach(file => {
        filenames.push(file.name);
        this.editDocuments.push(this.createUploadDocuments(file));
      });
      this.editUploadDocumentName = filenames.join(', ');
    }
  }

  public async updateProduct(){
    const objectId = this.currentProduct;
    const selectedProduct = this.productsPageData.find(
      p => p.objectId === objectId,
    );
    if (!selectedProduct) {
      return;
    }
    const name = this.editProductForm.get('name')!.value;
    const priceType = this.editProductForm.get('priceType')!.value;
    let pricePeriod = this.editProductForm.get('pricePeriod')!.value;
    const price = this.editProductForm.get('price')!.value;
    // const noOfSeats = this.editProductForm.get('noOfSeats')!.value;
    // const website = this.editProductForm.get('website')!.value;
    const description = this.editProductForm.get('description')!.value;
    if(!name){
      this.notifier.notify('error', 'Name cannot be empty!');
      return;
    }
    if(!pricePeriod && priceType === 'recurring'){
      this.notifier.notify('error', 'Price Period cannot be empty');
      return
    }
    if(priceType === 'onetime') {
      pricePeriod = '';
    }
    const productParams: Products = {
      objectId: this.currentProduct,
      name,
      priceType,
      pricePeriod,
      price,
      // noOfSeats,
      // website,
      description,
      website: '',
    };
    const productResponse = await this.dataService.updateToServer('classes/Products/' + this.currentProduct, productParams);
    if(productResponse){
      this.notifier.notify('success', 'Product Edited Successfully!!!');
      this.editProductForm.reset();
      this.editProductModal.hide();
    }
    else{
      this.notifier.notify('error', 'Error while editing Product.');
      this.editProductModal.show();
    }
    this.loadProducts().then();
    this.isLoading = true;
  }
  // public async updateProduct(){
  //   const objectId = this.currentProduct;
  //   const selectedProduct = this.productsPageData.find(
  //     p => p.objectId === objectId,
  //   );
  //   if (!selectedProduct) {
  //     return;
  //   }
  //   const insuranceName = this.editProductForm.get('insuranceName')!.value;
  //   const insuranceType = this.editProductForm.get('insuranceType')!.value;
  //   const planName = this.editProductForm.get('planName')!.value;
  //   const productFeature = this.editProductForm.get('productFeature')!.value;
  //   const productDoc: Documents[] = this.editProductForm.get('productDocument')!.value;
  //   const ranking = this.editProductForm.get('ranking')!.value;
  //   const productParams: Products = {
  //     objectId: this.currentProduct,
  //     insuranceName,
  //     insuranceType,
  //     planName,
  //     ranking,
  //     productFeatures: productFeature,
  //     productDocument: selectedProduct.productDocument || [],
  //   };
  //   if (productDoc) {
  //     const promises = Array.from(productDoc).map(async doc => {
  //       const uploadedDocument = await this.documentService.uploadDocument(
  //         doc.document,
  //       );
  //       productParams.productDocument!.push(uploadedDocument.url);
  //       return uploadedDocument;
  //     });
  //     await Promise.all(promises);
  //   }
  //   const productResponse = await this.dataService.updateToServer('classes/Products/' + this.currentProduct, productParams);
  //   if(productResponse){
  //     this.notifier.notify('success', 'Product Edited Successfully!!!');
  //     this.editProductModal.hide();
  //   }
  //   else{
  //     this.notifier.notify('error', 'Error while editing Product.');
  //     this.editProductModal.show();
  //   }
  //   this.loadProducts().then();
  //   this.isLoading = true;
  // }

  public async deleteProduct(productId: string) {
    this.deleteModal = true;
    this.currentProduct = productId;
  }

  public async clickYes() {
    const proId = this.currentProduct;
    try {
      await this.dataService.deleteOnServer(
        'classes/Products/' + proId,
      );
      this.deleteModal = false;
      this.productsPageData = this.productsPageData.filter(
        p => p.objectId !== proId,
      );
      await this.loadProducts().then();
      this.notifier.notify('success', 'Product deleted successfully');
    } catch (e) {
      this.notifier.notify('error', 'Something went wrong, please try again');
    }
  }

  public async onSelectFile(event: Event) {
    this.profilePhotoLoader = true;
    const files = (event.target as HTMLInputElement).files;
    if (files) {
      if((files[0].size / (1024 * 1024)) > 1){
        (event.target as HTMLInputElement).value = '';
        this.notifier.notify('error', 'Please select a file size less than 1 MB.');
        this.profilePhotoLoader = false;
        return;
      }
      else{
        this.imgURL = await this.dataService.uploadFile(files[0]);
        // const user = this.userService.getUser();
        localStorage.getItem('user');
        if(this.currentProduct){
          const productPhotoParams: any = {
          photo:{
            name: this.imgURL.name,
            url: this.imgURL.url,
            __type: 'File',
          },
          };
          await this.dataService.updateToServer('classes/Products/' + this.currentProduct, productPhotoParams);
        }
      }
    }
    this.profilePhotoLoader = false;
  }

  public decline() {
    this.deleteModal = false;
  }

  public isFieldValid(field: string, currentForm: FormGroup) {
    if (currentForm.get(field) && !this.disableFields) {
      return !currentForm.get(field)!.valid && currentForm.get(field)!.touched;
    }
    return;
  }

  public displayFieldCss(field: string, currentForm: FormGroup) {
    return {
      'has-error': this.isFieldValid(field, currentForm),
      'has-feedback': this.isFieldValid(field, currentForm),
    };
  }
}
