import { Injectable } from '@angular/core';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HelperService } from './helper.service';
import { environment } from '../../environments/environment';
import * as Parse from 'parse';

// @ts-ignore
Parse.initialize(environment.appId, environment.javaScriptKey);
(Parse as any).serverURL = environment.apiUrl;

@Injectable()
export class DataService {
  public httpHeaders = new HttpHeaders();

  public baseURL: string = environment.apiUrl;

  followUpSubscription : any;

  constructor(
    public http: HttpClient,
    private helperService: HelperService,
  ) {}

  public getFromServer = async (targetUrl: string, paramsData: object = {}) => {
    try {
      this.helperService.setParams(paramsData);
      const response = await this.http
        .get(this.baseURL + targetUrl)
        .toPromise();
      this.helperService.setParams({});
      return JSON.parse(JSON.stringify(response));
    } catch (e) {
      return;
    }
  };

  public postToServer = async (targetUrl: string, postData: {}) => {
    const response = await this.http
      .post(this.baseURL + targetUrl, postData)
      .toPromise();
    this.helperService.setParams({});
    return JSON.parse(JSON.stringify(response));
  };

  public updateToServer = async (targetUrl: string, postData: {}) => {
    this.helperService.setParams({});
    const response = await this.http
      .put(this.baseURL + targetUrl, postData)
      .toPromise();
    this.helperService.setParams({});
    return JSON.parse(JSON.stringify(response));
  };

  public deleteOnServer = async (
    targetUrl: string,
    postUrlData: {} = {},
    postData?: {},
  ) => {
    this.helperService.setParams(postUrlData);
    const response = await this.http
      .delete(this.baseURL + targetUrl, postData)
      .toPromise();
    this.helperService.setParams({});
    return JSON.parse(JSON.stringify(response));
  };

  public uploadFile = async (file: File) => {
    this.helperService.setParams({});
    this.helperService.setHeaders('Content-Type', file.type);
    const response = await this.http
      .post(this.baseURL + 'files/' + file.name, file)
      .toPromise();
    this.helperService.setParams({});
    return JSON.parse(JSON.stringify(response));
  };

  public uploadFiles = async (files: FileList): Promise<{ url: string, name: string }[]> => {
    const uploadedFiles = [];
  
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      this.helperService.setParams({});
      this.helperService.setHeaders('Content-Type', file.type);
      const response = await this.http
        .post(this.baseURL + 'files/' + file.name, file)
        .toPromise();
      this.helperService.setParams({});
      uploadedFiles.push({ url: JSON.parse(JSON.stringify(response)).url, name: file.name });
    }
  
    return uploadedFiles;
  };

  public updateHeaders(token: string) {
    this.httpHeaders = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('Accept', 'application/json');
  }

  public updateSessionHeaders(token: string) {
    this.helperService.setHeaders('X-Parse-Session-Token', token);
  }

  public cleanHeaders() {
    this.helperService.cleanHeaders();
  }

  public async getSpacesLiveQueryData() {
    const spacesObj = Parse.Object.extend('Spaces');
    const spacesQuery = new Parse.Query(spacesObj);
    // spacesQuery.equalTo('entity', {__type: 'Pointer', className: 'Entity', objectId: this.authService.getUser().entityId.objectId});

    const spacesSubscription = await spacesQuery.subscribe();
    spacesSubscription.on('create', async (space: any) => {
      this.helperService.setLiveQueryDataSpaces(space);
    });

    spacesSubscription.on('update', async (space: any) => {
      this.helperService.setLiveQueryDataSpaces(space);
    });
  }

  public async getFollowUpsLiveQueryData(entityId:string) {
    const followUpObj = Parse.Object.extend('FollowUps');
    const followUpQuery = new Parse.Query(followUpObj);
    followUpQuery.equalTo('entity', {__type: 'Pointer', className: 'Entity', objectId: entityId});
    if(this.followUpSubscription) this.followUpSubscription.unsubscribe();
    this.followUpSubscription = await followUpQuery.subscribe();

    this.followUpSubscription.on('open', () => {
      // console.log('open');
    });

    this.followUpSubscription.on('create', async (follow: any) => {
      this.helperService.setLiveQueryFollowUps(follow);
    });

    this.followUpSubscription.on('update', async (follow: any) => {
      this.helperService.setLiveQueryFollowUps(follow);
    });
  }

  public async eventsInfo(contactId: string) {
    try {
      const eventsObj = Parse.Object.extend('Events');
      const eventsQuery = new Parse.Query(eventsObj);
      // eventsQuery.exists('session');
      // eventsQuery.include('session');
      eventsQuery.equalTo('contact', {
        __type: 'Pointer',
        className: 'Contacts',
        objectId: contactId,
      });
      // eventsQuery.notEqualTo('session', {
      //   __type: 'Pointer',
      //   className: 'EventsSession',
      //   objectId: undefined,
      // });
      eventsQuery.descending('createdAt');
      const data = await eventsQuery.first();
      // if (data) {
      //   const v =  data.get('session');
      //   if(v) {
      //     return {...v.toJSON(), sessionId: data.get('session').get('sessionId'), lastSession: data.get('createdAt')};
      //   }
      // }
      return data;
    } catch (e) {
      console.log('error', e);
      return undefined;
    }
  }
}
