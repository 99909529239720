import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {Fields, FieldControl} from '../../../types/forms';

@Component({
    selector: 'form-builder',
    templateUrl: './form-builder.component.html',
})
export class FormBuilderComponent implements OnInit {
    @Output() public onSubmit = new EventEmitter();
    @Input() public fields: Fields[] = [];
    public form: FormGroup;

    constructor() {
    }

    public ngOnInit() {
        const fieldsControls: FieldControl = {};
        for (const f of this.fields) {
            if (f.type !== 'checkbox') {
                fieldsControls[f.name as keyof FieldControl] = new FormControl(
                    f.value || '',
                    Validators.required,
                );
            } else {
                const opts: FieldControl = {};
                if (f.options) {
                    for (const opt of f.options) {
                        opts[opt.key as keyof FieldControl] = new FormControl(opt.value);
                    }
                }
                fieldsControls[f.name] = new FormGroup(opts);
            }
        }
        this.form = new FormGroup(fieldsControls);
    }

    public onSubmitForm() {
      const form = this.form;
      if(!(form && form.valid)) {
        Object.keys(form.controls).forEach(key => {
          if(!form.controls[key].valid) {
            form.controls[key].markAsTouched();
            form.controls[key].markAsDirty();
          } else {
            form.controls[key].markAsPristine();
          }
        });
        form.updateValueAndValidity();
        return;
      }
      this.onSubmit.emit(this.form);
    }
}
