import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {DatatableSettings} from '../../types/datatables';
import {DataService} from '../../services/data.service';
import {Articles} from '../../types/articles';
import {UserService} from '../../services/user.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef, ModalDirective} from 'ngx-bootstrap/modal';
import {NotifierService} from 'angular-notifier';
import {DataTableDirective} from 'angular-datatables';
import { AuthService } from 'src/app/services/auth';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Categories } from '../../types/categories';
import { track } from '@inleads/event-logger';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent implements OnInit {

  @ViewChild(DataTableDirective) public datatableElement: DataTableDirective;
  @ViewChild('tableWrapper') public tableWrapperElement: ElementRef;
  @ViewChild('createArticleModal') public createArticleModal: ModalDirective;
  @ViewChild('editArticleModal') public editArticleModal: ModalDirective;
  @ViewChild('newCategory') public newCategoryModal?: ModalDirective;
  @ViewChild('newFileInput') newFileInput: any;
  @ViewChild('editFileInput') editFileInput: any;
  public dtOptions: DatatableSettings = {};
  public dtInstance: DataTables.Api;
  public newArticleForm: FormGroup;
  public editArticleForm: FormGroup;
  public newCategoryForm: FormGroup;
  public modalRef: BsModalRef;
  public isLoading: boolean;
  public articlesPageData: Articles[] = [];
  public currentArticle: string;
  public deleteModal = false;
  public articles: Articles;
  public disableFields: any;
  public categoryList: any[] = [];
  public categoryID: any;
  public generateText: any;
  public fileURL: any;
  public pdfValue: any;
  public fileName: any;
  public loadingArticle = false;
  public  MAX_FILE_SIZE_LIMIT: number = 5 * 1024 * 1024;
  htmlContent = '';

  config: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: 'p',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    sanitize: true,
    toolbarPosition: 'top',
    uploadUrl: 'v1/image',
    /*upload: (file: File) => { ... },
    uploadWithCredentials: false,
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]*/
  };

  constructor(private dataService: DataService, private fb: FormBuilder, public userService: UserService, private notifier: NotifierService,public authService: AuthService) {
  }

  public ngOnInit() {
    this.loadArticles();
    this.getCategories().then();
    this.initializeCategoryForm();
    this.initializeArticleForm();
    track("ARTICLES");
  }

  private initializeArticleForm() {
    this.newArticleForm = this.fb.group({
      title: ['', Validators.required],
      user: ['', Validators.required],
      entity: ['', Validators.required],
      category: [''],
      upload: [''],
      description: ['', Validators.required],
    });
    this.editArticleForm = this.fb.group({
      objectId: [''],
      title: ['', Validators.required],
      user: ['', Validators.required],
      entity: ['', Validators.required],
      category: [''],
      upload: [''],
      description: ['', Validators.required],
    });
  }

  private initializeCategoryForm() {
    this.newCategoryForm = this.fb.group({
      category_name: ['', Validators.required],
      category_description: ['', Validators.required],
    });
  }

  public async loadArticles() {
    this.isLoading = true;
    let requestArticleParams;
    requestArticleParams = {
      limit: 10000,
      order: '-createdAt',
      include: 'user,entity,category',
      where: {
        entity: { __type: 'Pointer', className: 'Entity', objectId: this.authService.getUser().entityId.objectId },
      },
    };
    const articleResponse = await this.dataService.getFromServer(
      'classes/Articles',
      requestArticleParams,
    );
    this.articlesPageData = articleResponse.results || [];
    this.isLoading = false;

    this.dtOptions = {
      order: [[2, 'desc'], [4, 'desc']],
      dom: '<"appRequestsFilter" lf>tipr',
      columnDefs: [
        {
          orderable: false,
          targets: 0,
          responsivePriority: 1,
        },
        {
          responsivePriority: 2,
          targets: 3,
        },
        {
          responsivePriority: 1,
          targets: 2,
        },
        {
          responsivePriority: 2,
          targets: 1,
        },
        {
          responsivePriority: 1,
          targets: 4,
        },
        {
          defaultContent: 'NA',
          targets: '_all',
        },
      ],
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search...',
        lengthMenu: 'Show _MENU_',
      },
      responsive: true,
      data: this.articlesPageData,
      columns: [
        {data: ''},
        {
          data: (row: Articles) => {
            return row.title ? row.title : 'NA';
          },
        },
        {
          data: (row: Articles) => {
            return row.category ? row.category.name : 'NA';
          },
        },
        {
          data: (row: Articles) => {
            return row.user ? row.user.name : 'NA';
          },
        },
        {
          data: (row: Articles) => {
            let file = row.fileUpload ? row.fileUpload!.url : '';
            let actionIcons;
            if(file) {
              actionIcons = '<span class="fa fa-download"></span>&nbsp;&nbsp;&nbsp;&nbsp;<span class="fa fa-pencil"></span>&nbsp;&nbsp;&nbsp;&nbsp;<span class="fa fa-trash"></span>';
            } else {
              actionIcons = '<a class="download-pdf"><span class="fa fa-download"></span></a>&nbsp;&nbsp;&nbsp;&nbsp;<span class="fa fa-pencil"></span>&nbsp;&nbsp;&nbsp;&nbsp;<span class="fa fa-trash"></span>'
            }
            return actionIcons;
          },
        },
      ],
      rowCallback: (row: Node, data: object) => {
        row.removeEventListener('click', () => { });
        row.addEventListener('click', e => {
          if ((e.target as HTMLElement).classList.contains('fa-download')) {
            window.open((data as Articles).fileUpload!.url, '_blank');
          }else if ((e.target as HTMLElement).classList.contains('fa-pencil')) {
            this.editArticle((data as Articles).objectId);
          }else if ((e.target as HTMLElement).classList.contains('fa-trash')) {
            this.deleteArticle((data as Articles).objectId);
          }
        });
        return row;
      },
    };
    if (articleResponse && articleResponse.length) {
      setTimeout(this.generateFilters, 100);
    }
  }

  private generateFilters = async () => {
    if (this.tableWrapperElement) {
      const filter = this.tableWrapperElement.nativeElement.querySelector(
        '.appArticlesFilter',
      );
      this.dtInstance = await this.datatableElement.dtInstance;
      const dtInstance = await this.datatableElement.dtInstance;
      filter.querySelectorAll('a').forEach((ele: HTMLElement) =>
        ele.addEventListener('click', (evt: Event) => {
          evt.preventDefault();
          const type = (evt.target as HTMLInputElement)!.dataset.type;
          dtInstance
            .column(6)
            .search(type!)
            .draw();
        }),
      );
    }
  };

  public async getCategories() {
    let categoriesQuery;
    if(!this.authService.isSuperAdmin()){
      categoriesQuery = {
        where: {
          entity: {
            __type: 'Pointer',
            className: 'Entity',
            objectId: this.authService.getUser().entityId.objectId,
          },
        },
      };
    }
    const resp = await this.dataService.getFromServer(
      'classes/Categories',
      categoriesQuery,
    );
    this.categoryList = resp.results;
  }

  public addCategory(event: Event) {
    event.preventDefault();
    this.newCategoryModal!.show();
  }

  public async createCategory(){
    const categoryName = this.newCategoryForm.get('category_name')!.value;
    const categoryDescription = this.newCategoryForm.get('category_description')!.value;
    if(!categoryName){
      this.notifier.notify('error', 'Name cannot be empty');
      return
    }
    if(!categoryDescription){
      this.notifier.notify('error', 'Description cannot be empty');
      return
    }
    const categoryParams: Categories = {
      name: categoryName,
      description: categoryDescription,
      entity: {
        __type: 'Pointer',
        className: 'Entity',
        objectId: this.authService.getUser().entityId.objectId,
      },
      objectId: '',
    };
    const categoryResponse = await this.dataService.postToServer('classes/Categories/', categoryParams);
    if(categoryResponse){
      this.notifier.notify('success', 'Category created Successfully!!!');
      this.categoryID = categoryResponse.objectId;
      this.newCategoryForm.reset();
      this.newCategoryModal!.hide();
    }
    this.getCategories();
    this.newArticleForm.get('category')!.setValue(categoryName);
  }

  public addArticle() {
    this.createArticleModal.show();
    this.newArticleForm.reset();
    this.pdfValue = '';
  }

  public async onFileChange(event: Event) {
    const files = (event.target as HTMLInputElement).files;
    if (files) {
      const file = files[0];
      if (file && file.size > this.MAX_FILE_SIZE_LIMIT) {
        (event.target as HTMLInputElement).value = '';
        this.notifier.notify('error', 'File size exceeds 5mb. Please select a file size less than 5 MB.!!!');
        return;
      }
      try {
        this.fileURL = await this.dataService.uploadFile(files[0]);
        let filePath;
        if(this.currentArticle){
          filePath = this.editFileInput.nativeElement.files[0].name;
        } else {
          filePath = this.newFileInput.nativeElement.files[0].name;
        }
        this.fileName = filePath.substring(filePath.lastIndexOf('\\') + 1);
        this.pdfValue = this.fileName;
      } catch (error) {
        // Handle error from uploadFile function
        console.error("Error uploading file:", error);
      }   
    }
  }

  public onDeleteFile() {
    if(this.currentArticle){
      this.editFileInput.nativeElement.value = '';
      this.pdfValue = '';
    } else {
      this.newFileInput.nativeElement.value = '';
      this.pdfValue = this.fileName;
    }
  }

  public async generateArticle() {
    this.loadingArticle = true;
    const sentence = this.newArticleForm.get('title')!.value;
    try {
    const articleData = await this.dataService.postToServer('functions/generateTextForArticle', {sentence});
    if (articleData.result.error) {
      this.notifier.notify('error', 'Please try again later or token exceeds!!');
    }
    this.generateText = articleData.result;
    this.newArticleForm.patchValue({
      description: this.generateText || '',
    });
  } catch (error) {
    console.error(error);
  } finally {
    this.loadingArticle = false;
  }
}

  public async createArticle() {
    const title = this.newArticleForm.get('title')!.value;
    const description = this.newArticleForm.get('description')!.value;
    let categoryId = this.newArticleForm.get('category')!.value;
    const fileUpload = this.newArticleForm.get('upload')!.value;
    if(this.categoryID){
      categoryId = this.categoryID;
    }

    if(!title){
      this.notifier.notify('error', 'Title cannot be empty!');
      return;
    }
    if(!categoryId){
      return this.notifier.notify('error', 'Please Select Category');
    }
    const articleParams:any = {
      objectId: '',
      title,
      description,
      entity: {
        __type: 'Pointer',
        className: 'Entity',
        objectId: this.authService.getUser().entityId.objectId,
      },
      user: {
        __type: 'Pointer',
        className: '_User',
        objectId: this.authService.getUser().objectId,
      },
      category: {
        __type: 'Pointer',
        className: 'Categories',
        objectId: categoryId,
      },
    };
    if(fileUpload) {
    articleParams.fileUpload = {
      name: this.fileURL.name,
      url: this.fileURL.url,
      __type: 'File',
    };
  }
    const articleResponse = await this.dataService.postToServer('classes/Articles/', articleParams);
    if(articleResponse){
      this.notifier.notify('success', 'Article created Successfully!!!');
      this.newArticleForm.reset();
      this.createArticleModal.hide();
      this.loadArticles().then();
    }
    else{
      this.notifier.notify('error', 'Error while creating Article.');
      this.createArticleModal.show();
    }
  }

  public async editArticle(articleId : string){
    this.currentArticle = articleId;
    this.editArticleModal.show();
    const article = await this.dataService.getFromServer(
      'classes/Articles/' + articleId,
    );
    const article_category: string = article && article.category ? article.category.objectId : '';
    this.pdfValue = article && article.fileUpload ? article.fileUpload!.name : '';
    this.pdfValue = this.pdfValue.split('_').pop();
    this.editArticleForm.patchValue({
      title: article.title || '',
      category: article_category || '',
      description: article.description || '',
    });
  }

  public async updateArticle(){
    const objectId = this.currentArticle;
    const selectedArticle = this.articlesPageData.find(
      p => p.objectId === objectId,
    );
    if (!selectedArticle) {
      return;
    }
    const title = this.editArticleForm.get('title')!.value;
    const categoryId = this.editArticleForm.get('category')!.value;
    const description = this.editArticleForm.get('description')!.value;
    if(!title){
      this.notifier.notify('error', 'Title cannot be empty!');
      return;
    }
    const articleParams:any = {
      objectId: this.currentArticle,
      title,
      category: {
        __type: 'Pointer',
        className: 'Categories',
        objectId: categoryId,
      },
      description,
      fileUpload: this.fileURL ? {
        name: this.fileURL.name,
        url: this.fileURL.url,
        __type: 'File',
      } : null,
    };
    const articleResponse = await this.dataService.updateToServer('classes/Articles/' + this.currentArticle, articleParams);
    if(articleResponse){
      this.notifier.notify('success', 'Article Edited Successfully!!!');
      this.editArticleForm.reset();
      this.pdfValue = '';
      this.editArticleModal.hide();
    }
    else{
      this.notifier.notify('error', 'Error while editing Article.');
      this.editArticleModal.show();
    }
    this.loadArticles().then();
    this.isLoading = true;
  }

  public async deleteArticle(articleId: string) {
    this.deleteModal = true;
    this.currentArticle = articleId;
  }

  public async clickYes() {
    const articleId = this.currentArticle;
    try {
      await this.dataService.deleteOnServer(
        'classes/Articles/' + articleId,
      );
      this.deleteModal = false;
      this.articlesPageData = this.articlesPageData.filter(
        p => p.objectId !== articleId,
      );
      await this.loadArticles().then();
      this.notifier.notify('success', 'Article deleted successfully');
    } catch (e) {
      this.notifier.notify('error', 'Something went wrong, please try again');
    }
  }

  public decline() {
    this.deleteModal = false;
  }

  public isFieldValid(field: string, currentForm: FormGroup) {
    if (currentForm.get(field) && !this.disableFields) {
      return !currentForm.get(field)!.valid && currentForm.get(field)!.touched;
    }
    return;
  }

  public displayFieldCss(field: string, currentForm: FormGroup) {
    return {
      'has-error': this.isFieldValid(field, currentForm),
      'has-feedback': this.isFieldValid(field, currentForm),
    };
  }

}
