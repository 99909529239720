import {Component, OnInit, ViewChild} from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { ModalDirective } from 'ngx-bootstrap';
import { AuthService } from 'src/app/services/auth';
import { DataService } from 'src/app/services/data.service';
import { Outreach } from 'src/app/types/outreach';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmailEditorComponent } from 'angular-email-editor';
import { generateImg } from "../../helpers/helper.date";

@Component({
  selector: 'app-outreach-template',
  templateUrl: './outreach-templates.component.html',
  styleUrls: ['./outreach-templates.component.scss'],
})
export class OutreachTemplateComponent implements OnInit {
  @ViewChild('newTemplateModal') public newTemplateModal: ModalDirective;
  @ViewChild(EmailEditorComponent) private emailEditor: EmailEditorComponent;
  isLoading: boolean = false;
  public newTemplateForm: FormGroup;
  public outreachTemplatesData: Outreach[] = [];
  public searchQuery: string = '';
  public filteredTemplatesData: any[] = [];
  public recordsPerPage: number = 10;
  public selectedItemIndex: number = 0;
  public disableFields: any;
  public emailSubject: any;
  public loadingMessage = false;
  public generateText: any;
  public templateId: string;
  public emailId: string;
  @ViewChild('template') public template?: ModalDirective;
  public existingHtmlData: string; // Holds the existing HTML data
  public existingEmailObjectData: any;


  htmlContent = '';

  exportHtml() {
    this.emailEditor.editor.exportHtml((data:any) => this.saveEmailTemplates(data));
  }

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: 'p',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
      {class: 'comic-sans-ms', name: 'Comic Sans MS'},
    ],
    customClasses: [
      {name: 'quote', class: 'quote'},
      {name: 'redText', class: 'redText'},
      {name: 'titleText', class: 'titleText', tag: 'h1'},
    ],
    sanitize: true,
    toolbarPosition: 'top',
    uploadUrl: 'v1/image',
  };

  constructor(
      private dataService: DataService,
      private fb: FormBuilder,
      private authService: AuthService,
      private notifier: NotifierService,
  ) {}

  ngOnInit() {
    this.initializeNewTemplateForm();
    this.loadOutreach().then(() => this.applySearch());
  }

  private async saveEmailTemplates(data: any) {
    try {
      const rawHtml = data.html;
      const fileName = this.newTemplateForm.get("templateNameVal")!.value || 'New Template';
      // Generate image
      const img = await generateImg(rawHtml, fileName.replace(/\s+/g, '')); // Pass the raw HTML string

      let templateParams = {
        templateName: this.newTemplateForm.get('templateNameVal')!.value,
        subject: this.newTemplateForm.get('emailSubject')!.value,
        message: data.html,
        emailDesign: data.design,
        fromUser: {
          __type: 'Pointer',
          className: '_User',
          objectId: this.authService.getUser().objectId,
        },
        entity:{
          __type: 'Pointer',
          className: 'Entity',
          objectId: this.authService.getUser().entityId.objectId,
        },
        ...(img && { thumbnail: img })
      };

      if (!templateParams.templateName) {
        this.notifier.notify('error', 'Please Enter Template Name!');
        return;
      }
      if (!templateParams.subject) {
        this.notifier.notify('error', 'Please Enter Template Subject!');
        return;
      }
      let templateResponse;
      if(this.templateId) {
        templateResponse = await this.dataService.updateToServer('classes/EmailTemplates/'+this.templateId, templateParams);
      } else {
        templateResponse = await this.dataService.postToServer('classes/EmailTemplates/', templateParams);
      }
        if (templateResponse) {
          this.notifier.notify('success', this.templateId ? 'Template updated successfully!' : 'Template saved successfully!');
          this.newTemplateForm.reset();
          this.newTemplateModal.hide();
          await this.loadOutreach();
          this.applySearch();
          this.templateId = '';
        } else {
          this.notifier.notify('error', 'Error while saving template.');
        }
    } catch (e) {
      console.log('error ', e);
    }
  }

  private initializeNewTemplateForm() {
    this.newTemplateForm = this.fb.group({
      templateNameVal: ['', Validators.required],
      emailSubject: ['', Validators.required],
      mailBody: ['', Validators.required],
    });
  }

  public async loadOutreach() {
    try {
      this.isLoading = true;
      const requestOutreachParams = {
        limit: 10000,
        order: '-createdAt',
        where: {
          $or: [
            { entity: { __type: 'Pointer', className: 'Entity', objectId: this.authService.getUser().entityId.objectId } },
            { entity: { __type: 'Pointer', className: 'Entity', objectId:'CzTXAOFEgY' }},
          ],
          // fromUser: { __type: 'Pointer', className: '_User', objectId: this.authService.getUser().objectId },
        },
      };
      const outreachResponse = await this.dataService.getFromServer('classes/EmailTemplates', requestOutreachParams);
      this.outreachTemplatesData = outreachResponse.results || [];
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      this.notifier.notify('error', e.error);
    }
  }

  public applySearch() {
    if (this.searchQuery.trim() === '') {
      this.filteredTemplatesData = this.outreachTemplatesData.slice(0, this.recordsPerPage);
    } else {
      this.filteredTemplatesData = this.outreachTemplatesData.filter((item: any) =>
          item.message.toLowerCase().includes(this.searchQuery.toLowerCase()),
      ).slice(0, this.recordsPerPage);
    }
  }

  public stripHTMLTags(html: string) {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  }

  public toggleMailContent(index: number) {
    this.selectedItemIndex = index;
  }

  public async deleteTemplate() {
    try {
      if(this.templateId) {
        await this.dataService.deleteOnServer(
            'classes/EmailTemplates/' + this.templateId,
        );
        await this.loadOutreach();
        $('#' + this.templateId).remove();
        await this.loadOutreach();
        this.applySearch();
        this.notifier.notify('success', 'Template deleted Successfully!!!');
      }
    } catch (e) {
      this.notifier.notify('error', 'Something went wrong, Please try again later.');
    }
    this.template!.hide();
  }

  public async editTemplateConfirm() {
    try {
      if (this.templateId) {
        this.newTemplateForm.reset();
        const template = await this.dataService.getFromServer(
            'classes/EmailTemplates/'+ this.templateId,
        );
        if(template) {
          this.newTemplateForm.setValue({
            templateNameVal: template.templateName || '',
            emailSubject: template.subject || '',
            mailBody: template.message || '',
          });
          this.existingHtmlData = template.message;
          this.existingEmailObjectData = template.emailDesign || {};
          this.loadExistingEmailTemplate(template.message);
        }
      }
    } catch (e) {
      this.notifier.notify('error', 'Something went wrong, Please try again later.');
    }
  }

  async loadExistingEmailTemplate(response:any) {
    try {
      this.existingHtmlData = response;
      this.setEditorContent(this.existingHtmlData);
    } catch (error) {
      console.error('Error loading email template:', error);
    }
  }

  // Set the HTML content in the editor
  setEditorContent(html: string) {
    if (this.emailEditor && this.emailEditor.editor) {
      try {
        if (html) {
          this.emailEditor.editor.loadDesign(this.existingEmailObjectData);
        } else {
          console.error('HTML data is empty or undefined');
        }
      } catch (error) {
        console.error('Error setting editor content:', error);
      }
    } else {
      console.error('EmailEditor instance or editor is not available');
    }
  }

  // Called when the editor is loaded
  editorLoaded() {
    if (this.emailEditor) {
      // Set the HTML content after the editor has loaded
      this.emailEditor.editor.loadDesign(this.existingEmailObjectData);
    }
  }

  public isFieldValid(field: string) {
    const formField = this.newTemplateForm.get(field);
    return formField && formField.invalid && (formField.dirty || formField.touched);
  }

  public displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field),
    };
  }

  public openNewTemplateForm() {
    this.templateId = '';
    this.newTemplateForm.reset();
    this.newTemplateModal.show();
  }

  public async generateMailBody() {
    this.loadingMessage = true;
    const sentence = 'create an email body without subject for ' + this.newTemplateForm.get('emailSubject')!.value;
    try {
      const mailBodyData = await this.dataService.postToServer('functions/generateTextForArticle', {sentence});
      this.generateText = mailBodyData.result;
      this.newTemplateForm.patchValue({
        mailBody: this.generateText || '',
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingMessage = false;
    }
  }

  // public async saveTemplate() {
  //   let templateParams: any = {
  //     templateName: this.newTemplateForm.get('templateNameVal')!.value,
  //     subject: this.newTemplateForm.get('emailSubject')!.value,
  //     message: this.newTemplateForm.get('mailBody')!.value,
  //   };
  //
  //   if (!templateParams.templateName || !templateParams.subject || !templateParams.message) {
  //     this.notifier.notify('error', 'All fields are required!');
  //     return;
  //   }
  //
  //   try {
  //     let templateResponse;
  //     if(this.templateId) {
  //       templateResponse = await this.dataService.updateToServer('classes/EmailTemplates/'+this.templateId, templateParams);
  //     } else {
  //       templateParams = {
  //         ...templateParams,  fromUser: {
  //           __type: 'Pointer',
  //           className: '_User',
  //           objectId: this.authService.getUser().objectId,
  //         },
  //       }
  //       templateResponse = await this.dataService.postToServer('classes/EmailTemplates/', templateParams);
  //     }
  //     if (templateResponse) {
  //       this.notifier.notify('success', this.templateId ? 'Template updated successfully!' : 'Template saved successfully!');
  //       this.newTemplateForm.reset();
  //       this.newTemplateModal.hide();
  //       await this.loadOutreach();
  //       this.applySearch();
  //       this.templateId = '';
  //     } else {
  //       this.notifier.notify('error', 'Error while saving template.');
  //     }
  //   } catch (error) {
  //     this.notifier.notify('error', 'Error while saving template.');
  //   }
  // }
}
