import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { UserService } from './user.service';
import {PostResponse} from '../types/common';
import {addLeadParams, Leads} from '../types/leads';

@Injectable({
  providedIn: 'root',
})
export class LeadsService {
  constructor(
    private dataService: DataService,
    private userService: UserService,
  ) {}

  public async getLeadById(_leadId: string, leadParams: any): Promise<Leads> {
    const lead = await this.dataService.getFromServer(`classes/Leads`,leadParams);
    return lead;
  }

  // tslint:disable-next-line:no-any
  public async leadRequests(leadParams: any): Promise<Leads[]> {
    const lead = await this.dataService.getFromServer(
      'classes/Leads',
      leadParams,
    );
    let filteredLeads: Leads[];
    if (this.userService.isSuperAdmin || this.userService.isAdmin || this.userService.isAgent || this.userService.isManager) {
      filteredLeads = lead.results;
    } else {
      filteredLeads = lead.results.filter((u: Leads) => {
        return (u.contact && u.contact!.objectId === this.userService.userId);
      });
    }
    return filteredLeads;
  }

  public async addLead(obj: addLeadParams): Promise<PostResponse> {
    const response = await this.dataService.postToServer(
      'classes/Leads',
      obj,
    );
    return response;
  }
}
