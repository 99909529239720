import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-dashboard-widget-header",
  templateUrl: "./dashboard-widget-header.component.html",
  styleUrls: ["./dashboard-widget-header.component.scss"],
})
export class DashboardWidgetHeaderComponent implements OnInit {
  @Input() widgetTitle: string;
  @Input() widgetType: string;
  public widgetIcon: string;

  constructor() {}

  ngOnInit() {
    if (this.widgetType == "engagedUsersMetrics") {
      this.widgetIcon =
        "../../../../assets/images/engaged-users-widget-icon.svg";
    }
    if (this.widgetType == "userSignupMetrics") {
      this.widgetIcon = "../../../../assets/images/user-signup-widget-icon.svg";
    }
    if (this.widgetType == "netPromoterScore") {
      this.widgetIcon = "../../../../assets/images/nps-widget-icon.svg";
    }
  }
}
