import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { UserService } from '../../services/user.service';
import { DataService } from '../../services/data.service';
import { AuthService } from '../../services/auth';
import { EmitterService } from '../../services/emitter.service';
import {tz} from 'moment-timezone';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { track } from '@inleads/event-logger';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  @Output() profileNameChange = new EventEmitter<string>();
  public isLoading: boolean;
  public editProfileForm: FormGroup;
  // tslint:disable-next-line:no-any
  public imgURL: any;
  public profilePhotoLoader: boolean;
  public user: any;
  public selectedTimeZone : any;
  public timezonesList : any;
  @ViewChild('template') public template?: ModalDirective;
  public isAdmin: boolean;
  public isConfirmed: boolean = false;

  constructor(
    private notifier: NotifierService,
    public userService: UserService,
    private dataService: DataService,
    private auth: AuthService,
    private router: Router,
  ) {}
  public ngOnInit() {
    this.isAdmin =  this.auth.isAdmin();
    // tslint:disable-next-line:no-this-assignment
    const currentObj = this;
    this.timezonesList = tz.names(); 

    this.editProfileForm = new FormGroup(
      {
        profileFormName: new FormControl('', [
          Validators.required,
        ]),
        profileFormEmail: new FormControl('', [
          Validators.required,
        ]),
        profileFormPhoneNumber: new FormControl('', []),
        timeZone: new FormControl('', [
          Validators.required,
        ]),
      },
    );

    const userResponse =  this.dataService.getFromServer('users/' + this.auth.getUser().objectId,{include: 'entityId'});
    userResponse.then((res) => {
      if(res){
        this.user = res;
        const name = res.name ? res.name : '';
        const email = res.email ? res.email : (res.username ? res.username : '');
        const phoneNumber = res.phoneNumber ? res.phoneNumber : '';
        this.selectedTimeZone = res.entityId && res.entityId.timeZone ? res.entityId.timeZone : '';
        
        if(res){
          currentObj.editProfileForm.setValue({
            profileFormEmail: email,
            profileFormName: name,
            profileFormPhoneNumber: phoneNumber,
            timeZone:this.selectedTimeZone
          });
        }
      }
    });

    const user = this.userService.getUser();
    this.imgURL = user!.profilePhoto;
  }

  public async saveProfile(event: Event){
    try {
      const name = this.editProfileForm.get('profileFormName')!.value;
      const phoneNumber = this.editProfileForm.get('profileFormPhoneNumber')!.value;
      const timeZone = this.selectedTimeZone;
      if(!name){
        this.notifier.notify('error', 'Name cannot be empty!');
        return;
      }
      if(!timeZone){
        this.notifier.notify('error', 'Timezone Cannot be empty');
        return;
      }
      if(this.user.entityId){
        const entityParams = {
          entityId: this.user.entityId.objectId,
          timeZone: this.selectedTimeZone,
          isProfileCompleted: true
        };
        await this.dataService.postToServer(
          'functions/updateEntityData',
          entityParams,
        );
      }
      //return;
      const data = {
        name,
        phoneNumber: parseInt(phoneNumber, 10)
      };
      const objectId = this.auth.getUser().objectId;
      if(objectId){
        const userUpdateRequestData = {
          objectId,
          ...data
        }
        const response = await this.userService.editUserSave(userUpdateRequestData);
        if (response) {
          this.notifier.notify('success', 'Profile Saved Successfully!!!');
        }
      }
    } catch (error) {
      this.notifier.notify('error', error + event);
    }
  }

  public async onSelectFile(event: Event) {
    this.profilePhotoLoader = true;
    const files = (event.target as HTMLInputElement).files;
    if (files) {
      if((files[0].size / (1024 * 1024)) > 1){
        (event.target as HTMLInputElement).value = '';
        this.notifier.notify('error', 'Please select a file size less than 1 MB.');
        this.profilePhotoLoader = false;
        return;
      }
      else{
        this.imgURL = await this.dataService.uploadFile(files[0]);
        const user = this.userService.getUser();
        user!.profilePhoto = this.imgURL;
        localStorage.setItem('user', JSON.stringify(user));
        sessionStorage.setItem('user', JSON.stringify(user));
        EmitterService.get('headerUpdate').emit(user);
        localStorage.getItem('user');
        const profileImage = {
          objectId: user!.objectId,
          profilePhoto: {
            name: this.imgURL.name,
            url: this.imgURL.url,
            __type: 'File',
          },
        };
        await this.userService.editUserSave(profileImage);
      }
    }
    this.profilePhotoLoader = false;
  }

  public async deleteUserAccount() {
    if (!this.isConfirmed) {
      this.notifier.notify('error', 'You must confirm that you understand the consequences before deleting your account.');
      return;
    }
    try{
      await this.dataService.cleanHeaders();
      localStorage.clear();
      sessionStorage.clear();
      this.template!.hide();
      await this.router.navigateByUrl('');
  
      await this.dataService.postToServer(
        'functions/deleteUserAccount',
        { objectId: this.user.objectId },
      );
      track('DELETE');
    }
    catch (error) {
      this.notifier.notify('error', 'Error deleting account');
      console.error('Error deleting account:', error);
    }
  }
}
