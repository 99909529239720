import {
  Component,
  OnInit,
  ElementRef,
  Renderer2,
  ViewChild,
  AfterViewInit,
  ViewEncapsulation,
  ChangeDetectorRef,
  HostListener, OnDestroy,
} from '@angular/core';
import {DataService} from '../../services/data.service';
import {AudioRecordingService} from 'src/app/services/audio-recording.service';
import {Subscription} from 'rxjs';
import {UserService} from 'src/app/services/user.service';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChatbotComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('dynamicContentContainer') public dynamicContentContainer: ElementRef;
  @ViewChild('chatLog') public chatLog: ElementRef;
  @ViewChild('audioControl') audioControl: ElementRef<HTMLAudioElement>;

  public isChatOpen: boolean;
  public showLoader: boolean;
  public disableSubmit: boolean;
  public aiInitMessage: string;
  public aiReplayMessage: string;
  public userQuery: string;
  public userInput: string;
  public threadId: string;
  private dynamicContentCount = 0;
  private aiResponseArray: string[] = [];
  private messageHistory: { flag: string, inputContent: string }[] = [];
  public prePromptsList = [];

  public userChoice: 'message-copy' | 'article' | '';
  public isListening: boolean = false;
  public audioSrc: string | null = null;
  public subscription!: Subscription;
  public botConfig: { name: string, initialPrompt: string, prePrompts: [] }

  // @ts-ignore
  constructor(private dataService: DataService,
              private renderer: Renderer2,
              private cdr: ChangeDetectorRef,
              private audioRecordingService: AudioRecordingService,
              private userService: UserService,) {
  }

  public ngOnInit() {
    localStorage.removeItem('chat_history');
    this.isChatOpen = false;
    this.showLoader = false;
    this.aiInitMessage = '';
    this.userInput = '';
    this.threadId = '';

    this.subscription = this.audioRecordingService.event$.subscribe(voiceInputStatus => {
      if (voiceInputStatus === 'stopped') {
        this.isListening = false;
      }
    });
    this.getBotConfig()
  }

  public async getBotConfig() {
    this.botConfig = await this.userService.getChatDocSetting();
    this.prePromptsList = this.botConfig.prePrompts;
  }

  public async startRecording() {
    this.isListening = !this.isListening;
    this.showLoader = true;
    this.disableSubmit = true;
    let resp = await this.audioRecordingService.startRecording();
    this.showLoader = false;
    this.disableSubmit = false;
    // @ts-ignore
    if (resp) {
      // @ts-ignore
      resp = resp.result;
      // @ts-ignore
      this.audioSrc = resp.respAudioBase64;
      this.cdr.detectChanges();
      // @ts-ignore
      this.userQuery = resp.inputText;
      this.dynamicContentHandler('userPrompt', this.userQuery)
      this.threadId = resp.threadId
      this.aiReplayMessage = resp.aiResponseContent;
      this.aiResponseArray.push(this.aiReplayMessage)
      this.dynamicContentHandler('aiResponse', this.formatResponse(this.aiReplayMessage))

      this.chatLog.nativeElement.scrollTop = this.chatLog.nativeElement.scrollHeight;
      this.cdr.detectChanges();

      this.playAudio();
    }


  }

  private playAudio() {

    if (this.audioControl && this.audioControl.nativeElement) {
      const audio: HTMLAudioElement = this.audioControl.nativeElement;
      // @ts-ignore
      audio.src = this.audioSrc; // Ensure this.audioSrc is the full base64 string
      audio.load();
      audio.play().then(() => {
        console.log('Playback started successfully');
      }).catch(error => {
        console.error('Error during playback:', error);
      });

    }
  }

  public stopRecording() {
    this.isListening = !this.isListening;
    this.audioRecordingService.stopRecording();
  }


  public openChatWindow() {
    this.isChatOpen = true;
    let history = localStorage.getItem('chat_history');
    history = history ? JSON.parse(history) : {};
    if (history) {

      Object.keys(history).forEach((key: string): void => {
        const value = (history as unknown as { [key: string]: any })[key];
        setTimeout(() => {
          this.dynamicContentHandler('' + value.flag, '' + value.inputContent)
        }, 100)
      });
    }
  }

  public onCloseChatBot() {

    localStorage.removeItem('chat_history');
    setTimeout(() => {
      localStorage.setItem('chat_history', JSON.stringify(this.messageHistory));
      this.messageHistory = []
    }, 100)

    this.isChatOpen = false;
    this.showLoader = false;
    /* this.aiInitMessage = '';
     this.userInput = '';
     this.userQuery = '';
     this.aiReplayMessage = '';*/
  }

  public ngAfterViewInit() {

  }

  @HostListener('click', ['$event'])
  public onClick(event: Event) {
    const target = event.target as HTMLElement;
    if (target && target.classList.contains('message-copy')) {
      const contentId = target.getAttribute('data-content-id');
      if (contentId) {
        this.copyMessage(+contentId);
      }
    }
  }


  public setInitAIMessage() {
    this.aiInitMessage = '';
    this.userInput = '';
    this.userQuery = '';
    this.aiReplayMessage = '';
    // @ts-ignore
    if (this.userChoice === 'email') {
      this.userInput = 'Generate warm email for your prospects';
    }
    if (this.userChoice === 'article') {
      this.userInput = 'Generate article';
    }
    // this.dynamicContentHandler('aiInitMessage', this.aiInitMessage)
  }

  public dynamicContentHandler(flag: string, inputContent: string) {

    this.messageHistory.push({flag, inputContent})
    let content = ''
    if (flag === 'aiInitMessage' || flag === 'userPrompt') {
      content = '<div class="user-message">\n' +
        '                <div class="user-message-text">' + inputContent + '</div>\n' +
        '            </div>'
    }
    if (flag === 'aiResponse') {
      content = '<div class="system-reply-message" data-content-id="' + this.dynamicContentCount + '">\n' +
        '                    <div class="system-message-text" style="display: flex">\n' +
        '                        <div>\n' +
        '                            <div>' + inputContent + '</div>\n' +
        '\n' +
        '                        </div>\n' +
        '                    </div>\n' +
        '                </div>'

      this.dynamicContentCount++;
    }

    setTimeout(() => {
      const dynamicContent = content;
      const newDiv = this.renderer.createElement('div');
      newDiv.className = 'messageDiv';
      this.renderer.setProperty(newDiv, 'innerHTML', dynamicContent);
      if (this.dynamicContentContainer) {
        this.renderer.appendChild(this.dynamicContentContainer.nativeElement, newDiv);
      }
      this.chatLog.nativeElement.scrollTop = this.chatLog.nativeElement.scrollHeight;
      this.cdr.detectChanges();

    }, 100)
  }


  public async submitPrompt() {
    this.showLoader = true;
    this.disableSubmit = true;
    this.aiReplayMessage = '';
    this.userQuery = this.userInput;
    this.userInput = '';

    this.dynamicContentHandler('userPrompt', this.userQuery)

    const openAIResp = await this.dataService.postToServer(
      'functions/get-openAI-response', {userPrompt: this.userQuery, contentType: this.userChoice, threadId: this.threadId},
    );
    const response = openAIResp.result
    this.threadId = response.threadId
    this.aiReplayMessage = response.content;
    this.aiResponseArray.push(this.aiReplayMessage)
    this.dynamicContentHandler('aiResponse', this.formatResponse(this.aiReplayMessage))
    this.showLoader = false;
    this.disableSubmit = false;

  }

  public formatResponse(response: string): string {
    // Replace \n with <br> and \t with &nbsp; (non-breaking space)
    return response.replace(/\n/g, '<br>').replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
  }

  public async copyMessage(contentId: number) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.innerHTML = this.aiResponseArray[contentId] ? this.aiResponseArray[contentId] : '';
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  public ngOnDestroy() {
    // Clean up the subscription
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
