import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { DataService } from '../../../services/data.service';
import { AuthService } from '../../../services/auth';
import { UserService } from '../../../services/user.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-postmark-configuration',
  templateUrl: './postmark-configuration.component.html',
  styleUrls: ['./postmark-configuration.component.scss'],
})
export class PostmarkConfigurationComponent implements OnInit {
  public postmarkConfiguration: FormGroup;
  public isFormSubmitted: boolean;
  public canShow = false;
  public domainId = '';
  public dkimHostKey = '';
  public dkimHostValue = '';
  public dkimHostVerified = false;

  constructor(
    private dataService: DataService,
    public authService: AuthService,
    public userService: UserService,
    private formBuilder: FormBuilder,
    private ngxService: NgxUiLoaderService,
    private notifier: NotifierService,
  ) {}

  public ngOnInit() {
    this.initForm();
  }

  public async initForm() {
    const userEntityData = await this.userService.userEntityData();
    const user = await this.userService.getUser();
    const postMarkConfig =
      userEntityData.entityId && userEntityData.entityId.postMarkConfig
        ? userEntityData.entityId.postMarkConfig
        : {};
    const domainName = postMarkConfig.senderEmail || (user && user.email || '');
    const senderName = postMarkConfig.senderName || (user && user.name || '');
    this.domainId = postMarkConfig.domainId;
    this.postmarkConfiguration = this.formBuilder.group({
      domainName: new FormControl(domainName, [
        Validators.required,
        Validators.email,
      ]),
      senderName: new FormControl(senderName),
    });
    if (!!this.domainId) {
      await this.getDomainDetails();
    }
    this.canShow = true;
  }

  public isFieldValid(field: string, currentForm: FormGroup) {
    if (currentForm.get(field)) {
      return !currentForm.get(field)!.valid && currentForm.get(field)!.touched;
    }
    return;
  }

  public displayFieldCss(field: string, currentForm: FormGroup) {
    return {
      'has-error': this.isFieldValid(field, currentForm),
      'has-feedback': this.isFieldValid(field, currentForm),
    };
  }

  public async getDomainDetails() {
    this.ngxService.start();
    try {
      const domainDetailsResponse = await this.dataService.postToServer(
        'functions/manage-postmark',
        {
          domainId: this.domainId,
          type: 'get',
        },
      );
      const domainDetails = domainDetailsResponse.result;
      if (domainDetails && domainDetails.ID) {
        this.dkimHostKey =
          domainDetails.DKIMPendingHost || domainDetails.DKIMHost;
        // this.dkimHostValue =
        //   domainDetails.DKIMPendingTextValue || domainDetails.DKIMTextValue;
        let modifiedString = this.removeSpacesAfterSemicolons( domainDetails.DKIMPendingTextValue || domainDetails.DKIMTextValue);
        this.dkimHostValue = modifiedString
        this.dkimHostVerified = domainDetails.DKIMVerified;
      }
    } catch (e) {
      alert(e.message);
    }
    this.ngxService.stop();
  }

  removeSpacesAfterSemicolons(inputString: string): string {
    return inputString.replace(/;\s+/g, ';');
  }
  

  public async createDomain() {
    this.ngxService.start();
    try {
      const formValues = this.postmarkConfiguration.value;
      const email = formValues.domainName;
      const senderName = formValues.senderName;
      const domainName = email.split('@')[1];
      const domainDetailsResponse = await this.dataService.postToServer(
        'functions/manage-postmark',
        {
          domainName,
          type: 'add',
        },
      );
      const domainDetails = domainDetailsResponse.result;
      if (domainDetails && domainDetails.ID) {
        this.domainId = domainDetails.ID;
        this.dkimHostKey =
          domainDetails.DKIMPendingHost || domainDetails.DKIMHost;
        this.dkimHostValue =
          domainDetails.DKIMPendingTextValue || domainDetails.DKIMTextValue;
        this.updateEntity(domainName, email, senderName, true);
      }
    } catch (e) {
      alert(e.message);
    }
    this.ngxService.stop();
  }

  public async updateEntity(domainName = '', email = '', senderName = '', isAdd = true) {
    const userEntityData = await this.userService.userEntityData();
    const updateData = isAdd
      ? {
          postMarkConfig: {
            domainName,
            senderName,
            senderEmail: email,
            domainId: this.domainId,
          },
        }
      : { postMarkConfig: {} };
    await this.dataService.updateToServer(
      'classes/Entity/' + userEntityData.entityId.objectId,
      updateData,
    );
  }

  public async deleteDomain() {
    if (confirm('Are you sure you want to delete this domain?')) {
      this.ngxService.start();
      try {
        await this.dataService.postToServer('functions/manage-postmark', {
          domainId: this.domainId,
          type: 'delete',
        });
        this.domainId = '';
        this.dkimHostKey = '';
        this.dkimHostValue = '';
        this.dkimHostVerified = false;
        this.updateEntity('', '', '', false);
      } catch (e) {
        alert(e.message);
      }
      this.ngxService.stop();
    }
  }

  public async verifyDomain() {
    this.ngxService.start();
    try {
      const postmarkResponse = await this.dataService.postToServer(
        'functions/manage-postmark',
        {
          domainId: this.domainId,
          type: 'verify',
        },
      );
      const data = postmarkResponse.result;
      if (data.DKIMVerified && data.DKIMUpdateStatus !== 'Pending') {
        this.dkimHostVerified = true;
      } else {
        alert(`Domain ${data.Name} is not verified yet`);
      }
    } catch (e) {
      alert(e.message);
    }
    this.ngxService.stop();
  }

  public copyCode(code: string) {
    const el = document.createElement('textarea');
    el.value = code;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.notifier.notify('success', 'Key copied to clipboard');
  }

  public async updateSenderEmail() {
    const userEntityData = await this.userService.userEntityData();
    const postMarkConfig =
      userEntityData.entityId && userEntityData.entityId.postMarkConfig
        ? userEntityData.entityId.postMarkConfig
        : {};
    const formValues = this.postmarkConfiguration.value;
    const email = formValues.domainName;
    const senderName = formValues.senderName;
    const domainName = email.split('@')[1];
    if (postMarkConfig.domainName !== domainName) {
      alert('Domain name cannot be changed');
      return;
    }
    this.updateEntity(domainName, email, senderName, true);
  }
}
