import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { AuthService } from '../../../services/auth';
import { DataService } from '../../../services/data.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-nps-widget',
  templateUrl: './nps-widget.component.html',
  styleUrls: ['./nps-widget.component.scss'],
})
export class NpsWidgetComponent implements OnInit {
  public npsConfiguration: FormGroup;
  @ViewChild("codeToNPS") codeToNPS: ElementRef;
  public isFormSubmitted: boolean;
  public showSetting: boolean = false;
  public canShow = false;
  public eventsApiKey: string;

  constructor(
    private dataService: DataService,
    public authService: AuthService,
    public userService: UserService,
    private formBuilder: FormBuilder,
  ) {}

  public ngOnInit() {
    this.initForm();
  }

  copyNPSCode() {
    const textArea = this.codeToNPS.nativeElement;
    textArea.select();
    document.execCommand("copy");
    textArea.setSelectionRange(0, 0);
  }

  public async initForm() {
    const userEntityData = await this.userService.userEntityData();
    this.eventsApiKey = userEntityData.entityId.eventsApiKey;
    const frequency =
      userEntityData.entityId &&
      userEntityData.entityId.npsConfig &&
      userEntityData.entityId.npsConfig.frequency
        ? userEntityData.entityId.npsConfig.frequency
        : 15;
    const urlCondition =
      userEntityData.entityId &&
      userEntityData.entityId.npsConfig &&
      userEntityData.entityId.npsConfig.urlCondition
        ? userEntityData.entityId.npsConfig.urlCondition
        : '';
    const path =
      userEntityData.entityId &&
      userEntityData.entityId.npsConfig &&
      userEntityData.entityId.npsConfig.path
        ? userEntityData.entityId.npsConfig.path
        : '';
    const afterDuration =
      userEntityData.entityId &&
      userEntityData.entityId.npsConfig &&
      userEntityData.entityId.npsConfig.afterDuration
        ? userEntityData.entityId.npsConfig.afterDuration
        : 30;
    const enableNPS =
      userEntityData.entityId &&
      userEntityData.entityId.npsConfig &&
      userEntityData.entityId.npsConfig.enableNPS
        ? userEntityData.entityId.npsConfig.enableNPS
        : 'bottomLeft';
    const position =
      userEntityData.entityId &&
      userEntityData.entityId.npsConfig &&
      userEntityData.entityId.npsConfig.position
        ? userEntityData.entityId.npsConfig.position
        : false;
    this.npsConfiguration = this.formBuilder.group({
      frequency: new FormControl(frequency, [
        Validators.required,
        Validators.pattern(/^[0-9]+/),
      ]),
      urlCondition: new FormControl(urlCondition, [
        Validators.required,
      ]),
      path: new FormControl(path, [
        Validators.required,
      ]),
      afterDuration: new FormControl(afterDuration, [
        Validators.required,
        Validators.pattern(/^[0-9]+/),
      ]),
      enableNPS: [enableNPS],
      position: [position],
    });
    this.canShow = true;
  }

  public showSettings(){
    this.showSetting = !this.showSetting;
  }

  public hideSettings(){
    this.showSetting = false;
  }
  
  public isFieldValid(field: string, currentForm: FormGroup) {
    if (currentForm.get(field)) {
      return !currentForm.get(field)!.valid && currentForm.get(field)!.touched;
    }
    return;
  }

  public displayFieldCss(field: string, currentForm: FormGroup) {
    return {
      'has-error': this.isFieldValid(field, currentForm),
      'has-feedback': this.isFieldValid(field, currentForm),
    };
  }

  public async saveNpsConfiguration() {
    this.isFormSubmitted = true;
    if (this.npsConfiguration.valid) {
      const entityObject = this.authService.getUser();
      const formValues = this.npsConfiguration.value;
      // Trim the path field and remove leading & trailing slashes
      formValues.path = formValues.path.trim().replace(/^\//, '');
      const updateData = {
        npsConfig: {
          frequency: formValues.frequency,
          urlCondition: formValues.urlCondition,
          path: formValues.path,
          afterDuration: formValues.afterDuration,
          enableNPS: !!formValues.enableNPS,
          position: formValues.position,
        },
      };
      await this.dataService.updateToServer(
        'classes/Entity/' + entityObject.entityId.objectId,
        updateData,
      );
    }
    this.isFormSubmitted = false;
  }

  public isActivePosition(position: string) {
    return (this.npsConfiguration.value.position || 'bottomLeft') === position
      ? 'active'
      : '';
  }
}
