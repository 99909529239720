import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { DatatableSettings } from "../../types/datatables";
import { DataService } from "../../services/data.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BsModalRef, ModalDirective } from "ngx-bootstrap/modal";
import { NotifierService } from "angular-notifier";
import { DataTableDirective } from "angular-datatables";
import { AuthService } from "src/app/services/auth";
// import { validationPattern } from '../../constants/regexp';
import { Contacts } from "src/app/types/contacts";
import { Outreach } from "src/app/types/outreach";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { UserService } from "src/app/services/user.service";
import { track } from "@inleads/event-logger";
import { ActivatedRoute } from "@angular/router";

import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { EmailEditorComponent } from "angular-email-editor";
import html2canvas from "html2canvas";
import {generateImg} from "../../helpers/helper.date";

@Component({
  selector: "app-outreach",
  templateUrl: "./outreach.component.html",
  styleUrls: ["./outreach.component.scss"]
})
export class OutreachComponent implements OnInit {
  @ViewChild(DataTableDirective) public datatableElement: DataTableDirective;
  @ViewChild("tableWrapper") public tableWrapperElement: ElementRef;
  @ViewChild("createOutreachModal") public createOutreachModal: ModalDirective;
  @ViewChild("newTemplateModal") public newTemplateModal?: ModalDirective;
  @ViewChild(EmailEditorComponent) private emailEditor: EmailEditorComponent;
  public newTemplateForm: FormGroup;
  public dtOptions: DatatableSettings = {};
  public dtInstance: DataTables.Api;
  public newOutreachForm: FormGroup;
  public modalRef: BsModalRef;
  public isLoading: boolean;
  public outreachsPageData: Outreach[] = [];
  public disableFields: any;
  public userEntityDetails: any;
  public contactList: Contacts[] = [];
  public loadingMessage = false;
  public templatesList: any[] = [];
  // public isButtonDisabled = false;
  public generateText: any;
  public selectedItemIndex: number = 0;
  public recordsPerPage: number = 10;
  public searchQuery: string = "";
  public filteredData: any[] = [];
  public domainId = "";
  public showSaveTemplateCheckbox = true;
  public dkimHostVerified = false;
  domainVerificationMessage: string = "";
  fullNameField: any;
  htmlContent = "";
  public postmarkConfig: any;
  public existingHtmlData: SafeHtml;
  public existingEmailObjectData: any;
  public templateId: string;
  public emailBody: string;
  public selectedItem: SafeHtml;
  public templatesListPreview: SafeHtml = [];
  public selectedTemplate: string | null = null;
  public design: any;
  public activeTab: number = 0;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "auto",
    minHeight: "0",
    maxHeight: "auto",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "Enter text here...",
    defaultParagraphSeparator: "p",
    defaultFontName: "",
    defaultFontSize: "",
    fonts: [
      { class: "arial", name: "Arial" },
      { class: "times-new-roman", name: "Times New Roman" },
      { class: "calibri", name: "Calibri" },
      { class: "comic-sans-ms", name: "Comic Sans MS" }
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote"
      },
      {
        name: "redText",
        class: "redText"
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1"
      }
    ],
    sanitize: true,
    toolbarPosition: "top",
    uploadUrl: "v1/image"
  };
  selectedContact: Contacts | undefined;
  fromLeads: boolean = false;

  constructor(
    private dataService: DataService,
    private fb: FormBuilder,
    private notifier: NotifierService,
    // private http: HttpClient,
    private authService: AuthService,
    private ngxService: NgxUiLoaderService,
    public userService: UserService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {}

  public async ngOnInit() {
    await this.loadOutreach();
    this.getPostmarkConfig();
    this.getuserEntityDetails();
    this.initializeNewTemplateForm();
    this.route.params.subscribe((params: { [x: string]: any }) => {
      const contactId = params["contactId"];
      this.fromLeads = params["fromLeads"] === "true";
      // if (contactId) {
      //   this.getContacts(contactId);
      // }
      if (contactId) {
        this.getContacts(contactId).then(() => {
          if (this.selectedContact && this.fromLeads) {
            this.addOutreach();
          }
        });
      }
    });
    this.getTemplates();
    track("OUTREACH");
  }
  async getuserEntityDetails() {
    const userParams = {
      where: {
        objectId: this.authService.getUser().objectId
      },
      include: "entityId"
    };
    const users = await this.dataService.getFromServer(
      "classes/_User",
      userParams
    );
    this.userEntityDetails = (users && users.results[0]) || [];
  }

  private initializeOutreachForm() {
    this.newOutreachForm = this.fb.group({
      // objectId: ['', Validators.required],
      fullName: [[], Validators.required],
      // email: [
      //   '',
      //   [Validators.required, Validators.pattern(validationPattern.email)],
      // ],
      templates: [""],
      contactId: [""],
      saveTemplatecheckbox: [false],
      templateName: [""],
      subject: ["", Validators.required],
      message: ["", Validators.required]
    });

    this.newOutreachForm
      .get("saveTemplatecheckbox")!
      .valueChanges.subscribe(checked => {
        const templateNameControl = this.newOutreachForm.get("templateName")!;
        if (checked) {
          templateNameControl.setValidators([Validators.required]);
        } else {
          templateNameControl.clearValidators();
        }
        templateNameControl.updateValueAndValidity();
      });
  }

  private initializeNewTemplateForm() {
    this.newTemplateForm = this.fb.group({
      templateNameVal: ["", Validators.required],
      emailSubject: ["", Validators.required],
      mailBody: ["", Validators.required]
    });
  }

  private initializeFullNameField() {
    this.fullNameField = {
      type: "select",
      name: "fullName",
      tags: true,
      placeholder: "Select Contacts",
      select2Data: this.contactList.map(contact => ({
        id: contact.objectId,
        text: `${contact.name} (${contact.email})`
      })),
      select2Options: {
        multiple: true,
        tags: true,
        templateResult: (data: any) => {
          if (!data.id) {
            return data.text;
          }
          const contact = this.contactList.find(
            contact => contact.objectId === data.id
          );
          if (contact) {
            if (contact.email) {
              return $(`<span>${contact.name} (${contact.email})</span>`);
            } else {
              return $(`<span>${contact.name}</span>`);
            }
          }
          return data.text;
        },
        templateSelection: (data: any) => {
          if (!data.id) {
            return data.text;
          }
          const contact = this.contactList.find(
            contact => contact.objectId === data.id
          );
          if (contact) {
            return contact.name;
          }
          return data.text;
        }
      }
    };
  }

  public async loadOutreach() {
    this.initializeOutreachForm();
    try {
      this.isLoading = true;
      let requestOutreachParams;
      requestOutreachParams = {
        limit: 10000,
        order: "-createdAt",
        include: "toContact",
        where: {
          fromUser: {
            __type: "Pointer",
            className: "_User",
            objectId: this.authService.getUser().objectId
          }
        }
      };
      const outreachResponse = await this.dataService.getFromServer(
        "classes/Messages",
        requestOutreachParams
      );
      this.outreachsPageData = outreachResponse.results || [];
      if (this.outreachsPageData) {
        this.applySearch();
      }
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      this.notifier.notify("error", e.error);
    }
  }

  public applySearch() {
    if (this.searchQuery.trim() === "") {
      this.filteredData = this.outreachsPageData.slice(0, this.recordsPerPage);
    } else {
      this.filteredData = this.outreachsPageData
        .filter(item => {
          return item.message
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase());
        })
        .slice(0, this.recordsPerPage);
    }
  }

  public getEmailEvents(): string[] {
    return this.outreachsPageData.map(outreach => outreach.emailEvents);
  }

  public getStatusText(emailEvents: string): string {
    switch (emailEvents) {
      case "SubscriptionChanged":
        return "Subscription Changed";
      case "Delivered":
        return "Delivered";
      case "Transient":
        return "Transient";
      case "Opened":
        return "Opened";
      case "LinkClicked":
        return "Link Clicked";
      case "Bounced":
        return "Bounced";
      default:
        return "";
    }
  }

  public toggleMailContent(index: number, outreachData?: any) {
    this.selectedItemIndex = index;
    if (outreachData) {
      this.selectedItem = this.sanitizer.bypassSecurityTrustHtml(
        outreachData.message
      );
    }
  }

  public stripHTMLTags(html: string) {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  }

  public async getTemplates() {
    let templatesQuery;
    templatesQuery = {
      where: {
        fromUser: {
          __type: "Pointer",
          className: "_User",
          objectId: this.authService.getUser().objectId
        }
      }
    };
    const resp = await this.dataService.getFromServer(
      "classes/EmailTemplates",
      templatesQuery
    );
    this.templatesList = resp.results;
    if (this.templatesList && this.templatesList.length > 0) {
      this.templatesListPreview = this.templatesList.map(template => ({
        message: this.sanitizer.bypassSecurityTrustHtml(
          template.message as string
        )
      }));
    }
  }

  public onTemplateChange(selectedTemplateId: string): void {
    this.activeTab = 1;
    this.selectedTemplate = selectedTemplateId;
    const selectedTemplate = this.templatesList.find(
      template => template.objectId === selectedTemplateId
    );
    this.templateId = selectedTemplateId;
    if (selectedTemplate) {
      this.newOutreachForm.patchValue({
        subject: selectedTemplate.subject || '',
        message: selectedTemplate.message || '',
        saveTemplatecheckbox: false
      });
      this.existingEmailObjectData = selectedTemplate.emailDesign || {};
      this.emailBody = selectedTemplate.message || {};
      this.existingHtmlData = this.sanitizer.bypassSecurityTrustHtml(
        selectedTemplate.message
      );
      this.showSaveTemplateCheckbox = false;
    }
  }

  public async getContacts(contactId?: string) {
    let contactConditionParams;
    if (this.userService.isSuperAdmin) {
      contactConditionParams = {
        where: {
          isActive: { $ne: false }
        }
      };
    } else if (this.userService.isAdmin) {
      contactConditionParams = {
        where: {
          entity: {
            __type: "Pointer",
            className: "Entity",
            objectId: this.authService.getUser().entityId.objectId
          },
          isActive: { $ne: false }
        }
      };
    } else if (this.userService.isAgent) {
      contactConditionParams = {
        where: {
          $or: [
            {
              agent: {
                __type: "Pointer",
                className: "_User",
                objectId: this.authService.getUser().objectId
              }
            },
            {
              assignTo: {
                __type: "Pointer",
                className: "_User",
                objectId: this.authService.getUser().objectId
              }
            }
          ],
          isActive: { $ne: false }
        }
      };
    }
    const res = await this.dataService.getFromServer(
      "classes/Contacts",
      contactConditionParams
    );
    this.contactList = res.results;

    if (contactId) {
      this.selectedContact = this.contactList.find(
        contact => contact.objectId === contactId
      );
      if (this.selectedContact) {
        this.newOutreachForm.patchValue({
          fullName: [this.selectedContact.objectId]
        });
      }
    }

    if (res.results) {
      this.getGroups();
    }
  }

  private async fetchContactsForGroup(group: any) {
    if (group.contacts && group.contacts.__type === "Relation") {
      const contactsQuery = {
        where: {
          $relatedTo: {
            object: {
              __type: "Pointer",
              className: "Groups",
              objectId: group.objectId
            },
            key: "contacts"
          }
        }
      };
      const contactsResponse = await this.dataService.getFromServer(
        "classes/Contacts",
        contactsQuery
      );
      return contactsResponse.results || [];
    }
    return [];
  }

  public async getGroups() {
    let groupsParams = {
      include: "Contacts",
      where: {
        entity: {
          __type: "Pointer",
          className: "Entity",
          objectId: this.authService.getUser().entityId.objectId
        }
      }
    };
    let res = await this.dataService.getFromServer(
      "classes/Groups",
      groupsParams
    );
    res = res.results;
    res.map((res: any) => {
      res.name = res.groupName;
      return res;
    });
    if (res.length > 0) {
      let groupsData = res;
      const contactsData = this.contactList;
      this.contactList = contactsData.concat(groupsData);
    }
    this.initializeFullNameField();
  }

  public async generateMailBody() {
    this.loadingMessage = true;
    const sentence =
      "create an email body without subject for " +
      this.newOutreachForm.get("subject")!.value;
    try {
      const mailBodyData = await this.dataService.postToServer(
        "functions/generateTextForArticle",
        { sentence }
      );
      this.generateText = mailBodyData.result
        .replace(/\[Name\]/g, this.newOutreachForm.get("fullName")!.value.name)
        .replace(/\[Your Name\]/g, this.authService.getUser().name)
        .replace(/\[Company Name\]/g, this.authService.getUser().entityId.name);
      this.newOutreachForm.patchValue({
        message: this.generateText || ""
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingMessage = false;
    }
  }

  public onChangeCustomer(e: any) {
    console.log(e);
  }

  // public selectCustomer(e: any) {
  //   this.newOutreachForm.get('email')!.setValue(e.email);
  //   this.newOutreachForm.get('contactId')!.setValue(e.objectId);
  // }

  public addOutreach() {
    //this.getContacts();
    this.activeTab = 0;
    this.createOutreachModal.show();
    this.newOutreachForm.reset();
    this.existingHtmlData = "";
    this.existingEmailObjectData = "";
    this.templateId = "";
    if (this.selectedContact && this.fromLeads) {
      this.newOutreachForm.patchValue({
        fullName: [this.selectedContact.objectId]
      });
    } else {
      this.newOutreachForm.reset();
      this.getContacts();
    }
  }

  public async createOutreach() {
    this.createOutreachModal.hide();

    const contactNames: string[] = this.newOutreachForm.get("fullName")!.value;
    // const contactEmail = this.newOutreachForm.get('email')!.value;
    const subject = this.newOutreachForm.get("subject")!.value;
    const message = this.newOutreachForm.get("message")!.value;
    // const contactId = this.newOutreachForm.get('contactId')!.value;

    if (!message) {
      this.notifier.notify("error", "Message cannot be empty!");
      return;
    }
    if (contactNames.length <= 0) {
      this.notifier.notify("error", "Please Select Contact");
      return;
    }
    let outreachResponse;
    for (const contactObjectId of contactNames) {
      const selectedContact: any = this.contactList.find(
        contact => contact.objectId === contactObjectId
      );
      if (selectedContact.groupName) {
        const groupOfContacts = await this.fetchContactsForGroup(
          selectedContact
        );
        for (const selectedGroupContact of groupOfContacts) {
          if (selectedGroupContact) {
            const personalizedSubject = subject
              .replace("[Customer Email]", selectedGroupContact.email)
              .replace("[Customer's Email]", selectedGroupContact.email)
              .replace(
                "[Organisation Name]",
                this.userEntityDetails.entityId.name
              )
              .replace("[Customer Name]", selectedGroupContact.name)
              .replace("[Customer's Name]", selectedGroupContact.name)
              .replace("[User Name]", this.authService.getUser().name)
              .replace("[Website]", this.userEntityDetails.username);

            const personalizedMessage = message
              .replace("[Customer Email]", selectedGroupContact.email)
              .replace("[Customer's Email]", selectedGroupContact.email)
              .replace(
                "[Organisation Name]",
                this.userEntityDetails.entityId.name
              )
              .replace("[Customer Name]", selectedGroupContact.name)
              .replace("[Customer's Name]", selectedGroupContact.name)
              .replace("[User Name]", this.authService.getUser().name)
              .replace("[Website]", this.userEntityDetails.username);

            let outreachParams: any = {
              objectId: "",
              message: personalizedMessage,
              subject: personalizedSubject,
              fromUser: {
                __type: "Pointer",
                className: "_User",
                objectId: this.authService.getUser().objectId
              },
              toContact: {
                __type: "Pointer",
                className: "Contacts",
                objectId: selectedGroupContact.objectId
              },
              isEmail: true,
              ...(this.templateId && this.templateId !== 'default' && { isEmailDesigned: true })
            };
            // this.isButtonDisabled = true;
            outreachResponse = await this.dataService.postToServer(
              "classes/Messages/",
              outreachParams
            );
          }
        }
      }
      if (!selectedContact.groupName) {
        const personalizedSubject = subject
          .replace("[Customer Email]", selectedContact.email)
          .replace("[Customer's Email]", selectedContact.email)
          .replace("[Organisation Name]", this.userEntityDetails.entityId.name)
          .replace("[Customer Name]", selectedContact.name)
          .replace("[Customer's Name]", selectedContact.name)
          .replace("[User Name]", this.authService.getUser().name)
          .replace("[Website]", this.userEntityDetails.username);

        const personalizedMessage = message
          .replace("[Customer Email]", selectedContact.email)
          .replace("[Customer's Email]", selectedContact.email)
          .replace("[Organisation Name]", this.userEntityDetails.entityId.name)
          .replace("[Customer Name]", selectedContact.name)
          .replace("[Customer's Name]", selectedContact.name)
          .replace("[User Name]", this.authService.getUser().name)
          .replace("[Website]", this.userEntityDetails.username);

        let outreachParams: any = {
          objectId: "",
          message: personalizedMessage,
          subject: personalizedSubject,
          fromUser: {
            __type: "Pointer",
            className: "_User",
            objectId: this.authService.getUser().objectId
          },
          toContact: {
            __type: "Pointer",
            className: "Contacts",
            objectId: contactObjectId
          },
          isEmail: true,
          ...(this.templateId && this.templateId !== 'default' && { isEmailDesigned: true })
        };

        // this.isButtonDisabled = true;
        outreachResponse = await this.dataService.postToServer(
          "classes/Messages/",
          outreachParams
        );
      }
    }
    if (this.newOutreachForm.get("saveTemplatecheckbox")!.value) {
      const templateParams: any = {
        message: message,
        subject: subject,
        templateName: this.newOutreachForm.get("templateName")!.value,
        fromUser: {
          __type: "Pointer",
          className: "_User",
          objectId: this.authService.getUser().objectId
        }
      };
      outreachResponse = await this.dataService.postToServer(
        "classes/EmailTemplates/",
        templateParams
      );
    }
    if (outreachResponse) {
      this.notifier.notify("success", "Email created Successfully!!!");
      this.newOutreachForm.reset();
      this.createOutreachModal.hide();
      // this.isButtonDisabled = false;
      this.loadOutreach().then();
      this.applySearch();
    } else {
      this.notifier.notify("error", "Error while creating Outreach.");
      this.createOutreachModal.show();
    }
  }

  public async getPostmarkConfig() {
    this.ngxService.start();
    try {
      const entityId = this.authService.getUser().entityId.objectId;
      const entityResponse = await this.dataService.getFromServer(
        `classes/Entity/${entityId}`
      );
      this.postmarkConfig = entityResponse.postMarkConfig;
      if (this.postmarkConfig && this.postmarkConfig.domainName) {
        this.domainVerificationMessage = ` You can now start sending emails. Your Domain is verified.`;
      } else {
        this.domainVerificationMessage = `You cannot send emails until your domain is verified. Please add and verify your email domain, from settings.`;
      }
    } catch (error) {
      console.error("Error fetching postmarkconfig:", error);
    }
    this.ngxService.stop();
  }

  public isFieldValid(field: string, currentForm: FormGroup) {
    if (currentForm.get(field) && !this.disableFields) {
      return !currentForm.get(field)!.valid && currentForm.get(field)!.touched;
    }
    return;
  }

  public displayFieldCss(field: string, currentForm: FormGroup) {
    return {
      "has-error": this.isFieldValid(field, currentForm),
      "has-feedback": this.isFieldValid(field, currentForm)
    };
  }

  public editEmail() {
    const selectedTemplate = this.templatesList.find(
      template => template.objectId === this.templateId
    );
    if (selectedTemplate) {
      this.newTemplateForm.setValue({
        templateNameVal: selectedTemplate.templateName || "",
        emailSubject: selectedTemplate.subject || "",
        mailBody: selectedTemplate.message || ""
      });
      this.newOutreachForm.patchValue({
        subject: selectedTemplate.subject,
        message: selectedTemplate.message,
        saveTemplatecheckbox: false
      });
      this.existingEmailObjectData = selectedTemplate.emailDesign;
      this.emailBody = selectedTemplate.message;
      this.existingHtmlData = this.sanitizer.bypassSecurityTrustHtml(
        selectedTemplate.message
      );
      this.loadExistingEmailTemplate(selectedTemplate.message);
    }
    this.newTemplateModal!.show();
  }

  public getA() {
    try {
      const element = document.getElementById(
        "iframe-container"
      ) as HTMLElement;

      html2canvas(element)
        .then(canvas => {
          // Convert the canvas to an image
          const imgData = canvas.toDataURL("image/png");

          // Log the image data URL or do something with it (e.g., download, display)
          console.log(imgData);

          // Optional: Create an image element and append it to the document to display the captured image
          const imgElement = document.createElement("img");
          imgElement.src = imgData;
          document.body.appendChild(imgElement);
        })
        .catch(error => {
          console.log("Error capturing the image:", error);
        });
    } catch (e) {
      console.log(e);
    }
  }

  exportHtml() {
    this.emailEditor.editor.exportHtml((data: any) =>
      this.saveEmailTemplate(data)
    );
  }

  public async saveEmailTemplate(data: any) {
    try{
      const rawHtml = data.html;
      const fileName = this.newTemplateForm.get("templateNameVal")!.value || 'New Template';
      // Generate image
      const img = await generateImg(rawHtml, fileName.replace(/\s+/g, '')); // Pass the raw HTML string

      let templateParams = {
        templateName: this.newTemplateForm.get("templateNameVal")!.value,
        subject: this.newTemplateForm.get("emailSubject")!.value,
        message: data.html,
        emailDesign: data.design,
        fromUser: {
          __type: "Pointer",
          className: "_User",
          objectId: this.authService.getUser().objectId
        },
        ...(img && { thumbnail: img })
      };

      if (!templateParams.templateName) {
        this.notifier.notify("error", "Please Enter Template Name!");
        return;
      }
      if (!templateParams.subject) {
        this.notifier.notify("error", "Please Enter Template Subject!");
        return;
      }
      let templateResponse;
      if (this.templateId) {
        templateResponse = await this.dataService.updateToServer(
          "classes/EmailTemplates/" + this.templateId,
          templateParams
        );
      } else {
        templateResponse = await this.dataService.postToServer(
          "classes/EmailTemplates/",
          templateParams
        );
      }
      if (templateResponse) {
        this.getTemplates();
        this.notifier.notify(
          "success",
          this.templateId
            ? "Template updated successfully!"
            : "Template saved successfully!"
        );
        this.templatesList = this.templatesList.map(template => {
          if (template.objectId === this.templateId) {
            return {
              ...template, // Spread the existing properties
              templateName: templateParams.templateName,
              subject: templateParams.subject,
              message: templateParams.message,
              emailDesign: templateParams.emailDesign
            };
          }
          return template; // Return the original template if no match is found
        });
        this.editEmail();
        this.newTemplateModal!.hide();
        this.newTemplateForm.reset();
      } else {
        this.notifier.notify("error", "Error while saving template.");
      }
    } catch (e) {
      console.log("error ", e);
    }
  }

  public captureIframe() {
    try {
      const iframe = document.getElementById("myIframe") as HTMLIFrameElement;

      if (iframe) {
        const iframeDocument =
          iframe.contentDocument ||
          (iframe.contentWindow && iframe.contentWindow.document);

        if (iframeDocument) {
          html2canvas(iframeDocument.body)
            .then(canvas => {
              const img = document.createElement("img");
              img.src = canvas.toDataURL("image/png");
              const resultDiv = document.getElementById("result");
              console.log(img.src);
              if (resultDiv) {
                resultDiv.innerHTML = "";
                resultDiv.appendChild(img);
              }
            })
            .catch(err => {
              console.error("Error capturing iframe content:", err);
            });
        } else {
          console.error("Unable to access iframe document.");
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  async loadExistingEmailTemplate(response: any) {
    try {
      this.setEditorContent(response);
    } catch (error) {
      console.error("Error loading email template:", error);
    }
  }

  // Set the HTML content in the editor
  setEditorContent(html: string) {
    if (this.emailEditor && this.emailEditor.editor) {
      try {
        if (html) {
          this.emailEditor.editor.loadDesign(this.existingEmailObjectData);
        } else {
          console.error("HTML data is empty or undefined");
        }
      } catch (error) {
        console.error("Error setting editor content:", error);
      }
    } else {
      console.error("EmailEditor instance or editor is not available");
    }
  }

  // Called when the editor is loaded
  editorLoaded() {
    if (this.emailEditor) {
      // Set the HTML content after the editor has loaded
      this.emailEditor.editor.loadDesign(this.existingEmailObjectData);
    }
  }
}
