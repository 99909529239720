import {Component, OnInit, AfterViewInit, OnChanges, Input, SimpleChanges} from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o/lib/models/owl-options.model';
import { SharedDataService } from '../../services/shared-data.service';

@Component({
  selector: 'app-owl-carousel',
  templateUrl: './owl-carousel.component.html',
  styleUrls: ['./owl-carousel.component.scss']
})
export class OwlCarouselComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() countList: any = {};
  @Input() count: any;
  @Input() dealAmount: any = {};
  @Input() currentMonthRetentionRate: string;
  public slickData: any = [];


  public customOptions: OwlOptions = {
    items:6,
    rewind:true,
    autoplay:true,
    autoplaySpeed:500,
    autoplayTimeout: 5000,
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 4,
      },
    },
    nav: true,
  }


  constructor(private sharedDataService: SharedDataService) {}


  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.count && changes.count.currentValue) {
      this.countList = changes.count.currentValue;
      this.initChart()
    }

  }
  ngAfterViewInit(): void {
    this.initChart()
  }

  private initChart(): void {
    this.slickData = [];
    this.slickData = [
      {
        title: 'Total Leads',
        numbers: this.countList.leads || 0.0,
        indecation: '',
        subTitle: '',
      },
      {
        title: 'Expected Revenue',
        numbers: '$' + this.dealAmount.total || 0.0,
        indecation: '',
        subTitle: '',
      },
      {
        title: 'Connections',
        numbers: this.countList.contacts || 0.0,
        indecation: '',
        subTitle: '',
      },
      {
        title: 'DAU',
        numbers: this.countList.dau || 0.0,
        indecation: '',
        subTitle: '',
      },
      {
        title: 'Retention',
        numbers: this.currentMonthRetentionRate === undefined ? 0 : this.currentMonthRetentionRate + '%',
        indecation: 'arrowR-' + this.countList.arrowR,
        subTitle: this.countList.retentionRateChange,
      },
      {
        title: 'Churn',
        numbers: this.countList.currentChurn + '%',
        indecation: 'arrow-' + this.countList.arrow,
        subTitle: this.countList.churnRateChange,
      },
    ];
    this.sharedDataService.changeSlickData(this.slickData);
  }

  public numberWithCommas(x:number) {
    const parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
}
