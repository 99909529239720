import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NotifierService } from "angular-notifier";
import { DataService } from "../../services/data.service";
import { AuthService } from "../../services/auth";

@Component({
  selector: "landing-page",
  templateUrl: "./landing-page.component.html",
  styleUrls: ["./landing-page.component.scss"]
})
export class LandingPageComponent implements OnInit {
  public isLoading = true;
  public websiteForm: FormGroup;
  public isWebsite: boolean = false;
  public imgURL: any;
  productImages: Array<{ url: string; name: string }> = [];
  public widgetsActionStatus: boolean = true;
  public whatAppWidgetStatus: boolean = false;
  public contactFormWidgetStatus: boolean = false;
  public joinWaitlistWidgetStatus: boolean = false;
  public isFormSubmitted: boolean;
  public initialSlug = "";
  public entityImage: boolean;
  public isApproved: boolean = false;

  constructor(
    private notifier: NotifierService,
    private dataService: DataService,
    public authService: AuthService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.websiteForm = this.fb.group({
      slugName: ["", [Validators.required]],
      websiteTitle: ["", [Validators.required]],
      // websiteSubTitle: ["", [Validators.required]],
      websiteOwnLink: ["", [Validators.required]],
      websiteDescription: ["", [Validators.required]],
      imgURL: ["", [Validators.required]],
      whatsappNumber: ["", [Validators.required]],
      contactCta: ["", [Validators.required]],
      metaTitle: ["", [Validators.required]],
      metaDescription: ["", [Validators.required]]
    });
    this.fetchInitialIsWebsiteValue();
    this.isLoading = false;
  }

  async toggleWebsite(event: any) {
    this.isWebsite = event.target.checked;
    await this.saveToggleState();
    if (this.isWebsite) {
      await this.fetchInitialIsWebsiteValue();
    } else {
      this.websiteForm.controls["slugName"].reset();
      this.initialSlug = "";
    }
  }

  public async onSelectFile(event: Event) {
    this.entityImage = true;
    const files = (event.target as HTMLInputElement).files;
    if (files) {
      if (files[0].size / (1024 * 1024) > 1) {
        (event.target as HTMLInputElement).value = "";
        this.notifier.notify(
          "error",
          "Please select a file size less than 1 MB."
        );
        this.entityImage = false;
        return;
      } else {
        this.imgURL = await this.dataService.uploadFile(files[0]);
        try {
          const data = {
            entityImage: {
              name: this.imgURL.name,
              url: this.imgURL.url,
              __type: "File"
            },
            isApproved: false,
          };
          const entityObject = this.authService.getUser();
          if (entityObject) {
            const response = await this.dataService.updateToServer(
              "classes/Entity/" + entityObject.entityId.objectId,
              data
            );
            if (response) {
              this.isApproved =  false;
              this.notifier.notify("success", "Entity Image Updated");
            }
          }
        } catch (error) {
          this.notifier.notify("error", "Unable to add your website URL");
        }
      }
    }
    this.entityImage = false;
  }

  public async onSelectMultipleFiles(event: Event) {
    const files = (event.target as HTMLInputElement).files;
    if (files) {
      const uploadedFiles: Array<{
        url: string;
        name: string;
      }> = await this.dataService.uploadFiles(files);
      this.productImages = (this.productImages as Array<{
        url: string;
        name: string;
      }>).concat(
        uploadedFiles.map(file => ({ url: file.url, name: file.name }))
      );
    }
  }

  public deleteImage(index: number) {
    if (
      index < 0 ||
      index >=
        (this.productImages as Array<{ url: string; name: string }>).length
    ) {
      this.notifier.notify("error", "Invalid index for image deletion");
      return;
    }

    (this.productImages as Array<{ url: string; name: string }>).splice(
      index,
      1
    );
    this.notifier.notify("success", "Image removed successfully");
  }

  async widgetsCallToActions(event: any) {
    this.widgetsActionStatus = event.target.checked;
    await this.saveToggleState();
  }

  async wappWidgetStatus(event: any) {
    this.whatAppWidgetStatus = event.target.checked;
    await this.saveToggleState();
  }

  async formWidgetStatus(event: any, type: string) {
    const checked = event.target.checked;
    if (type === "cf") {
      this.contactFormWidgetStatus = checked;
      if (checked) {
        this.joinWaitlistWidgetStatus = false;
      }
    } else if (type === "jw") {
      this.joinWaitlistWidgetStatus = checked;
      if (checked) {
        this.contactFormWidgetStatus = false;
      }
    }
    await this.saveToggleState();
  }

  get isInitialSlugEmpty() {
    return !this.initialSlug;
  }

  public async addSlugIntegrations() {
    this.isFormSubmitted = true;
    const profileSlug = this.websiteForm.value.slugName;
    const websiteDescription = this.websiteForm.value.websiteDescription;
    // const websiteSubTitle = this.websiteForm.value.websiteSubTitle;
    const websiteTitle = this.websiteForm.value.websiteTitle;
    const websiteOwnLink = this.websiteForm.value.websiteOwnLink;
    const productImages = this.productImages.map(img => ({
      url: img.url,
      name: img.name,
      __type: "File"
    }));
    const whatsappNumber = this.websiteForm.value.whatsappNumber+'';
    const contactCta = this.websiteForm.value.contactCta;
    const metaTitle = this.websiteForm.value.metaTitle;
    const metaDescription = this.websiteForm.value.metaDescription;

   
    try {
      const data = {
        profileSlug,
        websiteTitle,
        websiteDescription,
        isWebsite: this.isWebsite,
        productImages,
        websiteOwnLink,
        whatsappNumber,
        contactCta,
        metaTitle,
        metaDescription,
        isApproved: false,
      };

      const entityObject = this.authService.getUser();
      if (entityObject) {
        const response = await this.dataService.updateToServer(
          "classes/Entity/" + entityObject.entityId.objectId,
          data
        );
        if (response) {
          this.isApproved =  false;
          this.notifier.notify("success", "Your landing page under review");
        }
      }
    } catch (error) {
      this.notifier.notify("error", "Unable to add your Landing Page");
      console.error("Error updating slug integrations:", error);
    } finally {
      this.isFormSubmitted = false;
    }
  }

  public async viewProductPage() {
    const profileSlug = this.websiteForm.value.slugName;
    // debugger;
    try {
      if (profileSlug) {
        // window.open(`http://localhost:3000/profile/${profileSlug}`, '_blank');
        window.open(`https://inleads.ai/landing/${profileSlug}`, "_blank");
      }
    } catch (e) {
      console.log(e);
    }
  }

  public async saveToggleState() {
    try {
      const data = {
        isWebsite: this.isWebsite,
        widgetsActionStatus: this.widgetsActionStatus,
        whatAppWidgetStatus: this.whatAppWidgetStatus,
        contactFormWidgetStatus: this.contactFormWidgetStatus,
        joinWaitlistWidgetStatus: this.joinWaitlistWidgetStatus || false,
        isApproved: false,
      };

      const entityObject = this.authService.getUser();

      if (
        entityObject &&
        entityObject.entityId &&
        entityObject.entityId.objectId
      ) {
        console.log("Sending data to server:", data);

        const response = await this.dataService.updateToServer(
          `classes/Entity/${entityObject.entityId.objectId}`,
          data
        );

        console.log("Server response:", response);

        if (response && data.isWebsite === false) {
          this.notifier.notify("success", "Your Landing Page is Offline");
        } else if (response && data.widgetsActionStatus !== false) {
          this.notifier.notify("success", "Call to actions enabled");
        } else if (response && data.widgetsActionStatus === false) {
          this.notifier.notify("error", "Call to actions disabled");
        } else {
          this.notifier.notify("error", "Unexpected response from the server");
        }
      } else {
        throw new Error("Invalid entity object");
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error(
          "Error updating the landing page toggle state:",
          error.message
        );
      } else {
        console.error(
          "Unknown error updating the landing page toggle state:",
          error
        );
      }
      this.notifier.notify(
        "error",
        "Unable to update the Landing page toggle state"
      );
    }
  }

  public async fetchInitialIsWebsiteValue() {
    try {
      const entityObject = this.authService.getUser();
      if (entityObject) {
        const response = await this.dataService.getFromServer(
          "classes/Entity/" + entityObject.entityId.objectId
        );
        this.isApproved = response.isApproved
        console.log('response',response);
        if (response && response.isWebsite !== undefined) {
          this.isWebsite = response.isWebsite;
          if (this.isWebsite) {
            this.initialSlug = response.profileSlug || "";
            this.websiteForm.patchValue({
              slugName: response.profileSlug || "",
              websiteTitle: response.websiteTitle || "",
              websiteDescription: response.websiteDescription || "",
              websiteOwnLink: response.websiteOwnLink || "",
              whatsappNumber: response.whatsappNumber || "",
              contactCta: response.contactCta || "",
              metaTitle: response.metaTitle || "",
              metaDescription: response.metaDescription || "",
            });
            this.imgURL = response.entityImage ? response.entityImage.url : "";
            this.productImages = response.productImages
              ? response.productImages.map((img: any) => ({
                  url: img.url,
                  name: img.name
                }))
              : [];

            this.websiteForm.updateValueAndValidity();
          }
        }
      }
    } catch (error) {
      this.notifier.notify(
        "error",
        "Unable to fetch the initial isWebsite value"
      );
      console.error("Error fetching initial value:", error);
    }
  }

 
}
