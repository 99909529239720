import { Routes } from '@angular/router';
import { DashboardComponent } from '../screens/dashboard/dashboard.component';
import { RegistrationComponent } from '../screens/registration/registration.component';
import { LoginComponent } from '../screens/login/login.component';
import { ForgotpasswordComponent } from '../screens/forgotpassword/forgotpassword.component';
import { AuthGuardService } from '../services/auth-guard.service';
import { NoAuthenticationService } from '../services/no-authentication.service';
import { SettingsComponent } from '../screens/settings/settings.component';
import { PrivacyPolicyComponent } from '../screens/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from '../screens/terms-conditions/terms-conditions.component';
import { ReportsComponent } from '../screens/reports/reports.component';
import { ContactsComponent } from '../screens/contacts/contacts.component';
import { LeadsComponent } from '../screens/leads/leads.component';
import { ProfileComponent } from '../screens/profile/profile.component';
import { AgentsComponent } from '../screens/agents/agents.component';
import { ManagersComponent } from '../screens/managers/managers.component';
import { ProductsComponent } from '../screens/products/products.component';
import { SubscriptionComponent } from '../screens/subscription/subscription.component';
import { ContactDetailComponent } from '../screens/contact-detail/contact-detail.component';
import { SpacesComponent } from '../screens/spaces/spaces.component';
import { QuotesComponent } from '../screens/quotes/quotes.component';
import { ArticlesComponent } from '../screens/articles/articles.component';
import { FollowUpsComponent } from '../screens/follow-ups/follow-ups.component';
import { TasksComponent } from '../screens/tasks/tasks.component';
import { OutreachComponent } from '../screens/outreach/outreach.component';
import { OutreachTemplateComponent } from '../screens/outreach-templates/outreach-templates.component';
import { DauMetricsComponent  } from '../screens/dau-metrics/dau-metrics.component';
import { VisitorsComponent } from '../screens/visitors/visitors.component';
import { VisitorDeatilsComponent } from '../screens/visitor-deatils/visitor-deatils.component';
import { LeadDetailsComponent } from '../screens/lead-details/lead-details.component';
import { GroupsComponent } from '../screens/groups/groups.component';
import { SupportComponent } from '../screens/support/support.component';
import { LandingPageComponent } from '../screens/landing-page/landing-page.component';
import { EventDetailsComponent } from '../screens/event-details/event-details.component';

export const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    data:[{PageName:'Login'}],
    canActivate: [NoAuthenticationService],
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    data:[{PageName:'Dashboard', Title: 'Dashboard'}],
    canActivate: [AuthGuardService],
  },
  {
    path: 'dashboard/:id',
    component: DashboardComponent,
    data:[{PageName:'Dashboard', Title: 'Dashboard'}],
    canActivate: [AuthGuardService],
  },
  {
    path: 'login',
    component: LoginComponent,
    data:[{PageName:'Login'}],
    canActivate: [NoAuthenticationService],
  },
  // {
  //   path: 'registration/customer',
  //   component: RegistrationComponent,
  //   data:[{PageName:'Customer SignUp'}],
  //   canActivate: [NoAuthenticationService],
  // },
  // {
  //   path: 'registration/agent',
  //   component: RegistrationComponent,
  //   data:[{PageName:'Agent SignUp'}],
  //   canActivate: [NoAuthenticationService],
  // },
  {
    path: 'registration',
    component: RegistrationComponent,
    data:[{PageName:'SignUp'}],
    canActivate: [NoAuthenticationService],
  },
  {
    path: 'forgot',
    component: ForgotpasswordComponent,
    data:[{PageName:'Forgot'}],
    canActivate: [NoAuthenticationService],
  },
  {
    path: 'reports',
    component: ReportsComponent,
    data:[{PageName:'Analytics'}],
    canActivate: [AuthGuardService],
  },
  {
    path: 'profile',
    component: ProfileComponent,
    data:[{PageName:'Profile'}],
    canActivate: [AuthGuardService],
  },
  {
    path: 'support',
    component: SupportComponent,
    data:[{PageName:'Support'}],
    canActivate: [AuthGuardService],
  },
  {
    path: 'contacts',
    component: ContactsComponent,
    data:[{PageName:'Contacts', Title: 'Network'}],
    canActivate: [AuthGuardService],
  },
  {
    path: 'contact-details',
    component: ContactDetailComponent,
    data:[{PageName:'Customer-details'}],
    canActivate: [AuthGuardService],
  },
  {
    path: 'products',
    component: ProductsComponent,
    data:[{PageName:'Products', Title: 'Products'}],
    canActivate: [AuthGuardService],
  },
  {
    path: 'agents',
    component: AgentsComponent,
    data:[{PageName:'Agents', Title: 'Team'}],
    canActivate: [AuthGuardService],
  },
  {
    path: 'managers',
    component: ManagersComponent,
    data:[{PageName:'Managers'}],
    canActivate: [AuthGuardService],
  },
  {
    path: 'settings',
    component: SettingsComponent,
    data:[{PageName:'Settings'}],
    canActivate: [AuthGuardService],
  },
  {
    path: 'settings/:tabName',
    component: SettingsComponent,
    data:[{PageName:'Settings'}],
    canActivate: [AuthGuardService],
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    data:[{PageName:'Privacy Policy'}],
  },
  {
    path: 'terms-conditions',
    component: TermsConditionsComponent,
    data:[{PageName:'Terms Conditions'}],
  },
  {
    path: 'leads',
    component: LeadsComponent,
    data:[{PageName:'Leads'}],
    canActivate: [AuthGuardService],
  },
  {
    path: 'leads/:id',
    component: LeadsComponent,
    data:[{PageName:'Leads'}],
    canActivate: [AuthGuardService],
  },
  {
    path: 'lead/add',
    component: LeadsComponent,
    data:[{PageName:'Leads'}],
    canActivate: [AuthGuardService],
  },
  {
    path: 'spaces',
    component: SpacesComponent,
    data:[{PageName:'Spaces'}],
    canActivate: [AuthGuardService],
  },
  {
    path: 'account',
    component: SubscriptionComponent,
    data:[{PageName:'Account'}],
    canActivate: [],
  },
  {
    path: 'redirect',
    component: LoginComponent,
    data:[{PageName:'Login'}],
    canActivate: [NoAuthenticationService]
  },
  {
    path: 'quotes',
    component: QuotesComponent,
    data:[{PageName:'Quotes'}],
    canActivate: [AuthGuardService],
  },
  {
    path: 'articles',
    component: ArticlesComponent,
    data:[{PageName:'Articles'}],
    canActivate: [AuthGuardService],
  },
  {
    path: 'tasks',
    component: TasksComponent,
    data:[{PageName:'Tasks', Title: 'Tasks'}],
    canActivate: [AuthGuardService],
  },
  {
    path: 'landingPage',
    component: LandingPageComponent,
    data:[{PageName:'LandingPage'}],
    canActivate: [AuthGuardService],
  },
  {
    path: 'activity',
    component: FollowUpsComponent,
    data:[{PageName:'Activity'}],
    canActivate: [AuthGuardService],
  },
  {
    path: 'outreach-template',
    component: OutreachTemplateComponent,
    data:[{PageName:'Outreach-Templates'}],
    canActivate: [AuthGuardService],
  },
  {
    path: 'outreach',
    component: OutreachComponent,
    data:[{PageName:'Outreach'}],
    canActivate: [AuthGuardService],
  },
  {
    path: 'visitors',
    component: VisitorsComponent,
    data:[{PageName:'Visitors'}],
    canActivate: [AuthGuardService],
  },
  {
    path: 'visitor-details',
    component: VisitorDeatilsComponent,
    data:[{PageName:'Visitor-details'}],
    canActivate: [AuthGuardService],
  },
  {
    path: 'event-details',
    component: EventDetailsComponent,
    data:[{PageName:'Event-details'}],
    canActivate: [AuthGuardService],
  },
  {
    path: 'groups',
    component: GroupsComponent,
    data:[{PageName:'Groups'}],
    canActivate: [AuthGuardService],
  },
  {
    path: 'lead-details',
    component: LeadDetailsComponent,
    data:[{PageName:'Lead-details'}],
    canActivate: [AuthGuardService],
  },
  {
    path: 'dau-metrics',
    component: DauMetricsComponent,
    data: [{ PageName:'dau-metrics', Title: 'Active Users'}],
    canActivate: [AuthGuardService],
  },
];
