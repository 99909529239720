import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { AuthService } from './auth';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  constructor(
    public dataService: DataService,
    public authService: AuthService,
  ) { }


  public async getEventsList(isUniqueEvent = false) {
    let eventsList:any = [];
    const params = {
      isUniqueEvent,
      entityObjectId: this.authService.getUser().entityId.objectId
    }
    const entityObjectIdList = await this.dataService.postToServer(
      "functions/getEventsList",
      params
    );
      entityObjectIdList.result.forEach((obj: any) => {
      eventsList.push(obj.objectId ? obj : {'name':obj})
    })

    return eventsList
  }

  public async getEventsMappingList() {
    const eventsMappingQuery = this.authService.isSuperAdmin() ? {} : {
      include: 'templateId,stage',
      where: {
        entity: {
          __type: "Pointer",
          className: "Entity",
          objectId: this.authService.getUser().entityId.objectId
        },
        isActive: true,
      },
    };
    try {
      const resp = await this.dataService.getFromServer("classes/EventMapping", eventsMappingQuery);
      return resp.results || []
    } catch (error) {
      console.error('Error fetching events mapping list:', error);
    }
  }


}
