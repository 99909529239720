import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { AuthService } from "./auth";
import * as moment from 'moment';
import { UserService } from "./user.service";
import { DataService } from "./data.service";


@Injectable()
export class AuthGuardService implements CanActivate {
  public userEntityData :any;
  public TRAIL_PERIOD_DAYS = 30;
  constructor(private auth: AuthService, private route: Router,public userService: UserService,private dataService: DataService) {
  }

  public async canActivate(route: ActivatedRouteSnapshot) {
    route
    const user:any = localStorage.getItem('user');

    if (this.auth.isSignedIn()) {
      // Check if the user is subscribed
      if (!this.userEntityData) {
        this.userEntityData = await this.userService.userEntityData();
      }
      if (this.userEntityData.objectId != user.objectId) {
        this.userEntityData = await this.userService.userEntityData();
      }
      // Check if the user is subscribed
      const ctrAt =new Date(this.userEntityData.createdAt);
      const userCreatedAt = moment(ctrAt);
      if(this.userEntityData && this.userEntityData.entityId) {
      const trialPeriod = this.userEntityData.entityId.trialPeriod ? Number(this.userEntityData.entityId.trialPeriod) : this.TRAIL_PERIOD_DAYS;
      const fifteenDaysAdded = moment(userCreatedAt).add(trialPeriod, 'days');
      const isTrileExpire = this.userEntityData.paidUser ? false : moment().isAfter(fifteenDaysAdded)
      if (isTrileExpire) {
        this.route.navigate(["/account"]);
        return true;
      }
    }
     return true;
    } else {
      localStorage.clear();
      sessionStorage.clear();
      await this.dataService.cleanHeaders();
      this.route.navigate(["login"]);
      return true;
    }
    this.route.navigate(["login"]);
    return false;
  }
}
