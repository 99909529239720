import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from "@angular/core";
import { NotifierService } from "angular-notifier";
import { DataService } from "src/app/services/data.service";
import { UserService } from "src/app/services/user.service";
import { BsModalRef, ModalDirective } from "ngx-bootstrap/modal";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import * as moment from "moment";
import { CountriesList } from "src/app/constants/countries";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { track } from '@inleads/event-logger';

declare var Stripe: any;
@Component({
  selector: "app-subscription",
  templateUrl: "./subscription.component.html",
  styleUrls: ["./subscription.component.scss"]
})
export class SubscriptionComponent implements OnInit, AfterViewInit {
  @ViewChild("paymentTemplate") public paymentTemplate: ModalDirective;
  @ViewChild("updatePaymentTemplate")
  public updatePaymentTemplate: ModalDirective;
  @ViewChild(DataTableDirective) public datatableElement: DataTableDirective;
  @ViewChild("tableWrapper") public tableWrapperElement: ElementRef;
  public dtElement: DataTableDirective;
  public dtTrigger: Subject<any> = new Subject<any>();
  public dtRendered = false;
  public isInitCompleted = false;
  public dtOptions = {};
  public dtInstance: DataTables.Api;
  public userDetails: any;
  public isSubscribed: boolean;
  public subscriptionDetails: any;
  public stripeCustomerId: string;
  public planId: string;
  public subscriptionItemsId: string;
  public isLoader: boolean;
  public planType: string;
  public modalRef: BsModalRef;
  public stripe: any;
  public updateStripe: any;
  public updateSelectedCard: any;
  public countriesList: { name: string; code: string }[] = CountriesList;
  /*private stripeKey = 'pk_test_rpPs3rDMwpqaT7wY16AYErO5';*/
  private stripeKey =
    "pk_live_51JNCynSGwJP69Nw9jMulMlED1pzPQos6GNFJLuLW6CvdbK1ye3AHSc2z5Z3GZT9RngFdo9JBwDQXWfbLPQL9i5PV00pubGJILK";

  /*private stripeKey =
    "pk_test_51JNCynSGwJP69Nw9ZsCoQaqKb1VbHcWqax4K0VwTFlbcijP141O0jdqMpd1Vr9sXzsI4TUkHvSHgyp8MetdmKp9q004SX1lUEd";*/
  public card: any;
  public updateCardForm: any;
  // public isStripeFormValid = true;
  public stripePaymentIntentKey: string;
  public isLoading: boolean;
  public paidUser: boolean;
  public stripeForm: FormGroup;
  public subscriptionType: string;
  public isNewCard: boolean;
  public isUpdateNewCard: boolean;
  public toggleTabs = 0;
  public useINR = false;
  public billingHistoryList = [];
  public cards: any = [];
  public trialPeriod : number;
  public TRAIL_PERIOD_DAYS = 30;
  timeZone: string;
  currencySymbol: string;

  constructor(
    public userService: UserService,
    private dataService: DataService,
    private notifier: NotifierService,
    private fb: FormBuilder,
    public cdr: ChangeDetectorRef,
    private ngxService: NgxUiLoaderService
  ) {}

  public async ngOnInit() {
    this.isLoader = true;
    this.ngxService.start();
    this.userDetails = this.userService.getUser();
    this.getTimeZone();
    this.paidUser = this.userDetails.paidUser;
    if (
      Intl.DateTimeFormat().resolvedOptions().timeZone === "Asia/Kolkata" ||
      Intl.DateTimeFormat().resolvedOptions().timeZone === "Asia/Calcutta"
    ) {
      this.useINR = true;
    }
    const trialEndDate = moment(this.userDetails.createdAt).add(this.TRAIL_PERIOD_DAYS, 'days');
    const todayDate = moment();
    this.trialPeriod = trialEndDate.diff(todayDate, 'days');
    if (this.userDetails.stripeCustomerId) {
      this.stripeCustomerId = this.userDetails.stripeCustomerId;
      this.getSubscriptionDetails();
      await this.getInvoiceDetails();
      this.dtInstance = await this.datatableElement.dtInstance;
    } else {
      this.planType = "";
    }

    setTimeout(() => {
      this.isLoader = false;
    }, 100);
    await this.loadCustomerCards();
    this.stripeForm = this.fb.group({
      name: ["", Validators.required],
      address: ["", Validators.required],
      city: ["", Validators.required],
      state: ["", Validators.required],
      country: ["", Validators.required],
      postalcode: ["", Validators.required],
      creditCard: ["", Validators.required]
    });
    await new Promise(resolve => setTimeout(resolve, 150));
    this.isInitCompleted = true;

    await new Promise(resolve => setTimeout(resolve, 500));
    if (!this.stripe) {
      this.stripe = Stripe(this.stripeKey);
    }
    const elements = this.stripe.elements();
    this.card = elements.create("card", {
      hidePostalCode: true
    });
    this.card.mount("#card-element");
    this.ngxService.stop();
  }

  public async loadCustomerCards() {
    const response = await this.dataService.postToServer(
      "functions/GetCustomerCards",
      {}
    );
    this.cards = response.result;
    return response.result;
  }

  public async payToPlan(type: string) {
    if (!this.stripeForm.valid) {
      alert("Please complete the form");
      return;
    }
    this.paymentTemplate.hide();
    this.isLoading = false;
    this.ngxService.start();
    // this.selectedPlan = this.PremiumLivePlanId;
    if (!this.stripeCustomerId) {
      this.createStripeCustomer(type);
    } else {
      this.subscribeToPlan(type, this.stripeCustomerId);
    }
  }

  public ngAfterViewInit() {
    this.dtTrigger.next();
  }

  public ngOnDestroy() {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  public async createStripeCustomer(type: string) {
    try {
      const params = {
        name: this.stripeForm.get("name")!.value,
        email: this.userDetails.email,
        userId: this.userDetails.objectId,
        address: this.stripeForm.get("address")!.value,
        city: this.stripeForm.get("city")!.value,
        state: this.stripeForm.get("state")!.value,
        postal_code: this.stripeForm.get("postalcode")!.value,
        country: this.stripeForm.get("country")!.value
      };
      const response = await this.dataService.postToServer(
        "functions/CreateCustomer",
        params
      );
      await this.userService.updateUser("stripeCustomerId", response.result);
      this.userDetails.stripeCustomerId = response.result;
      // this.stripeCustomerId = response.result;
      this.subscribeToPlan(type, response.result);
    } catch (error) {
      this.notifier.notify("error", JSON.stringify(error));
      console.log(JSON.stringify(error));
      this.ngxService.stop();
    }
  }

  public async subscribeToPlan(planType: string, custID: string) {
    try {
      this.ngxService.start();
      const _planType = this.useINR ? `${planType}_INR` : planType;
      let cardId = this.stripeForm.get("creditCard")!.value;
      if (cardId === "AddNewCard") {
        const card = await this.addCardPayment(this.stripe);
        cardId = card.id;
      }
      const params = {
        stripeCustomerId: custID,
        planType: _planType,
        userId: this.userDetails.objectId,
        subscriptionId:
          this.paidUser && this.isSubscribed
            ? this.userDetails.stripeSubscriptionId || null
            : null,
        itemsId: this.subscriptionItemsId || null,
        cardId: cardId
      };
      const response = await this.dataService.postToServer(
        "functions/CreateSubscription",
        params
      );
      track("CREATE_SUBSCRIPTION");
      if (!this.paidUser) {
        this.stripePaymentIntentKey = response.result.paymentIntent;
        this.stripeCustomerId = custID;
        await this.userService.updateUser(
          "stripeSubscriptionId",
          response.result.id
        );
        this.userDetails.stripeSubscriptionId = response.result.id;

        this.cardPayment(response.result.id, cardId);
      } else {
        this.notifier.notify("success", "Your Subscription is Successfull");
        await this.userService.updateUser(
          "stripeSubscriptionId",
          response.result.id
        );
        this.stripeCustomerId = custID;
        this.userDetails.stripeSubscriptionId = response.result.id;
        this.getSubscriptionDetails();
      }
    } catch (e) {
      this.notifier.notify("error", JSON.stringify(e));
      this.ngxService.stop();
    }
  }

  public async createCard(type: string) {
    this.subscriptionType = type;
    if (!this.paidUser) {
      this.paymentTemplate.show();
    } else {
      this.subscribeToPlan(type, this.stripeCustomerId);
    }
    if (!this.stripe) {
    this.stripe = Stripe(this.stripeKey);
    }
    await new Promise(resolve => setTimeout(resolve, 1500));
    const elements = this.stripe.elements();
    this.card = elements.create("card", {
      hidePostalCode: true
    });
    this.card.mount("#card-element");
    this.card.addEventListener("change", (event: any) => {
      // this.isStripeFormValid = true;
      const displayError = document.getElementById(
        "card-errors"
      ) as HTMLElement;
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = "";
      }
      if (event.complete) {
        // this.isStripeFormValid = false;
      }
    });
  }

  public async addCardPayment(stripObject: any) {
    // Create payment method and confirm payment intent.
    try {
    const cardToken = await stripObject.createToken(this.card);
    const response = await this.dataService.postToServer(
      "functions/AddCustomerCard",
      { token: cardToken.token.id }
    );
      if (response.result.type && response.result.type === "makePayment") {
        const card = await this.makeTestPayment(
          response.result.paymentIntent,
          cardToken.token.id
        );
        return card.pop();
      } else {
    this.cards = [...this.cards, response.result];
    return response.result;
  }
    } catch (e) {
      this.ngxService.stop();
      throw e.message;
    }
  }

  public async makeTestPayment(paymentIntent: any, token: string) {
    try {
      const response = await this.stripe.confirmCardPayment(
        paymentIntent.client_secret,
        {
          payment_method: {
            type: "card",
            card: { token }
          },
          setup_future_usage: "off_session"
        }
      );
      if (response && response.error) {
        alert(response.error.message);
        throw new Error(response.error.message);
      }

      return await this.loadCustomerCards();
    } catch (e) {
      throw new Error(e.message);
    }
  }

  public cardPayment(subscriptionId: string, cardId: string) {
    this.isLoading = false;
    // this.subscriptionId = subscriptionId;
    // this.isStripeFormValid = true;
    /*const addr = this.stripeForm.get("address")!.value;
    const city = this.stripeForm.get("city")!.value;
    const state = this.stripeForm.get("state")!.value;
    const country = this.stripeForm.get("country")!.value;
    const postalCode = this.stripeForm.get("postalcode")!.value;
alert(cardId)*/
    // Create payment method and confirm payment intent.
    this.stripe
      .confirmCardPayment(this.stripePaymentIntentKey, {
        payment_method: cardId,
        setup_future_usage: "off_session"
      })
      .then(async (result: any) => {
        if (this.modalRef) {
        this.modalRef.hide();
        }
        // this.isStripeFormValid = false;
        if (result.error) {
          // this.cancelSubscription(false);
          this.ngxService.stop();
          alert(result.error.message);
        } else {
          if (result.paymentIntent.status === "succeeded") {
            await this.dataService.postToServer("functions/saveSubscription", {
              subscriptionId,
              userId: this.userDetails.objectId
            });
            this.userService.updateUser("paidUser", true);
            this.userService.updateUser("stripeSubscriptionId", subscriptionId);
            this.isSubscribed = true;
            this.userDetails.paidUser = true;
            this.userDetails.stripeSubscriptionId = subscriptionId;
            this.notifier.notify("success", "Your Subscription is Successfull");
            // this.planId = this.selectedPlan;
            this.getSubscriptionDetails();
          }
        }
      });
  }

  public async getSubscriptionDetails() {
    if (!this.userDetails.stripeSubscriptionId) {
      this.isSubscribed = false;
      this.planId = "";
      this.planType = "";
      this.ngxService.stop();
      return;
    }
    try {
      const getSubscription = await this.dataService.postToServer(
        "functions/GetSubscriptionDetails",
        {
          id: this.userDetails.stripeSubscriptionId,
          userId: this.userDetails.objectId
        }
      );
      this.ngxService.stop();
      this.subscriptionDetails = getSubscription;
      if (
        getSubscription.result.status !== "active" &&
        this.userDetails.paidUser
      ) {
        await this.cancelSubscription(false);
        this.isSubscribed = false;
        this.planId = "";
        this.planType = "";
        return;
      } else if (!this.userDetails.paidUser) {
        this.isSubscribed = false;
        this.planId = "";
        this.planType = "";
        if (getSubscription) {
          this.subscriptionItemsId = getSubscription.result.items.data[0].id;
        }
        return;
      }
      this.isSubscribed = true;
      this.planId = getSubscription.result.items.data[0].plan.id;
      if (getSubscription.result.items.data[0].plan.currency === "inr") {
        this.useINR = true;
      }
      let planType =
        getSubscription.result.plan.product.name ||
        getSubscription.result.plan.nickname;
      planType = planType.split(" ")[0].toLowerCase();
      this.planType = planType.charAt(0).toUpperCase() + planType.substr(1);
      this.subscriptionItemsId = getSubscription.result.items.data[0].id;
      this.paidUser = true;
      /* if (this.planId === this.BasicLivePlanId) {
        this.planType = 'Basic';
      }
      else if (this.planId === this.PremiumLivePlanId) {
        this.planType = 'Premium';
      } */
    } catch (error) {
      this.ngxService.stop();
      this.isSubscribed = false;
      this.planId = "";
      this.planType = "";
    }
  }

  public async cancelSubscription(call: boolean) {
    if (confirm("Are you sure, you want to cancel your subscription")) {
    try {
        this.ngxService.start();
        await this.dataService.postToServer("functions/CancelSubscription", {
          id: this.userDetails.stripeSubscriptionId,
          userId: this.userDetails.objectId
        });
      track("CANCELLED_SUBSCRIPTION");
      this.isSubscribed = false;
        this.planType = "";
        this.planId = "";
      this.paidUser = false;
        this.userDetails.stripeSubscriptionId = "";
        this.userService.updateUser("paidUser", false);
        this.userService.updateUser("stripeSubscriptionId", "");
        this.notifier.notify("error", "Your Subscription is Cancelled");
      if (call) {
        this.getSubscriptionDetails();
      }
    } catch (error) {
        this.notifier.notify("error", error.message);
      }
      this.ngxService.stop();
    }
  }

  public cardCancel() {
    this.paymentTemplate.hide();
    // this.paidUser = false;
    // this.cancelSubscription(false);
  }

  public updateSubscription(type: string) {
    /* let message = '';
    if (type === 'Starter') {
      message = 'Do you want to Downgrade your subscription from Premium to ' + type + ' Plan?';
    } else if (type === 'Premium') {
      message = 'Do you want to Upgrade your subscription to ' + type + ' Plan?';
    } */
    // confirmation modal and if confirmed
    if (!this.paidUser && !this.stripeCustomerId) {
      this.paymentTemplate.show();
      this.createCard(type);
    } else {
      this.subscribeToPlan(type, this.stripeCustomerId);
    }
  }

  public async getInvoiceDetails() {
    try {
      const getInvoiceList = await this.dataService.postToServer(
        "functions/getInvoiceList",
        { stripeCustomerId: this.userDetails.stripeCustomerId }
      );
      
      this.billingHistoryList = getInvoiceList.result.data.filter((item: any) => {
        return item.status === "paid";
      });;

      this.dtOptions = {
        columnDefs: [
          {
            targets: 0,
            width: "2px",
            visible: false
          },
          {
            defaultContent: "",
            targets: "_all"
          }
        ],
        language: {
          search: "_INPUT_",
          searchPlaceholder: "Search...",
          lengthMenu: "<span>Show</span> _MENU_"
        },
        responsive: true,
        data: this.billingHistoryList,
        columns: [
          { data: "" },
          {
            data: (row: any) => {
              let createdAt = moment(
                row.status_transitions.paid_at,
                "X"
              ).format("DD/MM/YYYY");
              return createdAt;
            }
          },
          {
            data: (row: any) => {
              return row.amount_paid ? row.amount_paid / 100 : 0;
            }
          },
          {
            data: () => {
              let res;
              res = '<span class="fa fa-download cpointer"></span>';
              return res;
            }
          }
        ],
        rowCallback: (row: Node, data: any) => {
          row.removeEventListener("click", () => {});
          row.addEventListener("click", e => {
            if ((e.target as HTMLElement).classList.contains("fa-download")) {
              window.open(data.invoice_pdf, "_blank");
            }
          });
          return row;
        }
      };

      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        setTimeout(() => {
          this.dtTrigger.next();
        }, 10);
      });
    } catch (error) {
      console.log("error ::: ", error);
    }
  }
  public updateCards(cards: any) {
    this.cards = cards;
  }
  public onCardSelected(event: any) {
    this.isNewCard = event.target.value === "AddNewCard";
  }
  public onUpdateCardSelected(event: any) {
    this.isUpdateNewCard = event.target.value === "AddNewCard";
  }
  public getCardNumber(default_payment_method: string, cards: any) {
    const card = cards.find((card: any) => card.id === default_payment_method);
    if (!card) {
      return "";
    }
    return "XXXX-XXXX-XXXX-" + card.last4;
  }
  public getCardExpiry(default_payment_method: string, cards: any) {
    const card = cards.find((card: any) => card.id === default_payment_method);
    if (!card) {
      return "";
    }
    return `${card.exp_month}/${card.exp_year}`;
  }
  public getSubscriptionEnding() {
    if (
      this.subscriptionDetails &&
      this.subscriptionDetails.result &&
      this.subscriptionDetails.result.default_payment_method
    ) {
      return moment(
        this.subscriptionDetails.result.current_period_end,
        "X"
      ).format("DD MMM, YYYY");
    }
    return "";
  }

  public onUpdatePaymentModalShown() {
    if (!this.updateStripe) {
      this.updateStripe = Stripe(this.stripeKey);
    }
    const elements = this.updateStripe.elements();
    this.updateCardForm = elements.create("card", {
      hidePostalCode: true
    });
    this.updateCardForm.mount("#update-card-element");
    if (
      this.subscriptionDetails &&
      this.subscriptionDetails.result &&
      this.subscriptionDetails.result.default_payment_method
    ) {
      this.updateSelectedCard = this.subscriptionDetails.result.default_payment_method;
    }
  }

  public async onUpdateSubscriptionPayment() {
    this.ngxService.start();
    let cardId = this.updateSelectedCard;
    try {
      if (cardId === "AddNewCard") {
        const card = await this.addCardPayment(this.updateStripe);
        cardId = card.id;
      }
      this.subscriptionDetails = await this.dataService.postToServer(
        "functions/UpdateSubscriptionPayment",
        { cardId: cardId }
      );
      this.updatePaymentTemplate.hide();
    } catch (e) {
      alert(e.message);
    }
    this.ngxService.stop();
  }

  // Finding user timezone and countrycode and show subscription fee (₹ or $) accordingly
  getTimeZone() {
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const countryCode = moment.tz(this.timeZone).zoneAbbr();
    this.currencySymbol = countryCode === 'IST' ? '₹' : '$';
  }

}
