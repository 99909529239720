import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../services/data.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AuthService } from '../../services/auth';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-follow-ups',
  templateUrl: './follow-ups.component.html',
  styleUrls: ['./follow-ups.component.scss']
})
export class FollowUpsComponent implements OnInit {

  public followupData: any = {};
  public profileImg: string;
  @ViewChild('standUpModal') public standUpModal?: ModalDirective;
  public isLoading: boolean;
  public isEmptyState: boolean;

  public standUpData: any = {
    question1: {
      question: '',
      answer: '',
      questionId: '',
      followupRespId: ''
    },
  };
  public currentQuestionData = {
    index: 1,
    questionId: '',
    answer: this.standUpData.question1.answer,
  };
  public followUpId: string;
  public followUpCount: number = 0;
  public isSubmitted: boolean;
  public isOpenModal: boolean;

  constructor(
    private dataService: DataService,
    private auth: AuthService,
    private notifier: NotifierService,
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.getFollowUpsResponse();
  }


  private getFollowUpsResponse = async () => {

    const user = localStorage.getItem('user');
    let currentUser : any;
    if (user) {
      currentUser = JSON.parse(user);
    }
    this.profileImg = 'assets/images/user-01.svg';
    
    const userParams: any = {
      entityId: currentUser && currentUser.entityId && currentUser.entityId.objectId,
    };
    const followUpResponseList = await this.dataService.postToServer('functions/getFollowUpResponse', userParams);
    if (followUpResponseList.result.length) {
      let self = this;
      const followUpDataGroupedByDate = _.groupBy(followUpResponseList.result, function (obj) {
        return self.dateToFromNowDaily(moment(obj.createdAt));
      });
      this.formatFollowUp(followUpDataGroupedByDate);
      this.isLoading = false;
      this.isEmptyState = false;
    }
    else{
      this.isLoading = false;
      this.isEmptyState = true;
      this.getFollowup();
    }
  }

  private formatFollowUp = (data: any) => {
    if (Object.keys(data).length) {
      for (const [key, value] of Object.entries(data)) {
        let data: any = value;
        const followUpDataGroupedByName = _.groupBy(data, function (obj) {
          return obj.user.name;
        });
        this.followupData[key] = followUpDataGroupedByName;
      }
    }
  };

  // call this function, passing-in your date
  public dateToFromNowDaily = (myDate: any) => {
    let date = moment(myDate);
    if (moment().diff(date, 'days') > 0) {
      return moment(myDate).format('DD/MM/YYYY'); 
    }
    return date.calendar().split(' ')[0];
  }

  public async fetchFollowUpData() {
    const params = {
      include: 'entity,user,questions',
      where: {
        entity: {
          __type: 'Pointer',
          className: 'Entity',
          objectId: this.auth.getUser().entityId.objectId,
        },
      },
    };
    const paramsResponse = await this.dataService.getFromServer(
      'classes/FollowUps',
      params,
    );
    // tslint:disable-next-line:no-any
    let followUpResp: any = {};
    if (paramsResponse.results) {
      if (paramsResponse.results.length) {
        followUpResp = paramsResponse.results[0];
        this.followUpCount = paramsResponse.results.length;
        this.followUpId = followUpResp.objectId;
        this.standUpData = {};
      }
    }
    followUpResp.questions.forEach((q: any, i: number) => {
      this.standUpData[`question${i + 1}`] = {
        question: q.name,
        answer: '',
        questionId: q.objectId,
        followupRespId: ''
      }
    });
    if (Object.entries(this.standUpData).length > 0) {
      this.currentQuestionData = {
        index: 1,
        questionId: this.standUpData[`question${1}`].questionId,
        answer: this.standUpData[`question${1}`].answer,
      }
    }
  }

  public nextQuestion = () => {
    let key = 'question' + this.currentQuestionData.index;
    this.standUpData[key].answer = this.currentQuestionData.answer;
    this.currentQuestionData.index += 1;
    key = 'question' + this.currentQuestionData.index;
    this.currentQuestionData.answer = this.standUpData[key].answer;
  };

  public previousQuestion = () => {
    this.currentQuestionData.index -= 1;
    const key = 'question' + this.currentQuestionData.index;
    this.currentQuestionData.answer = this.standUpData[key].answer;
  };

  public saveFollowUpResponses = async () => {
    this.isSubmitted = true;
    const key = 'question' + this.currentQuestionData.index;
    this.standUpData[key].answer = this.currentQuestionData.answer;
    this.currentQuestionData.answer = this.standUpData[key].answer;
    const submitParams: any = {
      user: {
        __type: 'Pointer',
        className: '_User',
        objectId: this.auth.getUser().objectId,
      },
      entity: {
        __type: 'Pointer',
        className: 'Entity',
        objectId: this.auth.getUser().entityId.objectId,
      },
      followup: {
        __type: 'Pointer',
        className: 'FollowUps',
        objectId: this.followUpId,
      },
    };
      // @ts-ignore
      Object.keys(this.standUpData).forEach(async (key) => {
        const obj = {
          answer: this.standUpData[key].answer,
          question: {
            __type: 'Pointer',
            className: 'Questions',
            objectId: this.standUpData[key].questionId,
          },
          ...submitParams
        }
        await this.dataService.postToServer(
          'classes/FollowUpResponse',
          obj,
        );
      });
      await this.getFollowUpsResponse();
      this.isSubmitted = false;
      this.closefollowUpModal();
      this.notifier.notify('success', 'FollowUp Saved Successfully !!!');
  }

  public showStandUpButton = (button: string) => {
    if (button === 'previous') {
      return this.currentQuestionData.index > 1
    }
    if (button === 'next') {
      return this.currentQuestionData.index < Object.entries(this.standUpData).length
    }
    if (button === 'submit') {
      return this.currentQuestionData.index === Object.entries(this.standUpData).length
    }
    return false;
  };

  public async showFollowUpModal() {
    this.isOpenModal = true;
    await this.fetchFollowUpData();
    this.isOpenModal = false;
    if (this.followUpCount === 1) {
      this.standUpModal!.show();
    }
    else {
      this.notifier.notify('error', 'No Records Found !!!');
    }
  }

  public closefollowUpModal() {
    this.standUpModal!.hide();
  }

  public async getFollowup() {
    if (this.auth.getUser().objectId) {
      try {
        const paramsData: any = {
          where: {
            user: {
              __type: 'Pointer',
              className: '_User',
              objectId: this.auth.getUser().objectId,
            },
          },
        };
        const resp = await this.dataService.getFromServer(
          'classes/FollowUps/', paramsData
        );
        if (resp.results && resp.results.length) {
          return resp.results;
        }
      } catch (e) {
        if (e instanceof Error) {
          this.notifier.notify('error', e.message);
        }
      }
    } else {
      this.notifier.notify('error', 'Invalid user');
    }
  }

}
