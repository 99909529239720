import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { validationPattern } from 'src/app/constants/regexp';
import { AuthService } from 'src/app/services/auth';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss'],
})
export class ForgotpasswordComponent implements OnInit {
  public forgotForm: FormGroup;
  public disableFields: boolean;
  public isFormSubmitted: boolean;
  constructor(private authService: AuthService, private notifier: NotifierService) {}

  public ngOnInit() {
    const _email = null;
    this.forgotForm = new FormGroup({
      forgotemail: new FormControl(_email, [
        Validators.pattern(validationPattern.email),
      ]),
    });
  }
  public async forgot(_form: FormGroup) {
    if(!this.forgotForm.value.forgotemail){
      return this.notifier.notify('error', 'Please Enter Email ID');
    }
    this.disableFields = true;
    this.isFormSubmitted = true;
    const forgotDetails = { email: this.forgotForm.value.forgotemail };
    try {
      await this.authService.forgotPassword(forgotDetails);
      this.forgotForm.reset();
    } catch (_) {
      this.notifier.notify('error', 'Please Enter Registered Email-ID');
    }
    this.disableFields = false;
    this.isFormSubmitted = false;
  }

  public isFieldValid(field: string, currentForm: FormGroup) {
    if (currentForm.get(field) && !this.disableFields) {
      return !currentForm.get(field)!.valid && currentForm.get(field)!.touched;
    }
    return;
  }

  public displayFieldCss(field: string, currentForm: FormGroup) {
    return {
      'has-error': this.isFieldValid(field, currentForm),
      'has-feedback': this.isFieldValid(field, currentForm),
    };
  }
}
