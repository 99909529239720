import {
  AfterViewInit,
  // ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import * as moment from 'moment';
import { AuthService } from '../../services/auth';
import { DataService } from '../../services/data.service';
import { UserService } from 'src/app/services/user.service';
import { DataTableDirective } from 'angular-datatables';
import {Subject} from 'rxjs';
import { Groups } from 'src/app/types/groups';
import { ModalDirective } from 'ngx-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { Contacts } from 'src/app/types/contacts';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss'],
})
export class GroupsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('createGroupModal') public createGroupModal: ModalDirective;
  @ViewChild('editGroupModal') public editGroupModal: ModalDirective;
  @ViewChild(DataTableDirective) public dtElement: DataTableDirective;
  public dtOptions: any = {};
  public isLoading: boolean;
  public newGroupForm: FormGroup;
  public editGroupForm: FormGroup;
  public dtGroupTrigger: Subject<any> = new Subject();
  public dtGroupOptions: any = {};
  public groupsPageData: Groups[] = [];
  public disableFields: any;
  public groupOfContacts: any;
  public contactField: any;
  public contactList: Contacts[] = [];
  public currentGroup: string;
  public deleteModal = false;
  public isPrivateUser: boolean;

  constructor(
    private dataService: DataService,
    public authService: AuthService,
    public userService: UserService,
    private fb: FormBuilder,
    private notifier: NotifierService,
    // private cdr: ChangeDetectorRef,
  ) {
    this.groupOfContacts = {
      type: 'select',
      name: 'contactField',
      FormControlName: 'contactField',
      tags: true,
      placeholder: 'Select Contacts',
      select2Data: [],
      select2Options: { multiple: true, tags: true },
    };
  }

  public async ngOnInit() {
    this.isPrivateUser = this.authService.isPrivateUser();
    this.initializeGroupForm();
    await this.getContacts();
    await this.loadGroupsRequests().then();
  }

  public ngAfterViewInit() {
    this.dtGroupTrigger.next();
    // this.cdr.detectChanges();
  }

  public ngOnDestroy() {
    this.dtGroupTrigger.unsubscribe();
  }

  public async getContacts() {
    let contactConditionParams;
    if (this.userService.isSuperAdmin) {
      contactConditionParams = {
        where: {
          isActive: { $ne: false },
        },
      };
    } else if (this.userService.isAdmin) {
      contactConditionParams = {
        where: {
          entity: {
            __type: 'Pointer',
            className: 'Entity',
            objectId: this.authService.getUser().entityId.objectId,
          },
          isActive: { $ne: false },
        },
      };
    } else if (this.userService.isAgent) {
      contactConditionParams = {
        where: {
          $or: [
            {
              agent: {
                __type: 'Pointer',
                className: '_User',
                objectId: this.authService.getUser().objectId,
              },
            },
            {
              assignTo: {
                __type: 'Pointer',
                className: '_User',
                objectId: this.authService.getUser().objectId,
              },
            },
          ],
          isActive: { $ne: false },
        },
      };
    }
    const res = await this.dataService.getFromServer(
      'classes/Contacts',
      contactConditionParams,
    );
    this.contactList = res.results;
    this.initializeContactField();
    // setTimeout(() => {
    //   this.cdr.detectChanges();
    // }, 0);
  }

  private async fetchContactsForGroup(group: any) {
    if (group.contacts && group.contacts.__type === 'Relation') {
        const contactsQuery = {
            where: {
                $relatedTo: {
                    object: {
                        __type: 'Pointer',
                        className: 'Groups',
                        objectId: group.objectId,
                    },
                    key: 'contacts',
                },
            },
        };
        const contactsResponse = await this.dataService.getFromServer(
          'classes/Contacts',
          contactsQuery,
        );
        return contactsResponse.results || [];
    }
    return [];
  }

  private initializeContactField() {
    this.groupOfContacts = {
      type: 'select',
      name: 'contactField',
      FormControlName: 'contactField',
      tags: true,
      placeholder: 'Select Contacts',
      select2Data: this.contactList.map(contact => ({ id: contact.objectId, text: contact.name })),
      select2Options:{multiple: true,tags: true},
    };
  }

  private initializeGroupForm() {
    this.newGroupForm = this.fb.group({
      groupName: ['', Validators.required],
      contactField: ['', Validators.required],
      groupDescription: [''],
    });
    this.editGroupForm = this.fb.group({
      groupName: ['', Validators.required],
      contactField: ['', Validators.required],
      groupDescription: [''],
    });
  }

  public async loadGroupsRequests(skipTableRender = false) {
    if (!skipTableRender) {
      this.isLoading = true;
    }
    const groupParams = {
      include: 'entity,contacts',
      limit: 10000,
      order: '-createdAt',
      where: {
        entity: {
          __type: 'Pointer',
          className: 'Entity',
          objectId: this.authService.getUser().entityId.objectId,
        },
      },
    };
    const groups = await this.dataService.getFromServer('classes/Groups', groupParams);
    this.groupsPageData = groups.results || [];

    for (const group of this.groupsPageData) {
      group.contacts = await this.fetchContactsForGroup(group);
    }

    this.isLoading = false;
    this.dtGroupOptions = {
      order: [
        [2, 'desc'],
        [3, 'desc'],
      ],
      dom: '<"appRequestsFilter"lf>tipr',
      buttons: [
        {
          extend: 'csv',
          title: 'Export InLeads-Groups',
        },
      ],
      columnDefs: [
        {
          orderable: false,
          targets: 0,
          responsivePriority: 1,
          width: '25px',
        },
        {
          orderable: false,
          className: 'select-checkbox',
          targets: 1,
          width: '25px',
          responsivePriority: 1,
          visible: false,
        },
        {
          targets: [2],
          visible: false,
        },
        {
          orderable: true,
          targets: [3],
          visible: false,
        },
        {
          targets: [4], // Group Name
          responsivePriority: 1,
        },
        {
          targets: [5], // Group Description
          responsivePriority: 2,
        },
        {
          targets: [6], // Contacts
          responsivePriority: 3,
        },
        {
          defaultContent: 'NA',
          targets: '_all',
        },
      ],
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search...',
        lengthMenu: '_MENU_',
      },
      responsive: true,
      data: this.groupsPageData,
      info: false,
      columns: [
        {
          data: '',
        },
        { data: '' },
        {
          data: (row: Groups) => {
            return moment(row.createdAt).valueOf();
          },
        },
        {
          data: (row: Groups) => {
            return moment(row.updatedAt).valueOf();
          },
        },
        {
          data: (row: Groups) => {
            return row.groupName || 'NA';
          },
        },
        {
          data: (row: Groups) => {
            return row.groupDescription || 'NA';
          },
        },
        {
          data: (row: Groups) => {
            const contactData: any = row.contacts;
            if (Array.isArray(contactData)) {
              const contactIds = contactData.map((contact: any) => contact.objectId);
              return contactIds ? contactIds.length : 0;
            } else {
              return 'NA';
            }
          },
        },
        {
          data: () => {
            return !this.isPrivateUser ? '<span class="fa fa-pencil"></span>&nbsp;&nbsp;&nbsp;&nbsp;:<span class="fa fa-trash"></span>' : null;
          },
        },
      ],
      rowCallback: (row: Node, data: object) => {
        row.removeEventListener('click', () => {
        });
        row.addEventListener('click', e => {
          if ((e.target as HTMLElement).classList.contains('fa-pencil')) {
            this.editGroup((data as Groups).objectId);
          }else if ((e.target as HTMLElement).classList.contains('fa-trash')) {
            this.deleteGroup((data as Groups).objectId);
          }
        });
        return row;
      },
    };

    if (!skipTableRender) {
      setTimeout(() => {
        if (this.dtElement && this.dtElement.dtInstance) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            setTimeout(() => {
              this.dtGroupTrigger.next();
            }, 10);
          });
        } else {
          this.dtGroupTrigger.next();
        }
      }, 10);
    }
  }

  public async deleteGroup(groupId: string) {
    this.deleteModal = true;
    this.currentGroup = groupId;
  }

  public async clickYes() {
    const groupId = this.currentGroup;
    try {
      await this.dataService.deleteOnServer(
        'classes/Groups/' + groupId,
      );
      this.deleteModal = false;
      this.groupsPageData = this.groupsPageData.filter(
        p => p.objectId !== groupId,
      );
      await this.loadGroupsRequests().then();
      this.notifier.notify('success', 'Group deleted successfully');
    } catch (e) {
      this.notifier.notify('error', 'Something went wrong, please try again');
    }
  }

  public decline() {
    this.deleteModal = false;
  }

  public async editGroup(groupId: string) {
    this.currentGroup = groupId;
    this.editGroupModal.show();
    const groupDetails = await this.dataService.getFromServer(
      'classes/Groups/' + groupId,
    );
    let relatedContacts = [];
    if (groupDetails.contacts && groupDetails.contacts.__type === 'Relation') {
        const contactsQuery = {
            where: {
                $relatedTo: {
                    object: {
                        __type: 'Pointer',
                        className: 'Groups',
                        objectId: groupId,
                    },
                    key: 'contacts',
                },
            },
        };
        relatedContacts = await this.dataService.getFromServer(
          'classes/' + groupDetails.contacts.className,
          contactsQuery,
        );
    }

    const selectedContacts = relatedContacts.results || [];
    const selectedContactIds = selectedContacts.map((contact: any) => contact.objectId);

    this.editGroupForm.patchValue({
        groupName: groupDetails.groupName || '',
        contactField: selectedContactIds,
        groupDescription: groupDetails.groupDescription || '',
    });
  }

  public async updateGroup() {
    this.isLoading = true;
    const objectId = this.currentGroup;
    const selectedGroup = this.groupsPageData.find(
      p => p.objectId === objectId,
    );
    if (!selectedGroup) {
      this.isLoading = false;
      return;
    }
    const name = this.editGroupForm.get('groupName')!.value;
    const contactsList: string[] = this.editGroupForm.get('contactField')!.value;
    const description = this.editGroupForm.get('groupDescription')!.value;

    if(!name){
      this.notifier.notify('error', 'Name cannot be empty!');
      this.isLoading = false;
      return;
    }

    if(contactsList.length <= 0){
      this.notifier.notify('error', 'Please Select Contact');
      this.isLoading = false;
      return;
    }

    const groupDetails = await this.dataService.getFromServer('classes/Groups/' + objectId);
    let currentContacts = [];
    if (groupDetails.contacts && groupDetails.contacts.__type === 'Relation') {
        const contactsQuery = {
            where: {
                $relatedTo: {
                    object: {
                        __type: 'Pointer',
                        className: 'Groups',
                        objectId: objectId,
                    },
                    key: 'contacts',
                },
            },
        };
        const contactsResponse = await this.dataService.getFromServer(
          'classes/' + groupDetails.contacts.className,
          contactsQuery,
        );
        currentContacts = contactsResponse.results || [];
    }

    const currentContactIds = currentContacts.map((contact: any) => contact.objectId);
    const contactsToAdd = contactsList.filter(contactId => !currentContactIds.includes(contactId));
    const contactsToRemove = currentContactIds.filter((contactId: string) => !contactsList.includes(contactId));

    let groupResponse;
    let groupParams: Groups;
    groupParams = {
      objectId: objectId,
      groupName: name,
      groupDescription: description,
      entity: {
        __type: 'Pointer',
        className: 'Entity',
        objectId: this.authService.getUser().entityId.objectId,
      },
      contacts: {
        __op: 'Batch',
        objects: [],
      },
    };

    if (!groupParams.contacts.ops) {
      groupParams.contacts.ops = [];
  }

    if (contactsToAdd.length > 0) {
        groupParams.contacts.ops.push({
            __op: 'AddRelation',
            objects: contactsToAdd.map(contactId => ({
                __type: 'Pointer',
                className: 'Contacts',
                objectId: contactId,
            })),
        });
    }

    if (contactsToRemove.length > 0) {
        groupParams.contacts.ops.push({
            __op: 'RemoveRelation',
            objects: contactsToRemove.map((contactId: any) => ({
                __type: 'Pointer',
                className: 'Contacts',
                objectId: contactId,
            })),
        });
    }

    groupResponse = await this.dataService.updateToServer('classes/Groups/' + objectId, groupParams);
    if (groupResponse) {
      this.notifier.notify('success', 'Group Edited Successfully!!!');
      this.editGroupForm.reset();
      this.editGroupModal.hide();
    } else {
      this.notifier.notify('error', 'Error while editing Group.');
      this.editGroupModal.show();
    }
    this.loadGroupsRequests().then();
    this.isLoading = false;
  }

  public openGroupForm() {
    this.createGroupModal.show();
  }

  public async createGroup() {
    this.isLoading = true;
    const name = this.newGroupForm.get('groupName')!.value;
    const contactsList: string[] = this.newGroupForm.get('contactField')!.value;
    const description = this.newGroupForm.get('groupDescription')!.value;

    if(!name){
      this.notifier.notify('error', 'Name cannot be empty!');
      return;
    }

    if(contactsList.length <= 0){
      this.notifier.notify('error', 'Please Select Contact');
      return
    }
    let groupResponse;
    let groupParams: Groups;
    groupParams = {
      objectId: '',
      groupName: name,
      groupDescription: description,
      entity: {
        __type: 'Pointer',
        className: 'Entity',
        objectId: this.authService.getUser().entityId.objectId,
      },
      contacts: {
        __op: 'AddRelation',
        objects: contactsList.map(contactId => ({
          __type: 'Pointer',
          className: 'Contacts',
          objectId: contactId,
        })),
      },
    },
    groupResponse = await this.dataService.postToServer('classes/Groups/', groupParams);
    if(groupResponse){
      this.createGroupModal.hide();
      this.notifier.notify('success', 'Group created Successfully!!!');
      this.newGroupForm.reset();
    }
    else{
      this.notifier.notify('error', 'Error while creating Group.');
      this.createGroupModal.show();
    }
    this.loadGroupsRequests().then();
    this.isLoading = false;
  }

  public isFieldValid(field: string, currentForm: FormGroup) {
    if (currentForm.get(field) && !this.disableFields) {
      return !currentForm.get(field)!.valid && currentForm.get(field)!.touched;
    }
    return;
  }

  public displayFieldCss(field: string, currentForm: FormGroup) {
    return {
      'has-error': this.isFieldValid(field, currentForm),
      'has-feedback': this.isFieldValid(field, currentForm),
    };
  }

}
