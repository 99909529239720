import {Injectable} from '@angular/core';
import {DataService} from './data.service';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AudioRecordingService {

  // @ts-ignore
  private mediaRecorder: MediaRecorder | null = null;
  private audioChunks: BlobPart[] = [];
  private eventEmitter = new Subject<any>();
  // Observable stream
  public event$ = this.eventEmitter.asObservable();

  public audioContext: AudioContext;


  constructor(private dataService: DataService) {
    this.audioContext = new AudioContext();
  }


  public startRecording(): Promise<any> {

    return new Promise(async (resolve, reject) => {

      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        console.error('getUserMedia not supported on your browser!');
        reject('getUserMedia not supported on your browser!');
        return;
      }

      try {
        const stream = await navigator.mediaDevices.getUserMedia({audio: true});

        // @ts-ignore
        this.mediaRecorder = new MediaRecorder(stream);
        this.audioChunks = [];

        // @ts-ignore
        this.mediaRecorder.ondataavailable = event => {
          this.audioChunks.push(event.data);
        };

        this.mediaRecorder.onstop = async () => {
          const audioBlob = new Blob(this.audioChunks, {type: 'audio/wav; codecs=0'});
          const reader = new FileReader();

          reader.onloadend = async () => {
            const base64AudioMessage = reader.result as string;
            try {
              const audioResp = await this.dataService.postToServer('functions/get-voice-input-from-user', {audioFile: base64AudioMessage});
              resolve(audioResp);
            } catch (error) {
              reject(error);
            }
          };

          reader.readAsDataURL(audioBlob);
        };


        this.mediaRecorder.start();
      } catch (error) {
        // console.error('Audio recording error:', error);
        reject(error);
      }
    });
  }

  public async stopRecording() {
    if (this.mediaRecorder) {
      this.mediaRecorder.stop();

      const stream = await navigator.mediaDevices.getUserMedia({audio: true, video: false});

      if (stream) {
        console.log('Stopping audio stream...');
        stream.getTracks().forEach(track => {
          track.onended = () => console.log(`${track.kind} track ended`);
          track.stop();
        });
      }
      console.log('Stopping audio recording...');
    }
  }
}
