import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import {AuthService} from '../../../services/auth';
import {UserService} from '../../../services/user.service';
import {environment} from '../../../../environments/environment';
import {NotifierService} from 'angular-notifier';

@Component({
  selector: 'app-chat-doc-settings',
  templateUrl: './chat-doc-settings.component.html',
  styleUrls: ['./chat-doc-settings.component.scss'],
})
export class ChatDocSettingsComponent implements OnInit {

  public chatDocSettingsForm: FormGroup;
  public isFormSubmitted: boolean;
  public canShow = false;
  public baseURL: string = environment.apiUrl;
  public hoveredIndex = -1;
  public showDeleteConfirmation = false;
  public promptTextValue = '';
  public prompts = [];
  public isClickedConfirmBtn = false;

  constructor(
    public authService: AuthService,
    public userService: UserService,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
  ) {
  }

  public ngOnInit() {
    this.isFormSubmitted = false;
    this.initForm();
  }

  public async initForm() {
    const defaultPrompt = 'Your role is to assist users by providing information exclusively from a predefined set of documents. ' +
      'Always maintain a polite and helpful demeanor in all interactions. If a user\'s ' +
      'query matches the topics covered in documents, ' +
      'provide a relevant and concise answer. If the query falls outside your knowledge base, politely inform the user that you don\'t have information ' +
      'on that topic and encourage them to ask another question related to the documents. Avoid mentioning or explaining the underlying ' +
      'technology or process of converting documents to vectors. Aim to emulate the behavior of ' +
      'an efficient and courteous assistant';
    const botSettingsData = await this.userService.getChatDocSetting();
    this.prompts = botSettingsData.prePrompts || [];
    this.chatDocSettingsForm = this.formBuilder.group({
      name: new FormControl(botSettingsData.name ? botSettingsData.name : '', [
        Validators.required,
      ]),
      promptMessage: new FormControl(botSettingsData.initialPrompt ? botSettingsData.initialPrompt : defaultPrompt, [
        Validators.required,
      ]),
      prePrompt: new FormControl('', []),
    });
    this.canShow = true;
  }



  public async onSubmit() {
    this.isFormSubmitted = true;
    const jsonPayload = await this.prepareJsonPayload();
    await this.userService.saveChatDocSetting(jsonPayload);
    this.isFormSubmitted = false;
    this.notifier.notify('success', 'Settings updated successfully');
    this.initForm();
  }

  


  

  public async prepareJsonPayload() {
    const payload = {
      // @ts-ignore
      name: this.chatDocSettingsForm.get('name') !== null ? this.chatDocSettingsForm.get('name').value : null,
      // @ts-ignore
      initialPrompt: this.chatDocSettingsForm.get('promptMessage') !== null ? this.chatDocSettingsForm.get('promptMessage').value : null,
      files: [],
      prePrompts: this.prompts,
    };
    

    return payload;
  }

  

  public deletePrompt(index: number): void {
    this.prompts.splice(index, 1);
  }

  public addPrompt(): void {
    let errorMessage = '';
    // @ts-ignore
    if (this.promptTextValue.trim() && !this.prompts.includes(this.promptTextValue) && this.prompts.length < 5) {
      // @ts-ignore
      this.prompts.push(this.promptTextValue);
      this.promptTextValue = '';
      errorMessage = '';
    } else {
      // @ts-ignore
      if (this.prompts.includes(this.promptTextValue)) {
        errorMessage = 'This prompt already exists.';
      } else if (this.prompts.length >= 5) {
        errorMessage = 'Cannot add more than 10 items.';
      } else {
        errorMessage = 'Input cannot be empty.';
      }
      this.notifier.notify('error', errorMessage);
    }
  }


  public isFieldValid(field: string, currentForm: FormGroup) {
    if (currentForm.get(field)) {
      return !currentForm.get(field)!.valid && currentForm.get(field)!.touched;
    }
    return;
  }

  public displayFieldCss(field: string, currentForm: FormGroup) {
    return {
      'has-error': this.isFieldValid(field, currentForm),
      'has-feedback': this.isFieldValid(field, currentForm),
    };
  }
}
