import {Component, OnInit, ViewChild} from '@angular/core';
import {BsModalRef, ModalDirective} from 'ngx-bootstrap/modal';
import {DatatableSettings} from '../../types/datatables';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {User, userRequestParams} from '../../types/user';
import {DataService} from '../../services/data.service';
import {AuthService} from '../../services/auth';
import * as moment from 'moment';
import {NotifierService} from 'angular-notifier';

@Component({
  selector: 'app-managers',
  templateUrl: './managers.component.html',
  styleUrls: ['./managers.component.scss'],
})
export class ManagersComponent implements OnInit {
  @ViewChild('createManagerModal') public createManagerModal: ModalDirective;
  @ViewChild('editManagerModal') public editManagerModal: ModalDirective;
  public isLoading = true;
  public dtOptions: DatatableSettings = {};
  public createManagerForm: FormGroup;
  public editManagerForm: FormGroup;
  public modalRef: BsModalRef;
  public managersPageData: User[] = [];
  public currentManager: string;
  public toggle = -1;
  public deactivateModal = false;

  constructor(
    private dataService: DataService,
    public authService: AuthService,
    private notifier: NotifierService,
  ) { }

  public ngOnInit() {
  this.toggle = 0;
    this.isLoading = true;
    this.initializeForm();
    this.getManagersList('Active').then();
    this.editManagerForm = new FormGroup(
      {
        name: new FormControl('', [
          Validators.required,
        ]),
        email: new FormControl('', [
          Validators.required,
        ]),
        phone: new FormControl('', [
          Validators.required,
        ]),
        gender: new FormControl('', [
          Validators.required,
        ]),
        target: new FormControl('', [
          Validators.required,
        ]),
      },
    );
  }
  public async getManagersList(txt : string){
    this.isLoading=true;
    let userParams ={};
    if(txt === 'Active'){
      userParams = {
        where: {
          isActive: true,
          userType: 'manager',
        },
      };
    }
    else{
      userParams = {
        where: {
          isActive: false,
          userType: 'manager',
        },
      };
    }

    const users = await this.dataService.getFromServer(
      'classes/_User',
      userParams,
    );
    this.managersPageData = users.results || [];
    this.dtOptions = {
      data: this.managersPageData,
      order: [],
      dom: '<"appRequestsFilter">lftipr',
      columnDefs: [
        {
          orderable: false,
          className: 'responsive-checkbox',
          targets: 0,
          responsivePriority: 1,
        },
        {
          targets: [1],
          visible: false,
        },
        {
          responsivePriority: 3,
          targets: 4,
        },
        {
          responsivePriority: 2,
          targets: 6,
        },
        {
          defaultContent: 'NA',
          targets: '_all',
        },
      ],
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search...',
        lengthMenu: 'Show _MENU_',
      },
      responsive: true,
      columns: [
        {data: ''},
        {
          data: (row: User) => {
            return moment(row.createdAt).valueOf();
          },
        },
        {
          data: (row: User) => {
            return row.name ? row.name : 'NA';
          },
        },
        {
          data: (row: User) => {
            return row.phoneNumber ? row.phoneNumber : 'NA';
          },
        },
        {
          data: (row: User) => {
            return row.username ? row.username : 'NA';
          },
        },
        {
          data: (row: User) => {
            return row.gender ? row.gender : 'NA';
          },
        },
        {
          data: (row: User) => {
            return row.target;
          },
        },
        {
          data: () => {
            return '<span class="fa fa-pencil"></span>&nbsp;&nbsp;&nbsp;&nbsp;<span class="fa fa-trash"></span>';
          },
        },
      ],
      rowCallback: (row: Node, data: object) => {
        row.removeEventListener('click', () => {
        });
        row.addEventListener('click', e => {
          if ((e.target as HTMLElement).classList.contains('fa-pencil')) {
            this.editManager((data as User).objectId);
          }else if ((e.target as HTMLElement).classList.contains('fa-trash')) {
            this.deleteManager((data as User).objectId);
          }
        });
        return row;
      },
    };
    this.isLoading=false;
  }

  private initializeForm() {
    this.createManagerForm = new FormGroup(
      {
        name: new FormControl('', [
          Validators.required,
        ]),
        email: new FormControl('', [
          Validators.required,
        ]),
        phone: new FormControl('', [
          Validators.required,
        ]),
        password: new FormControl('', [
          Validators.required,
        ]),
        gender: new FormControl('', [
          Validators.required,
        ]),
        target: new FormControl('', [
          Validators.required,
        ]),
      },
    );
  }
  public addManager() {
    this.createManagerModal.show();
  }
  public async showDeactivatedManagers(){
    this.getManagersList('deactive').then();
    this.toggle = 1;
  }
  public async showActiveManagers(){
    this.getManagersList('Active').then();
    this.toggle = 0;
  }
  public async createManager() {
    const name = this.createManagerForm.get('name')!.value;
    const email = this.createManagerForm.get('email')!.value;
    const password = this.createManagerForm.get('password')!.value;
    const phoneNumber = this.createManagerForm.get('phone')!.value;
    const gender = this.createManagerForm.get('gender')!.value;
    const target = this.createManagerForm.get('target')!.value;
    const userParams: userRequestParams = {
      name,
      email,
      username: email,
      password,
      phoneNumber:phoneNumber.toString(),
      gender,
      target,
      userType: 'manager',
      isActive :true,
    };
    const response = await this.dataService.postToServer('users', userParams);
    if(response){
      this.notifier.notify('success', 'Manager created Successfully!!!');
      this.createManagerModal.hide();
      this.getManagersList('Active').then();
    }
    else{
      this.notifier.notify('error', 'Error creating Manager.');
      this.createManagerModal.show();
    }
  }
  public async editManager(managerId : string){
    this.currentManager = managerId;
    this.editManagerModal.show();
    const userParams = {
      include: 'agent,assignTo',
    };
    const UserResponse = await this.dataService.getFromServer(
      'classes/_User/' + managerId,
      userParams,
    );
    this.editManagerForm.patchValue({
      name: UserResponse.name || '',
      email: UserResponse.username || '',
      phone: UserResponse.phoneNumber || '',
      gender: UserResponse.gender || 'male',
      target:  UserResponse.target || '',
    });
  }
  public async saveManager() {
    const name = this.editManagerForm.get('name')!.value;
    const email = this.editManagerForm.get('email')!.value;
    const phoneNumber = this.editManagerForm.get('phone')!.value;
    const gender = this.editManagerForm.get('gender')!.value;
    const target = this.editManagerForm.get('target')!.value;
    const userParams: userRequestParams = {
      name,
      email,
      username: email,
      phoneNumber:phoneNumber.toString(),
      gender,
      target,
      userType: 'manager',
      isActive :true,
    };
    const response = await this.dataService.postToServer('functions/updateUserDetails', userParams);
    if(response){
      this.notifier.notify('success', 'Manager edited Successfully!!!');
      this.editManagerModal.hide();
    }
    else{
      this.notifier.notify('error', 'Error editing Manager.');
      this.editManagerModal.show();
    }
    this.getManagersList('Active').then();
    this.isLoading = true;
  }

  public decline() {
    this.deactivateModal = false;
  }
  public async accept() {
    this.isLoading = true;
    const userParams: userRequestParams = {
      objectId : this.currentManager,
    };
    const response = await this.dataService.postToServer('functions/deactivateUser', userParams);
    if(response){
      this.notifier.notify('success', 'Manager De-activated Successfully!!!');
      this.getManagersList('Active').then();
      this.editManagerModal.hide();
    }
    else{
      this.notifier.notify('error', 'Error de-activating manager.');
      this.editManagerModal.show();
    }
    this.deactivateModal = false;
    this.isLoading = false;
  }

  public deleteManager(ManagerId : string) {
    this.currentManager = ManagerId;
    this.deactivateModal = true;
  }
}
