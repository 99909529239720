import { Component, Input, OnInit } from '@angular/core';
import { Fields } from '../../../../types/forms';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'field-builder',
  templateUrl: './field-builder.component.html',
})
export class FieldBuilderComponent implements OnInit {
  @Input() public field: Fields;
  @Input() public form: FormGroup;

  get isValid() {
    return this.form.controls[this.field.name].valid;
  }

  get isDirty() {
    return this.form.controls[this.field.name].dirty;
  }

  constructor() {}

  public ngOnInit() {}
}
