import * as moment from 'moment';
import {Tasks} from "../types/tasks";

export function getTasksData(tasks: Tasks[], type?: string) {
    if (!tasks.length) {
        return [];
    }

    return tasks
        .filter(item => item.nextActivity)
        .map(item => {
            const date = moment(item.nextActivity!.iso).format('YYYY-MM-DD');
            const start = moment(item.nextActivity!.iso).format('YYYY-MM-DDTHH:mm:ss');
            const title = type === 'dashboardCalender'
                ? item.stageOption
                : `${moment(item.nextActivity!.iso).format('HH:mma')} ${item.stageOption}`;
            const className = item.isComplete ? 'disabled-event' : '';

            return {
                id: item.objectId,
                title,
                date,
                start: type === 'dashboardCalender' ? start : undefined,
                className,
                extendedProps: item
            };
        });
}
