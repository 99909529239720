import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Notifications } from '../../types/notifications';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  @Input() public notifications: Notifications[];
  @Output() public updateNotificationStatus: EventEmitter<
    string
  > = new EventEmitter();

  constructor(private router: Router) {}

  public ngOnInit() {}

  public openNotification = (notification: Notifications) => {
    let route = '';    
    if (route) {
      this.updateNotificationStatus.emit(notification.objectId);
      this.router.navigateByUrl(route, { replaceUrl: true });
    }
  };
}
