import { Injectable } from '@angular/core';
import {PostResponse} from '../types/common';
import { DataService } from './data.service';
import { Tasks } from '../types/tasks';

@Injectable({
  providedIn: 'root',
})
export class TaskService {

  constructor(private dataService: DataService) { }

  public async addTask(obj: Tasks): Promise<PostResponse> {
    const response = await this.dataService.postToServer(
      'classes/Tasks',
      obj,
    );
    return response;
  }

  public async updateTask(obj: Tasks , id: string): Promise<PostResponse> {
    const response = await this.dataService.updateToServer(
      'classes/Tasks/' + id,
      obj,
    );
    return response;
  }
}
