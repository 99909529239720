import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth';
import { DataService } from '../../../services/data.service';
import * as moment from 'moment';

@Component({
  selector: 'app-user-signup-metrics-widget',
  templateUrl: './user-signup-metrics-widget.component.html',
  styleUrls: ['./user-signup-metrics-widget.component.scss']
})
export class UserSignupMetricsWidgetComponent implements OnInit {
  
  public chartData = {
    data: [] as any,
    title: "Monthly Signups",
    type: "LineChart",
    columnNames: ["Month", "Users"],
    options: {
      vAxis: {
        minValue: 0,
      },
      colors: ["#1B1464"],
      pointSize: 5,
      chartArea: { left: 0},
    },
    width: 500,
    height: 250,
  };

  constructor(
    public dataService: DataService,
    public authService: AuthService,
  ) { }

  ngOnInit() {
    this.getCustomerSignupMetrics('Month');
  }

  /**
 * @function : getCustomerSignupMetrics
 * @param {string} period : like week, month , year
 * @description : To Get Customer Signup Metrics
 */
  public async getCustomerSignupMetrics(period: string) {

    this.chartData.data = [];

    const params = {
      entityId: this.authService.getUser().entityId.objectId,
      period,
    }

    const getCustomerSignupMetricsResponse = await this.dataService.postToServer(
      "functions/getCustomerSignupMetrics",
      params
    );

    console.log('getCustomerSignupMetricsResponse',{ getCustomerSignupMetricsResponse });


    getCustomerSignupMetricsResponse && getCustomerSignupMetricsResponse.result && getCustomerSignupMetricsResponse.result.forEach((obj: any) => {
      this.chartData.data.push([moment(obj.objectId.date).format('MMM-DD'), obj.count])
    })

  }

}
