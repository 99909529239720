import { Component, OnInit} from '@angular/core';
import { UserService } from '../../services/user.service';
@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  constructor(
    public userService: UserService,
  ) {}
  public ngOnInit() {
  }
}

