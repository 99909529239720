import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { UserService } from './user.service';
import { Notifications } from '../types/notifications';

@Injectable()
export class NotificationService {
  constructor(
    private dataService: DataService,
    private userService: UserService,
  ) {}

  public async getNotifications(): Promise<Notifications[]> {
    const notificationsParams = {
      include: 'user',
      order: '-createdAt',
      where: {
        toUser: {
          __type: 'Pointer',
          className: '_User',
          objectId: this.userService.userId,
        },
      },
    };
    const response = await this.dataService.getFromServer(
      'classes/Messages',
      notificationsParams,
    );
    return response && response.results ? response.results : [];
  }

  public async updateNotificationStatus(objectId: string) {
    const notificationsParams = {
      status: 'read',
    };
    const response = await this.dataService.updateToServer(
      `classes/Messages/${objectId}`,
      notificationsParams,
    );
    return response;
  }
}
