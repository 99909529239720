import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Fields } from '../../../../types/forms';

@Component({
  selector: 'dropdown',
  template: `
    <div [formGroup]="form">
      <select
        class="form-control"
        [ngClass]="formField.class"
        [class]="formField.borderClass"
        [id]="formField.name"
        [formControlName]="formField.name"
        (change)="onChange($event)"
      >
        <option *ngFor="let opt of formField.options" [value]="opt.key">{{
          opt.label
        }}</option>
      </select>
      <label
        class="form-control-label"
        [ngClass]="formField.labelClass"
        [attr.for]="formField.label"
        >{{ formField.label }}
        <strong class="text-danger" *ngIf="formField.required">*</strong>
      </label>
    </div>
  `,
})
export class DropDownComponent {
  @Input() public formField: Fields;
  @Input() public form: FormGroup;
  public onChange(event: Event) {
    if (this.formField.onChange) {
      this.formField.onChange(event);
    }
  }
}
