import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { UserService } from './user.service';
import { AuthService } from "./auth";
import * as moment from "moment";
import { getDatesRange } from "../helpers/helper.date";
import { Observable, from, of, forkJoin, combineLatest } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

interface DashboardDataOptions {
    callDailyUsers?: boolean;
    callGetContacts?: boolean;
    callFetchLeadsList?: boolean;
    callGetEngagedUsersMetrics?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class DashboardService {

    constructor(
        private dataService: DataService,
        public authService: AuthService,
        private userService: UserService
    ) {}

    public dailyUsers(selectedRange: string): Observable<any> {
        try {
            const date = getDatesRange(selectedRange);
            const startDate = moment(date.startDate).toISOString();
            const endDate = moment(date.endDate).toISOString();
            let params = {
                entityId: this.userService.getUser()!.entityId!.objectId,
                createdAt: {
                    $gte: { __type: 'Date', iso: startDate },
                    $lte: { __type: 'Date', iso: endDate },
                },
            };

            return from(this.dataService.postToServer("functions/getEventContacts", params)).pipe(
                map(response => {
                    let responseObj = JSON.parse(response.result);
                    //current month churn rate
                    const churnRate = responseObj.currentChurn ? parseInt(responseObj.currentChurn) : 0;

                    //current month retention rate
                    let currentMonthRetentionRate = 1 - (churnRate / 100);
                    let retentionRate = (currentMonthRetentionRate * 100).toFixed(0);
                    return {
                        dailyActiveUsers: responseObj.dau ? responseObj.dau : 0,
                        churnRate,
                        retentionRate,
                        prevDau: responseObj.prevDau,
                        previousChurn: responseObj.previousChurn,
                    };
                }),
                catchError(error => {
                    console.error('Error fetching daily users count:', error);
                    return of({
                        dailyActiveUsers: 0,
                        churnRate: 0,
                        retentionRate: 0,
                        prevDau: 0,
                        previousChurn: 0,
                    });
                })
            );
        } catch (_) {
            return of({
                dailyActiveUsers: 0,
                churnRate: 0,
                retentionRate: 0,
                prevDau: 0,
                previousChurn: 0,
            });
        }
    }

    public getContacts(selectedRange: string): Observable<any> {
        const date = getDatesRange(selectedRange);
        const startDate = moment(date.startDate).toISOString();
        const endDate = moment(date.endDate).toISOString();

        let customerParams: any;

        if (this.authService.isSuperAdmin()) {
            customerParams = {
                limit: 10000,
                where: {
                    createdAt: { $gte: { __type: 'Date', iso: startDate }, $lte: { __type: 'Date', iso: endDate } },
                },
            };
        } else if (this.authService.isAdmin()) {
            customerParams = {
                limit: 10000,
                where: {
                    createdAt: { $gte: { __type: 'Date', iso: startDate }, $lte: { __type: 'Date', iso: endDate } },
                    email: { "$ne": 'anonymous@inleads.ai' },
                    $or: [
                        {
                            entity: {
                                __type: "Pointer",
                                className: "Entity",
                                objectId: this.authService.getUser().entityId.objectId,
                            },
                        },
                    ],
                },
            };
        } else if (this.authService.isAgent()) {
            customerParams = {
                limit: 10000,
                where: {
                    createdAt: { $gte: { __type: 'Date', iso: startDate }, $lte: { __type: 'Date', iso: endDate } },
                    email: { "$ne": 'anonymous@inleads.ai' },
                    $or: [
                        {
                            agent: {
                                __type: "Pointer",
                                className: "_User",
                                objectId: this.authService.getUser().objectId,
                            },
                        },
                        {
                            assignTo: {
                                __type: "Pointer",
                                className: "_User",
                                objectId: this.authService.getUser().objectId,
                            },
                        },
                    ],
                },
            };
        } else if (this.authService.isManager()) {
            customerParams = {
                limit: 10000,
                where: {
                    createdAt: { $gte: { __type: 'Date', iso: startDate }, $lte: { __type: 'Date', iso: endDate } },
                    email: { "$ne": 'anonymous@inleads.ai' },
                    $or: [
                        {
                            agent: {
                                $inQuery: {
                                    where: {
                                        createdAt: { $gte: { __type: 'Date', iso: startDate }, $lte: { __type: 'Date', iso: endDate } },
                                        entityId: {
                                            __type: "Pointer",
                                            className: "Entity",
                                            objectId: this.authService.getUser().entityId.objectId,
                                        },
                                    },
                                    className: "_User",
                                },
                            },
                        },
                        {
                            assignTo: {
                                $inQuery: {
                                    where: {
                                        entityId: {
                                            __type: "Pointer",
                                            className: "Entity",
                                            objectId: this.authService.getUser().entityId.objectId,
                                        },
                                    },
                                    className: "_User",
                                },
                            },
                        },
                        {
                            agent: {
                                __type: "Pointer",
                                className: "_User",
                                objectId: this.authService.getUser().objectId,
                            },
                        },
                        {
                            assignTo: {
                                __type: "Pointer",
                                className: "_User",
                                objectId: this.authService.getUser().objectId,
                            },
                        },
                    ],
                },
            };
        }
        customerParams.where.isActive = true;

        return customerParams ?
            from(this.dataService.getFromServer("classes/Contacts", customerParams)).pipe(
                map(response => ({ totalContacts: response && response.results ? response.results.length : 0 })),
                catchError(error => {
                    console.error('Error fetching contacts:', error);
                    return of({ totalContacts: 0 });
                })
            ) :
            of({ totalContacts: 0 });
    }

    public fetchLeadsList(selectedRange: string): Observable<any> {
        const date = getDatesRange(selectedRange);
        const startDate = moment(date.startDate).toISOString();
        const endDate = moment(date.endDate).toISOString();

        const getCurrentUser = this.authService.getUser();
        let params: any;
        if (this.authService.isSuperAdmin()) {
            params = {
                include: "status,stage,contact",
                where: {
                    isArchived: { $ne: true },
                    $or: [
                        { createdAt: {$gte: {__type: 'Date', iso: startDate}, $lte: {__type: 'Date', iso: endDate}}},
                        { updatedAt: {$gte: {__type: 'Date', iso: startDate}, $lte: {__type: 'Date', iso: endDate}}},
                    ],
                },
            };
        } else if (this.authService.isAdmin()) {
            params = {
                // include: "stage,contact",
                include: "status,stage,contact",
                order: "-dealValue",
                where: {
                    entity: {
                        __type: "Pointer",
                        className: "Entity",
                        objectId: this.authService.getUser().entityId.objectId,
                    },
                    // status: {
                    //     $ne: {
                    //         __type: "Pointer",
                    //         className: "Status",
                    //         objectId: "tt4nl8dJg6",
                    //     },
                    // },
                    isArchived: { $ne: true },
                    $or: [
                        { createdAt: {$gte: {__type: 'Date', iso: startDate}, $lte: {__type: 'Date', iso: endDate}}},
                        { updatedAt: {$gte: {__type: 'Date', iso: startDate}, $lte: {__type: 'Date', iso: endDate}}},
                    ],
                },
            };
        } else if (this.authService.isAgent()) {
            params = {
                include: "status,stage,contact",
                where: {
                    entity: {
                        __type: "Pointer",
                        className: "Entity",
                        objectId: this.authService.getUser().entityId.objectId,
                    },
                    // status: {
                    //     $ne: {
                    //         __type: "Pointer",
                    //         className: "Status",
                    //         objectId: "tt4nl8dJg6",
                    //     },
                    // },
                    isArchived: { $ne: true },
                    $or: [
                        { createdAt: {$gte: {__type: 'Date', iso: startDate}, $lte: {__type: 'Date', iso: endDate}}},
                        { updatedAt: {$gte: {__type: 'Date', iso: startDate}, $lte: {__type: 'Date', iso: endDate}}},
                    ],
                },
            };
            if(!!getCurrentUser.isPrivate) {
                params = {
                    where: {
                        ...params.where,
                        $or: [
                            {
                                agent: {
                                    __type: 'Pointer',
                                    className: '_User',
                                    objectId: this.authService.getUser().objectId,
                                },
                            },
                            {
                                assignTo: {
                                    __type: 'Pointer',
                                    className: '_User',
                                    objectId: this.authService.getUser().objectId,
                                },
                            },
                        ],
                    },
                }
            }
        } else if (this.authService.isManager()) {
            params = {
                include: "status,stage,contact",
                where: {
                    $or: [
                        {
                            agent: {
                                $inQuery: {
                                    where: {
                                        manager: {
                                            __type: "Pointer",
                                            className: "_User",
                                            objectId: this.authService.getUser().objectId,
                                        },
                                    },
                                    className: "_User",
                                },
                            },
                        },
                        {
                            assignTo: {
                                $inQuery: {
                                    where: {
                                        manager: {
                                            __type: "Pointer",
                                            className: "_User",
                                            objectId: this.authService.getUser().objectId,
                                        },
                                    },
                                    className: "_User",
                                },
                            },
                        },
                        {
                            agent: {
                                __type: "Pointer",
                                className: "_User",
                                objectId: this.authService.getUser().objectId,
                            },
                        },
                        {
                            assignTo: {
                                __type: "Pointer",
                                className: "_User",
                                objectId: this.authService.getUser().objectId,
                            },
                        },
                        { createdAt: {$gte: {__type: 'Date', iso: startDate}, $lte: {__type: 'Date', iso: endDate}}},
                        { updatedAt: {$gte: {__type: 'Date', iso: startDate}, $lte: {__type: 'Date', iso: endDate}}},
                    ],
                    // status: {
                    //     $ne: {
                    //         __type: "Pointer",
                    //         className: "Status",
                    //         objectId: "tt4nl8dJg6",
                    //     },
                    // },
                    isArchived: { $ne: true },
                    // createdAt: { $gte: { __type: 'Date', iso: startDate }, $lte: { __type: 'Date', iso: endDate } }
                },
            };
        }

        params.limit = 10000;

        // Fetch spaces to get the selectedSpace, without createdAt filter

        const spaceParams = {
            where: {
                entity: {
                    __type: "Pointer",
                    className: "Entity",
                    objectId: this.authService.getUser().entityId.objectId,
                },
                isArchived: { $ne: true },
            },
        };

        return from(this.dataService.getFromServer("classes/Spaces", spaceParams)).pipe(
            switchMap(spaceResp => {
                // Extracting the first result if it exists
                const spaceList = spaceResp.results && spaceResp.results.length > 0 ? [spaceResp.results[0]] : [];
                const selectedSpace = spaceList[0];

                if (selectedSpace) {
                    params.where.space = {
                        __type: "Pointer",
                        className: "Spaces",
                        objectId: selectedSpace.objectId,
                    };
                }

                // Fetching leads list based on updated params
                return from(this.dataService.getFromServer("classes/Leads", params)).pipe(
                    switchMap((leadsResp) => {
                        const totalLeads = leadsResp.results.length || 0;
                        const allLeads = leadsResp.results || [];
                        if (totalLeads > 0) {
                            return combineLatest([
                                of(spaceList),
                                of(totalLeads),
                                of(allLeads),
                                DashboardService.calculateDealValue(leadsResp.results)
                            ]).pipe(
                                map(([spaceList, totalLeads, allLeads, totalDeal]) => ({
                                    spaceList,
                                    totalLeads,
                                    expectedRevenue: totalDeal || 0,
                                    allLeadsData: allLeads,
                                }))
                            );
                        } else {
                            // Returning default values if no leads are found
                            return of({
                                spaceList,
                                totalLeads: 0,
                                expectedRevenue: 0,
                                allLeadsData: [],
                            });
                        }
                    }),
                    catchError(error => {
                        console.error('Error fetching leads list:', error);
                        return of({
                            spaceList: [],
                            totalLeads: 0,
                            expectedRevenue: 0,
                            allLeadsData: [],
                        });
                    })
                );
            }),
            catchError(error => {
                console.error('Error fetching space list:', error);
                return of({
                    spaceList: [],
                    totalLeads: 0,
                    expectedRevenue: 0,
                    allLeadsData: [],
                });
            })
        );
    }

    private static calculateDealValue(leads: any[]): Observable<number> {
        const totalDeal = leads.reduce((sum, lead) => sum + (lead.dealValue || 0), 0);
        return of(totalDeal);
    }

    public async getEngagedUsersMetrics(selectedRange: string): Promise<any> {
        try {
            const date = getDatesRange(selectedRange);
            const params = {
                entityId: this.authService.getUser().entityId.objectId,
                startDate: date.startDate,
                endDate: date.endDate,
            };

            const [engagedUsersMetricsResponse, customerSignupMetricsResponse, leadMetricsResponse] = await Promise.all([
                this.dataService.postToServer("functions/getEngagedUsersMetrics", params),
                this.dataService.postToServer("functions/getCustomerEventSignupMetrics", params),
                this.dataService.postToServer("functions/getAllLeadsMetrics", params),
            ]);

            const chartData: Array<[string, number, number, number]> = [];

            const processMetrics = (metrics: any[], typeIndex: number) => {
                metrics.forEach((obj: any) => {
                    const formattedDate = moment(obj.objectId.date || obj.objectId).format("MMM-DD");

                    let entry = chartData.find(item => item[0] === formattedDate);
                    if (entry) {
                        entry[typeIndex] += obj.count || obj.amount || obj.value || 0;
                    } else {
                        entry = [formattedDate, 0, 0, 0];
                        entry[typeIndex] = obj.count || obj.amount || obj.value || 0;
                        chartData.push(entry);
                    }
                });
            };

            if (engagedUsersMetricsResponse.result) {
                processMetrics(engagedUsersMetricsResponse.result, 1);
            }
            if (customerSignupMetricsResponse.result) {
                processMetrics(customerSignupMetricsResponse.result, 2);
            }
            if (leadMetricsResponse.result) {
                processMetrics(leadMetricsResponse.result, 3);
            }

            chartData.sort((a, b) => moment(a[0], "MMM-DD").diff(moment(b[0], "MMM-DD")));

            return {
                graphDate: chartData
            };
        } catch (error) {
            console.error('Error fetching engaged users metrics:', error);
            return {
                graphDate: []
            };
        }
    }

    public getDashboardData(selectedRange: string, options: DashboardDataOptions = {}): Observable<any> {
        const {
            callDailyUsers = false,
            callGetContacts = false,
            callFetchLeadsList = false,
            callGetEngagedUsersMetrics = false
        } = options;

        return forkJoin([
            callDailyUsers ? this.dailyUsers(selectedRange) : of({ dailyActiveUsers: 0, churnRate: 0, retentionRate: '0', prevDau: 0, previousChurn: 0 }),
            callGetContacts ? this.getContacts(selectedRange) : of({ totalContacts: 0 }),
            callFetchLeadsList ? this.fetchLeadsList(selectedRange) : of({ totalLeads: 0, expectedRevenue: 0, spaceList: [], allLeadsData: [] }),
            callGetEngagedUsersMetrics ? this.getEngagedUsersMetrics('lastThirtyDays') : of({ graphDate: [] })
        ]).pipe(
            map(([dailyUsers, totalContacts, totalLeads, engagedUsersMetrics]) => {
                return {
                    dailyActiveUsers: dailyUsers.dailyActiveUsers || 0,
                    prevDau: dailyUsers.prevDau || 0,
                    previousChurn: dailyUsers.previousChurn || 0,
                    churnRate: dailyUsers.churnRate || 0,
                    retentionRate: parseInt(dailyUsers.retentionRate, 10) || 0,
                    totalContacts: totalContacts.totalContacts || 0,
                    totalLeads: totalLeads.totalLeads || 0,
                    expectedRevenue: totalLeads.expectedRevenue || 0,
                    graphDate: engagedUsersMetrics.graphDate || [],
                    spaceList: totalLeads.spaceList || [],
                    allLeads: totalLeads.allLeadsData || [],
                };
            }),
            catchError(error => {
                console.error('Error fetching company overview:', error);
                return of({
                    dailyActiveUsers: 0,
                    prevDau: 0,
                    previousChurn: 0,
                    churnRate: 0,
                    retentionRate: 0,
                    totalContacts: 0,
                    totalLeads: 0,
                    expectedRevenue: 0,
                    graphDate: [],
                    spaceList: [],
                    allLeads: [],
                });
            })
        );
    }


}
