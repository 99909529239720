import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Fields} from '../../../../../types/forms';

@Component({
    selector: 'file',
    templateUrl: './file.html',
    styleUrls: ['./file.scss'],
})
export class FileComponent {
    @Input() public formField: Fields;
    @Input() public form: FormGroup;
    public isHovering: boolean;

    get isValid() {
        return this.form.controls[this.formField.name].valid;
    }

    get isDirty() {
        return this.form.controls[this.formField.name].dirty;
    }

    constructor() {
    }

    public ngOnChange() {
    }

    public toggleHover(_event: Event) {
    }
}
