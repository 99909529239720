import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './auth';

@Injectable()
export class NoAuthenticationService implements CanActivate {
  constructor(private auth: AuthService, private route: Router) {}

  public canActivate(): boolean {
    if (!this.auth.isSignedIn()) {
      return true;
    }
    this.route.navigate(['dashboard']);
    return false;
  }
}
