import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-widget-content',
  templateUrl: './dashboard-widget-content.component.html',
  styleUrls: ['./dashboard-widget-content.component.scss']
})
export class DashboardWidgetContentComponent implements OnInit {

  @Input() widgetType: string;
  @Input() dashboardEngagementMetrics: any[];

  constructor() { }

  ngOnInit() { }




}
