import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-widget',
  templateUrl: './dashboard-widget.component.html',
  styleUrls: ['./dashboard-widget.component.scss']
})
export class DashboardWidgetComponent implements OnInit {

  @Input() widgetType: string;
  @Input() dashboardEngagementMetrics: any[];

  constructor() { }

  ngOnInit() { }

}
