import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Fields } from '../../../../types/forms';

@Component({
  selector: 'number',
  template: `
    <div [formGroup]="form">
      <input
        [formControlName]="formField.name"
        class="form-control"
        [ngClass]="formField.class"
        [class]="formField.borderClass"
        type="number"
        [id]="formField.name"
        [name]="formField.name"
        autocomplete="off"
        required
      />
      <label
        class="form-control-label"
        [ngClass]="formField.labelClass"
        [attr.for]="formField.label"
      >
        {{ formField.label }}
        <strong class="text-danger" *ngIf="formField.required">*</strong>
      </label>
    </div>
  `,
})
export class NumberComponent {
  @Input() public formField: Fields;
  @Input() public form: FormGroup;
}
