import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Fields} from '../../../../types/forms';

@Component({
    selector: 'checkbox',
    template: `
        <div [formGroup]="form">
            <div [formGroupName]="formField.name">
                <div *ngFor="let opt of formField.options" class="form-check form-check">
                    <label class="form-check-label"><input [formControlName]="opt.key" class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                        {{ opt.label }}
                    </label>
                </div>
            </div>
        </div>
    `,
})
export class CheckBoxComponent {
    @Input() public formField: Fields;
    @Input() public form: FormGroup;
}
