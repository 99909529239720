import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class HelperService {
  private paramsList: object = {};
  private headers: Headers = {};
  private liveQueryDataSpaces: Subject<any>;
  private liveQueryFollowUpsData: Subject<any>;
  private liveQueryLeadsData: Subject<any>;
  private liveQueryNotificationsData: Subject<any>;
  private spacesData: Subject<any>;
  private stagesData: Subject<any>;

  constructor() {
    this.liveQueryDataSpaces = new Subject<any>();
    this.liveQueryFollowUpsData = new Subject<any>();
    this.spacesData = new BehaviorSubject<any>([]);
    this.stagesData = new BehaviorSubject<any>([]);
    this.liveQueryLeadsData = new Subject<any>();
    this.liveQueryNotificationsData = new Subject<any>();
  }

  public setParams(params: object) {
    this.paramsList = params;
  }

  public getParams() {
    return this.paramsList;
  }

  public setHeaders(key: string, value: string) {
    this.headers[key] = value;
  }

  public cleanHeaders() {
    this.headers = {};
  }

  public getHeaders() {
    return this.headers;
  }

  public setLiveQueryDataSpaces(_data: any) {
    this.liveQueryDataSpaces.next(_data);
  }

  public getLiveQueryDataSpaces() {
    return this.liveQueryDataSpaces.asObservable();
  }

  public setSpacesData(_data: any) {
    this.spacesData.next(_data);
  }

  public getSpacesData() {
    return this.spacesData.asObservable();
  }

  public setStagesData(_data: any) {
    this.stagesData.next(_data);
  }

  public getStagesData() {
    return this.stagesData.asObservable();
  }

  public setLiveQueryFollowUps(_data: any) {
   this.liveQueryFollowUpsData.next(_data);
  }

  public getLiveQueryFollowUps() {
    return this.liveQueryFollowUpsData.asObservable();
  }

  public setLiveQueryLeadsData(_data: any) {
    this.liveQueryLeadsData.next(_data);
  }

  public getLiveQueryLeadsData() {
    return this.liveQueryLeadsData.asObservable();
  }

  public setLiveQueryNotificationsData(_data: any) {
    this.liveQueryNotificationsData.next(_data);
  }

  public getLiveQueryNotificationsData() {
    return this.liveQueryNotificationsData.asObservable();
  }
}

type Headers = {
  [key: string]: string;
};
