import {Component, OnInit, ViewChild} from '@angular/core';
import {DatatableSettings} from '../../types/datatables';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BsModalRef, ModalDirective} from 'ngx-bootstrap/modal';
import {DataService} from '../../services/data.service';
import {AuthService} from '../../services/auth';
import {User, userRequestParams} from '../../types/user';
import * as moment from 'moment';
import {NotifierService} from 'angular-notifier';
import { UserService } from 'src/app/services/user.service';
import {validationPattern} from '../../constants/regexp';
import { track } from '@inleads/event-logger';


@Component({
  selector: 'app-agents',
  templateUrl: './agents.component.html',
  styleUrls: ['./agents.component.scss'],
})
export class AgentsComponent implements OnInit {
  @ViewChild('createAgentModal') public createAgentModal: ModalDirective;
  @ViewChild('editAgentModal') public editAgentModal: ModalDirective;
  @ViewChild('changeRoleModal') public changeRoleModal: ModalDirective;
  public isLoading = true;
  public dtOptions: DatatableSettings = {};
  public createAgentForm: FormGroup;
  public editAgentForm: FormGroup;
  public changeRoleForm: FormGroup;
  public modalRef: BsModalRef;
  public agentsPageData: User[] = [];
  public currentAgent: string;
  public managerID: string;
  public toggle = -1;
  public deactivateModal = false;
  public isManager: boolean;
  public isAdmin: boolean;
  public disableFields: boolean;
  profilePhotoLoader: boolean;
  imgURL: any;
  public showOrHideIsPrivateOption: boolean = false;


  constructor(
    private dataService: DataService,
    public authService: AuthService,
    private notifier: NotifierService,
    public userService: UserService,
  ) {
    this.isManager = this.authService.isManager();
    this.isAdmin = this.authService.isAdmin();
  }

  public ngOnInit() {
    this.toggle = 0;
    this.isLoading = true;
    this.initializeForm();
    this.getAgentsList('Active').then();
    track("TEAMS");
    this.changeRoleForm = new FormGroup(
      {
        changeFormRole: new FormControl('', [
          Validators.required,
        ]),
      },
      );
    this.editAgentForm = new FormGroup(
      {
        edit_agent_name: new FormControl('', [
          Validators.required,
        ]),
        edit_agent_email: new FormControl('', [
          Validators.required,
        ]),
        newPassword: new FormControl("", [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~`!@$%^&*()_+-=|{[\]}:;"'?/>.<,])(?=.{8,})/
          )
        ]),
        reEnterPassword: new FormControl("", [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~`!@$%^&*()_+-=|{[\]}:;"'?/>.<,])(?=.{8,})/
          )
        ]),
        edit_agent_phone: new FormControl('', []),
        gender: new FormControl('', [
          Validators.required,
        ]),
        edit_agent_target: new FormControl('', [
          Validators.required,
        ]),
        edit_agent_role: new FormControl('', [
          Validators.required,
        ]),
        edit_agent_agentCommission: new FormControl('', [
          Validators.required,
        ]),
      },
    );
  }

  public async getAgentsList(txt : string){
    this.isLoading=true;
    const userParams = {
          isActive: txt === 'Active' ? true : false,
          entityId: this.authService.getUser().entityId.objectId,
          userType: this.authService.getUser().userType,
      };

    const users = await this.dataService.postToServer(
      'functions/get-team-members',
    userParams,
    );

    this.agentsPageData = users && users.result || [];
    this.isLoading=false;
    this.dtOptions = {
      order: [[1, 'desc'], [3, 'desc']],
      dom: '<"appRequestsFilter" lf>tipr',
      columnDefs: [
        {
          orderable: false,
          targets: 0,
          responsivePriority: 1,
          width: '25px',
        },
        {
          orderable: false,
          className: 'select-checkbox',
          targets: 1,
          width: '25px',
          responsivePriority: 1,
          visible: false,
        },
        {
          responsivePriority: 1,
          targets: 8,
        },
        {
          targets: [2],
          visible: false,
        },
        {
          responsivePriority: 2,
          targets: 4,
        },
        {
          responsivePriority: 3,
          targets: 5,
        },
        {
          responsivePriority: 1,
          targets: 3,
        },
        {
          defaultContent: 'NA',
          targets: '_all',
        },
      ],
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search...',
        lengthMenu: '<span>Show</span> _MENU_',
      },
      responsive: true,
      data: this.agentsPageData,
      columns: [
        {data: ''},
        {data: ''},
        {
          data: (row: User) => {
            return moment(row.createdAt).valueOf();
          },
        },
        {
          data: (row: User) => {
            return row.name ? row.name : 'NA';
          },
        },
        {
          data: (row: User) => {
            return row.phoneNumber ? row.phoneNumber : 'NA';
          },
        },
        {
          data: (row: User) => {
            return row.username ? row.username : 'NA';
          },
        },
        {
          data: (row: User) => {
            return row.userType ? row.userType : 'NA';
          },
        },
        {
          data: (row: User) => {
            return row.target;
          },
        },
        {
          data: () => {
            let res;
            // if(this.isManager) {
              res = '<span class="fa fa-pencil cpointer"></span>';
              if(this.agentsPageData.length > 1){
                res = res+ '&nbsp;&nbsp;&nbsp;&nbsp;<span class="fa fa-trash cpointer"></span>';
              }
            // } else {
            //   res = '<span class="fa fa-pencil cpointer"></span>&nbsp;&nbsp;&nbsp;&nbsp;<span class="fa fa-trash cpointer"></span>&nbsp;&nbsp;&nbsp;&nbsp;<span class="fa fa-user cpointer"></span>';
            // }
            return res;
          },
        },
      ],
      rowCallback: (row: Node, data: object) => {
        row.removeEventListener('click', () => {
        });
        row.addEventListener('click', e => {
          if ((e.target as HTMLElement).classList.contains('fa-pencil')) {
            this.editAgent((data as User).objectId);
          }else if ((e.target as HTMLElement).classList.contains('fa-trash')) {
            this.deleteAgent((data as User).objectId);
          }
          // }else if ((e.target as HTMLElement).classList.contains('fa-user')) {
          //   this.changeRole((data as User).objectId);
          // }
        });
        return row;
      },
    };
  }

  private initializeForm() {
    this.createAgentForm = new FormGroup(
      {
        name: new FormControl('', [
          Validators.required,
        ]),
        email: new FormControl('', [
          Validators.required,
          Validators.pattern(validationPattern.email),
        ]),
        phone: new FormControl('', []),
        password: new FormControl('', [
          Validators.required,
          Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~`!@$%^&*()_+-=|{[\]}:;"'?/>.<,])(?=.{8,})/),
        ]),
        gender: new FormControl('', [
          Validators.required,
        ]),
        target: new FormControl('', [
          Validators.required,
        ]),
        agentFormRole: new FormControl('Select', [
          Validators.required,
        ]),
        agentCommission: new FormControl('0', [
          Validators.required,
        ]),
        markeMemberPrivate: new FormControl('', []),
      },
    );
  }
  public addAgent() {
    this.createAgentForm.get('agentFormRole')!.setValue('Select');
    this.showOrHideIsPrivateOption = false;
    this.createAgentForm.get('markeMemberPrivate')!.reset();
    this.createAgentModal.show();
  }
  public async showDeactivatedAgents(){
    this.getAgentsList('deactive').then();
    this.toggle = 1;
  }
  public async showActiveAgents(){
    this.getAgentsList('Active').then();
    this.toggle = 0;
  }
  public async createAgent() {
    try{
      const name = this.createAgentForm.get('name')!.value;
      const email = this.createAgentForm.get('email')!.value;
      const password = this.createAgentForm.get('password')!.value;
      const phoneNumber = this.createAgentForm.get('phone')!.value;
      const agentCommission = this.createAgentForm.get('agentCommission')!.value || 0;
      const markeMemberPrivate = this.createAgentForm.get('markeMemberPrivate')!.value;
      // const gender = this.createAgentForm.get('gender')!.value;
      const target = this.createAgentForm.get('target')!.value;
      if(!name){
        this.notifier.notify('error', 'Name cannot be empty!');
        return;
      }
      if(!email){
        this.notifier.notify('error', 'Email cannot be empty!');
        return;
      }
      if(!password){
        this.notifier.notify('error', 'Password cannot be empty!');
        return;
      }
      // if(!phoneNumber){
      //   this.notifier.notify('error', 'Phone Number cannot be empty!');
      //   return;
      // }
      if(!target){
        this.notifier.notify('error', 'Please Enter Target Amount');
        return;
      }

      /* if(!this.createAgentForm.get('agentFormRole')!.value){
        this.notifier.notify('error', 'Please Select Role');
        return;
      } */

      let agentFormRole;
       if(this.createAgentForm.get('agentFormRole')!.value === 'Select'){
        this.notifier.notify('error', 'Please Select Role');
        return;
      } else {
        agentFormRole = this.createAgentForm.get('agentFormRole')!.value;
      }


      const userParams: userRequestParams = {
        name,
        email,
        username: email,
        password,
        phoneNumber,
        target,
        userType: agentFormRole,
        isActive :true,
        manager : {
          __type: 'Pointer',
          className: '_User',
          objectId: this.authService.getUser().objectId,
        },
        entityId : {
          __type: 'Pointer',
          className: 'Entity',
          objectId: this.authService.getUser().entityId.objectId || '',
        },
        agentCommission : Number(agentCommission),
      };
      if(this.imgURL && this.imgURL.name && this.imgURL.url){
        userParams.profilePhoto = {
          name: this.imgURL && this.imgURL.name,
          url: this.imgURL.url,
          __type: 'File',
        }
      }
      if(!!markeMemberPrivate && this.showOrHideIsPrivateOption){
        userParams.isPrivate = true;
      }

      const response = await this.dataService.postToServer('users', userParams);
      if(response){
        this.createAgentForm.patchValue({
          name: '',
          email: '',
          phone: '',
          address: '',
          target:  '',
          userType: '',
          agentCommission: '',
          markeMemberPrivate: '',
        });
        this.notifier.notify('success', agentFormRole + ' '+'created successfully!!!');
        this.createAgentForm.reset();
        this.createAgentModal.hide();
        this.getAgentsList('Active').then();
      }
      else{
        this.notifier.notify('error', 'Error creating agent.');
        this.createAgentModal.show();
      }
      this.createAgentForm.patchValue({
        name: '',
        email: '',
        phone: '',
        address: '',
        target:  '',
        userType: '',
        agentCommission: '',
      });
    }
    catch(error){
      this.notifier.notify('error', error.error.error);
    }
  }

  public async editAgent(agentId: string) {
    this.currentAgent = agentId;
    this.editAgentModal.show();
    try {
      const userParams = {
          userId: agentId,
          entityId : this.authService.getUser().entityId.objectId,
          userType: this.authService.getUser().userType,
          isActive: this.authService.getUser().isActive,
      };
      const resp = await this.dataService.postToServer(
        'functions/get-team-user-details',
        userParams,
      );

      const UserResponse =  resp.result && resp.result[0]; //uC2EcSMgkh
      this.managerID = UserResponse.objectId || '';

      this.imgURL = UserResponse.profilePhoto || '';

      this.editAgentForm.patchValue({
        edit_agent_name: UserResponse.name || '',
        edit_agent_email: UserResponse.username || '',
        edit_agent_phone: UserResponse.phoneNumber || '',
        gender: UserResponse.gender || 'male',
        edit_agent_role: UserResponse.userType || '',
        edit_agent_target: UserResponse.target || '',
        edit_agent_agentCommission: Number(UserResponse.agentCommission || 0),
      });
    } catch (error) {
      console.error('Error fetching user data:', error); // Debugging line
    }
  }

    public async saveAgent(){
      const name = this.editAgentForm.get('edit_agent_name')!.value;
      const email = this.editAgentForm.get('edit_agent_email')!.value;
      const newPassword = this.editAgentForm.get('newPassword')!.value;
      const reEnterPassword = this.editAgentForm.get('reEnterPassword')!.value;
      const phoneNumber = this.editAgentForm.get('edit_agent_phone')!.value;
      // const gender = this.editAgentForm.get('gender')!.value;
      const target = this.editAgentForm.get('edit_agent_target')!.value;
      const role = this.editAgentForm.get('edit_agent_role')!.value;
      const agentCommission = this.editAgentForm.get('edit_agent_agentCommission')!.value;
      if(!name){
        this.notifier.notify('error', 'Name cannot be empty!');
        return;
      }
      if(!role){
        this.notifier.notify('error', 'Role cannot be empty!');
        return;
      }
      if(!email){
        this.notifier.notify('error', 'Email cannot be empty!');
        return;
      }
    if (newPassword !== reEnterPassword) {
      this.notifier.notify("error", "Password does not match");
      return;
    }
      // if(!phoneNumber){
      //   this.notifier.notify('error', 'Phone Number cannot be empty!');
      //   return;
      // }
      if(!target){
        this.notifier.notify('error', 'Please Enter Target Amount');
        return;
      }
      const userParams: userRequestParams = {
      name,
      email,
      agentCommission : Number(agentCommission),
      username: email,
      phoneNumber : Number(phoneNumber),
      target,
      userType: role,
      isActive :true,
      manager : {
      __type: 'Pointer',
      className: '_User',
      objectId: this.managerID,
      },
      entityId : {
        __type: 'Pointer',
        className: 'Entity',
        objectId: this.authService.getUser().entityId.objectId || '',
      },
      };
      if(this.imgURL && this.imgURL.name && this.imgURL.url){
        userParams.profilePhoto = {
          name: this.imgURL && this.imgURL.name,
          url: this.imgURL.url,
          __type: 'File',
        }
      }
      if (newPassword && reEnterPassword) {
        await this.dataService.postToServer('functions/updateTeamMemberPassword', { password: newPassword, objectId: this.currentAgent });
      }
      const response = await this.dataService.postToServer('functions/updateUserDetails', userParams);
      if(response){
      this.notifier.notify('success', 'Member Edited Successfully!!!');
      this.editAgentModal.hide();
      }
      else{
      this.notifier.notify('error', 'Error editing agent.');
      this.editAgentModal.show();
      }
      this.getAgentsList('Active').then();
      this.isLoading = true;
    }

    public decline() {
      this.deactivateModal = false;
    }
    public async deactivateUser() {
      if(this.toggle === 0) {
        const userParams: userRequestParams = {
          objectId : this.currentAgent,
          isActive : false,
        };
        const response = await this.dataService.postToServer('functions/updateUser', userParams);
        if(response){
          this.notifier.notify('success', 'Agent De-activated Successfully!!!');
          this.getAgentsList('Active').then();
          this.editAgentModal.hide();
        }
        else{
          this.notifier.notify('error', 'Error de-activating agent.');
          this.editAgentModal.show();
        }
      } else {
        const userParams: userRequestParams = {
          objectId : this.currentAgent,
          isActive : true,
        };
        const response = await this.dataService.postToServer('functions/updateUser', userParams);
        if(response){
          this.notifier.notify('success', 'Agent Activated Successfully!!!');
          this.getAgentsList('deactive').then();
          this.editAgentModal.hide();
        }
        else{
          this.notifier.notify('error', 'Error activating agent.');
          this.editAgentModal.show();
        }
      }
      this.deactivateModal = false;
      this.isLoading = true;
    }

  public deleteAgent(agentId : string) {
    this.currentAgent = agentId;
    if(this.agentsPageData && this.agentsPageData.length > 1){}
    this.deactivateModal = true;
  }

  public changeRole(agentId : string) {
    this.currentAgent = agentId;
    this.changeRoleModal.show();
  }

  public async saveRole() {
    const changeFormRole = this.changeRoleForm.get('changeFormRole')!.value;
    const userParams: userRequestParams = {
      objectId : this.currentAgent,
      userType : changeFormRole,
    };
    const response = await this.dataService.postToServer('functions/updateUser', userParams);
    if(response){
      this.notifier.notify('success', 'Role Updated Successfully!!!');
      this.changeRoleModal.hide();
      this.getAgentsList('Active').then();
    }
    else{
      this.notifier.notify('error', 'Error Changing Role.');
      this.changeRoleModal.show();
    }
    this.isLoading = true;
  }
  public isFieldValid(field: string, currentForm: FormGroup) {
    if (currentForm.get(field) && !this.disableFields) {
      return !currentForm.get(field)!.valid && currentForm.get(field)!.touched;
    }
    return;
  }

  public displayFieldCss(field: string, currentForm: FormGroup) {
    return {
      'has-error': this.isFieldValid(field, currentForm),
      'has-feedback': this.isFieldValid(field, currentForm),
    };
  }
  public async onSelectFile(event: Event) {
    this.profilePhotoLoader = true;
    const files = (event.target as HTMLInputElement).files;
    if (files) {
      if((files[0].size / (1024 * 1024)) > 1){
        (event.target as HTMLInputElement).value = '';
        this.notifier.notify('error', 'Please select a file size less than 1 MB.');
        this.profilePhotoLoader = false;
        return;
      }
      else{
        this.imgURL = await this.dataService.uploadFile(files[0]);
      }
    }
    this.profilePhotoLoader = false;
  }
  public async agentFormRole(event: Event) {
    const selectedRole = (event.target as HTMLSelectElement).value;
    console.log('Selected Role:', selectedRole);
    this.showOrHideIsPrivateOption = selectedRole === 'agent';
    if(!this.showOrHideIsPrivateOption) {
      this.createAgentForm.get('markeMemberPrivate')!.reset();
    }
  }
}

