import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'defaultPic',
  pure: true,
})
export class DefaultPicPipe implements PipeTransform {

  public transform(photoObject: any): any {
    if(photoObject){
      return photoObject.url;
    }
    else{
      return 'assets/images/user-01.svg';
    }
  }

}
