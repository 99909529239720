import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormGroup, Validators, FormArray, FormBuilder } from '@angular/forms'
import { NotifierService } from 'angular-notifier';
import { Quotes, DisplayQuotes, productsList } from 'src/app/types/quotes';
import { AuthService } from '../../services/auth';
import { DataService } from '../../services/data.service';
import { Products } from 'src/app/types/products';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import * as moment from 'moment';





@Component({
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.scss']
})
export class QuotesComponent implements OnInit {

  @ViewChild('newQuote') public newQuoteModal?: ModalDirective;
  @ViewChild('editQuote') public editQuoteModal?: ModalDirective;
  @ViewChild('newProduct') public newProductModal?: ModalDirective;
  @ViewChild(DataTableDirective) public datatableElement: DataTableDirective;
  @ViewChild('tableWrapper') public tableWrapperElement: ElementRef;
  public newQuoteForm: FormGroup;
  // public editQuoteForm: FormGroup;
  public newProductForm: FormGroup;
  public disableFields: boolean;
  public productList: any[] = [];
  public leadsList: any[] = [];
  public productID: any;
  public quotesList: any = [];
  public dtOptions: DataTables.Settings = {};
  public dtElement: DataTableDirective;
  // public dtOptions = {};
  public dtTrigger: Subject<any> = new Subject();
  public dtRendered = false;
  public dtInstance: DataTables.Api;
  public isLoading: boolean;
  public archiveModal = false;
  public unArchiveModal = false;
  public quoteId: any;
  public leadObjectId: any;
  public minDate: string;
  public existingQuoteData: any = [];
  public existingQuoteName: string;
  public editingQuotation: boolean;
  public selectedQuoteData: Quotes;

  constructor(
    private fb: FormBuilder,
    private notifier: NotifierService,
    public authService: AuthService,
    private dataService: DataService,
    public cdr: ChangeDetectorRef,

  ) { }

  ngOnInit() {
    this.intializeQuoteForms();
    this.getProducts();
    this.getLeadsData();
    this.fetchQuotesData();
  }

  private intializeQuoteForms() {
    this.newQuoteForm = this.fb.group({
      quote_name: ['', Validators.required],
      validityDate: [new Date(), Validators.required],
      description: ['This quote is valid for 15 days', Validators.required],
      lead: ['', Validators.required],
      quotationProducts: this.fb.array([]),
      total: [0]
    });

    // this.editQuoteForm = this.fb.group({
    //   quote_name: ['', Validators.required],
    //   validityDate: [new Date(), Validators.required],
    //   description: ['This quote is valid for 15 days', Validators.required],
    //   lead: ['', Validators.required],
    //   quotationProducts: this.fb.array([]),
    //   total: [0]
    // });

    this.newProductForm = this.fb.group({
      product_name: ['', Validators.required],
      product_description: ['', Validators.required],
      price_type: ['', Validators.required],
      price_period: ['', Validators.required],
      new_price: [, Validators.required],
      new_sheets: [, Validators.required],
    });

    this.addQuotationProductRow();

  }

  quotationProducts(): FormArray {
    return this.newQuoteForm.get("quotationProducts") as FormArray
  }

  calculateQuoteProductSubtotal(index: number): void {
    const selectedProduct: productsList = this.quotationProducts().controls[index].value;
    const subTotal = (parseFloat(selectedProduct.quantity) || 0) * (parseFloat(selectedProduct.amount) || 0);
    this.quotationProducts().controls[index].get('subTotal')!.setValue(subTotal);
    const total = this.quotationProducts().controls.reduce(
      (accumulator, currentValue) => accumulator + (parseFloat(currentValue.get('subTotal')!.value) || 0),
      0
    );
    this.newQuoteForm.get('total')!.setValue(total);
  }

  newQuotationProductRow(data?: any): FormGroup {
    return this.fb.group({
      quantity: data ? data.quantity : 0,
      amount: data ? data.amount : 0,
      objectId: data ? `${data.objectId}:${data.name}` : '',
      subTotal: data ? data.subTotal : 0
    });
  }

  addQuotationProductRow(data?: any) {
    this.quotationProducts().push(this.newQuotationProductRow(data));
  }

  removeQuantity(i: number) {
    this.quotationProducts().removeAt(i);
  }

  public async addQuote() {
    this.editingQuotation = false;
    this.quoteId = '';
    this.leadObjectId = '';
    this.newQuoteForm.reset();
    this.intializeQuoteForms();
    this.newQuoteModal!.show();
  }


  public isFieldValid(field: string, currentForm: FormGroup) {
    if (currentForm.get(field) && !this.disableFields) {
      return !currentForm.get(field)!.valid && currentForm.get(field)!.touched;
    }
    return;
  }

  public displayFieldCss(field: string, currentForm: FormGroup) {
    return {
      'has-error': this.isFieldValid(field, currentForm),
      'has-feedback': this.isFieldValid(field, currentForm),
    };
  }

  public selectCustomer(e: any) {
    this.newQuoteForm.get('email')!.setValue(e.email);
    this.newQuoteForm.get('phone')!.setValue(e.phoneNumber);
    this.newQuoteForm.get('contactId')!.setValue(e.objectId);
  }

  public async createQuote(update?: boolean) {
    try {
      this.isLoading = true;
      const quoteName = this.newQuoteForm.get('quote_name')!.value;
      const validityDate = this.newQuoteForm.get('validityDate')!.value;
      const description = this.newQuoteForm.get('description')!.value;
      const products = this.newQuoteForm.get("quotationProducts")!.value as productsList[];
      products.forEach(p => {
        const [objectId,name] = p.objectId.split(":");
        p.objectId = objectId;
        p.name = name;
      })
      const total = +this.newQuoteForm.get('total')!.value;
      const leadId = update ? this.leadObjectId : this.newQuoteForm.get('lead')!.value;
      if (!quoteName) {
        this.notifier.notify('error', 'QuoteName cannot be empty');
        return
      }
      if (!validityDate) {
        this.notifier.notify('error', 'Validity cannot be empty');
        return
      }
      if (!description) {
        return this.notifier.notify('error', 'Please Enter Description');
      }
      if (!leadId) {
        return this.notifier.notify('error', 'Please Select Lead');
      }
      const quoteParams: Quotes = {
        name: quoteName,
        entity: {
          __type: 'Pointer',
          className: 'Entity',
          objectId: this.authService.getUser().entityId.objectId,
        },
        user: {
          __type: 'Pointer',
          className: '_User',
          objectId: this.authService.getUser().objectId
        },
        leadId: {
          __type: 'Pointer',
          className: 'Leads',
          objectId: leadId
        },
        products: products,
        objectId: update ? this.quoteId : '',
        description: description,
        validity: { __type: "Date", iso: validityDate },
        total: total,
        status: "pending"
      };
      if (!update) {
        await this.dataService.postToServer('classes/Quotes/', quoteParams);
      }
      else {
        await this.dataService.updateToServer('classes/Quotes/' + this.quoteId, quoteParams);
      }
      const leadObj = {
        quoteValue: total
      };
      try {
        await this.dataService.updateToServer(
          'classes/Leads/' + leadId,
          leadObj,
        );
      } catch (e) {
        this.notifier.notify('error', e.error.error);
      }
      this.isLoading = false;
      this.notifier.notify('success', `Quote ${update ? 'updated' : 'created'} Successfully!!`);
      this.newQuoteModal!.hide();
      this.newQuoteForm.reset();
      this.intializeQuoteForms();
      this.fetchQuotesData();
    } catch (e) {
      this.isLoading = false;
      console.log(e);
      this.newQuoteModal!.hide();
      this.notifier.notify('error', e.error);
    }
  }

  public addProduct(event: Event) {
    event.preventDefault();
    this.newProductModal!.show();
  }

  public async getProducts() {
    let productsQuery;
    if (!this.authService.isSuperAdmin()) {
      productsQuery = {
        where: {
          entity: {
            __type: 'Pointer',
            className: 'Entity',
            objectId: this.authService.getUser().entityId.objectId,
          },
        },
      };
    }
    const resp = await this.dataService.getFromServer(
      'classes/Products',
      productsQuery,
    );
    this.productList = resp.results;
  }

  public async getLeadsData() {
    const leadsQuery = {
      where: {
        entity: {
          __type: 'Pointer',
          className: 'Entity',
          objectId: this.authService.getUser().entityId.objectId,
        },
        isArchived: { $ne: true },
        quoteValue: { "$exists": false },
        status: { "$exists": false }
      },
    };
    const resp = await this.dataService.getFromServer(
      'classes/Leads',
      leadsQuery,
    );
    this.leadsList = resp.results;
  }

  public async createProduct() {
    try {
      const productName = this.newProductForm.get('product_name')!.value;
      const productDescription = this.newProductForm.get('product_description')!.value;
      const productPriceType = this.newProductForm.get('price_type')!.value;
      const productPricePeriod = this.newProductForm.get('price_period')!.value;
      const productPrice = this.newProductForm.get('new_price')!.value;
      const productNoOfSeats = this.newProductForm.get('new_sheets')!.value;
      if (!productName) {
        this.notifier.notify('error', 'Name cannot be empty');
        return
      }
      if (!productDescription) {
        this.notifier.notify('error', 'Description cannot be empty');
        return
      }
      if(!productPriceType){
        this.notifier.notify('error', 'Price Type cannot be empty');
        return
      }
      // if(!productPricePeriod){
      //   this.notifier.notify('error', 'Price Period cannot be empty');
      //   return
      // }
      if(!productPrice){
        this.notifier.notify('error', 'Price cannot be empty');
        return
      }
      // if(!productNoOfSeats){
      //   this.notifier.notify('error', 'No Of Seats cannot be empty');
      //   return
      // }
      const productParams: Products = {
        name: productName,
        description: productDescription,
        entity: {
          __type: 'Pointer',
          className: 'Entity',
          objectId: this.authService.getUser().entityId.objectId,
        },
        website: '',
        objectId: '',
        priceType: productPriceType,
        pricePeriod: productPricePeriod,
        price: productPrice,
        noOfSeats: productNoOfSeats,
      };
      const productResponse = await this.dataService.postToServer('classes/Products/', productParams);
      if (productResponse) {
        this.notifier.notify('success', 'Product created Successfully!!!');
        this.productID = productResponse.objectId;
        this.newProductForm.reset();
        this.newProductModal!.hide();
      }
      //this.ngxLoader.start();
      this.getProducts();
      //this.newQuoteForm.get('product')!.setValue(productName);
      //this.ngxLoader.stop();
    } catch (e) {
      //this.newQuoteModal!.hide();
      this.notifier.notify('error', e.error);
    }
  }

  public async fetchQuotesData() {
    try {
      this.isLoading = true;
      const params = {
        include: 'leadId,leadId.contact',
        where: {
          entity: {
            __type: 'Pointer',
            className: 'Entity',
            objectId: this.authService.getUser().entityId.objectId,
          },
        },
      };
      const resp = await this.dataService.getFromServer(
        'classes/Quotes',
        params,
      );
      this.quotesList = resp.results || [];
      const dtOptions = {
        order: [
          [0, 'desc'],
        ],
        responsive: true,
        data: this.quotesList,
        columns: [
          {
            title: '',
            data: () => {
              return ''
            },
          },
          {
            title: 'Name',
            data: (row: DisplayQuotes) => {
              return row.name ? row.name : 'NA';
            },
          },
          {
            title: 'Quotation Date',
            data: (row: DisplayQuotes) => {
              return row.createdAt ? moment(row.createdAt).format('MM/DD/YYYY') : 'NA';

            },
          },
          {
            title: 'Lead',
            data: (row: DisplayQuotes) => {
              const leadName = row.leadId ? row.leadId.description : 'NA';
              return leadName;
            },
          },
          {
            title: 'Total',
            data: (row: DisplayQuotes) => {
              return row.total ? row.total : 0;
            },
          },
          {
            title: 'Actions',
            data: (row: DisplayQuotes) => {
              const archiveClass = row.isArchived ? 'unarchive' : 'archive';
              return `
              <div style="display:flex;align-items:center;justify-content:space-evenly;cursor:pointer"><span data-toggle="tooltip" data-placement="top" title="${archiveClass}"><img class="${archiveClass}" style="width:15px; cursor: pointer;" src="../../../../assets/images/New/${archiveClass}.svg" /></span>
              <span data-toggle="tooltip" class="fa fa-pencil" data-placement="top" title="Edit"></span>
              <a target="_blank" href='${row.quotePdf ? row.quotePdf.url : '#/quotes'}'><span data-toggle="tooltip" class="fa fa-file-pdf-o" data-placement="top" title="Download PDF"></span></a>
              </div>`;
            },
          },
        ],
        rowCallback: (row: Node, data: object) => {
          row.removeEventListener('click', () => {
          });
          row.addEventListener('click', e => {
            if ((e.target as HTMLElement).classList.contains('archive')) {
              this.archiveQuote((data as Quotes).objectId);
            }
            if ((e.target as HTMLElement).classList.contains('unarchive')) {
              this.unArchiveQuote((data as Quotes).objectId);
            }
            if ((e.target as HTMLElement).classList.contains('fa-pencil')) {
              this.editQuote((data as Quotes));
            }
          });
          // @ts-ignore
          row.setAttribute('id', `id-${(data as Quotes).objectId}`);
          return row;
        },
      };
      this.renderTable(dtOptions);
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      this.notifier.notify('error', e.error);
    }
  }

  public async archiveQuote(quoteId: string) {
    this.archiveModal = true;
    this.quoteId = quoteId;
  }

  public async unArchiveQuote(quoteId: string) {
    this.unArchiveModal = true;
    this.quoteId = quoteId;
  }

  public async editQuote(quote: Quotes) {
    this.editingQuotation = true;
    this.quoteId = quote.objectId;
    this.leadObjectId = (quote.leadId as any)!.objectId;
    this.newQuoteForm.get('quote_name')!.setValue(quote.name);
    this.newQuoteForm.get('lead')!.setValue((quote.leadId as any)!.description);
    if (quote.products && quote.products.length > 0) {
      this.removeQuantity(0);
      quote.products.forEach(p => {
        this.addQuotationProductRow(p);
      });
      this.calculateQuoteProductSubtotal(0);
    }
    this.newQuoteModal!.show();
  }

  public async clickYes() {
    try {
      await this.dataService.updateToServer('classes/Quotes/' + this.quoteId, {
        isArchived: true,
      });
      this.fetchQuotesData();
      this.archiveModal = false;
      this.notifier.notify('success', 'Quote Archived');
    } catch (e) {
      this.notifier.notify('error', e.error.error);
    }
  }

  public async clickUnArchive() {
    try {
      await this.dataService.updateToServer('classes/Quotes/' + this.quoteId, {
        isArchived: false,
      });
      this.fetchQuotesData();
      this.unArchiveModal = false;
      this.notifier.notify('success', 'Quote Unarchived');
    } catch (e) {
      this.notifier.notify('error', e.error.error);
    }
  }

  public decline() {
    this.archiveModal = false;
  }

  public declineUnArchive() {
    this.unArchiveModal = false;
  }


  public renderTable = (dtOptions: any) => {
    // destroy you current configuration
    this.dtRendered = false;
    this.dtOptions = dtOptions;
    // make sure your template notices it
    this.cdr.detectChanges();
    // initialize them again
    this.dtRendered = true;
    this.dtTrigger = new Subject();
    this.cdr.detectChanges();
    this.dtTrigger.next();
  }

}
