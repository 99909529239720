import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UserService } from "../../../services/user.service";
import { User } from "../../../types/user";
import { DataService } from "../../../services/data.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment";

@Component({
  selector: "app-payment-card",
  templateUrl: "./payment-card.component.html",
  styleUrls: ["./payment-card.component.scss"]
})
export class PaymentCardComponent implements OnInit {
  @Input() public card: CardType = {} as CardType;
  @Input() public cardsLength: number;
  @Input() public subscriptionDetails: any;
  @Input() public isAddCard = false;
  @Output() public onCardDeleted: EventEmitter<any> = new EventEmitter();
  @Output() public onCardUpdated: EventEmitter<any> = new EventEmitter();
  public minDate = new Date();
  public stripeUpdateForm: FormGroup;

  public userDetails: User;

  constructor(
    public userService: UserService,
    private dataService: DataService,
    private ngxService: NgxUiLoaderService,
    private fb: FormBuilder
  ) {
    this.userDetails = this.userService.getUser() as User;
  }

  ngOnInit() {
    let expiry = "";
    if (this.card.exp_month) {
      expiry = `${this.card.exp_month}/${moment(
        this.card.exp_year,
        "YYYY"
      ).format("YY")}`;
    }
    this.stripeUpdateForm = this.fb.group({
      expiryDate: [expiry, Validators.required]
      /*cvv: [
        "",
        [
          Validators.required,
          Validators.maxLength(4),
          Validators.pattern(/d{3,4}/)
        ]
      ]*/
    });
  }

  public async deleteCard(event: any, card: CardType) {
    event.preventDefault();
    try {
      if (confirm("Are you sure you want to delete this card")) {
        this.ngxService.start();
        const response = await this.dataService.postToServer(
          "functions/DeleteCustomerCard",
          { cardId: card.id }
        );
        this.ngxService.stop();
        if (response.result.deleted) {
          this.onCardDeleted.emit(card.id);
        }
      }
    } catch (e) {
      this.ngxService.stop();
    }
  }

  public async updateCard() {
    if (!this.stripeUpdateForm.valid) {
      return;
    }
    try {
      this.ngxService.start();
      const expiry_month = moment(
        this.stripeUpdateForm.get("expiryDate")!.value,
        "MM/YY"
      );
      const response = await this.dataService.postToServer(
        "functions/UpdateCustomerCard",
        {
          cardId: this.card.id,
          exp_month: Number(expiry_month.format("MM")),
          exp_year: Number(expiry_month.format("YYYY"))
        }
      );
      this.ngxService.stop();
      this.onCardUpdated.emit(response.result);
    } catch (e) {
      this.ngxService.stop();
    }
  }

  public isFieldValid(field: string, currentForm: FormGroup) {
    if (currentForm.get(field)) {
      return !currentForm.get(field)!.valid && currentForm.get(field)!.touched;
    }
    return;
  }

  public displayFieldCss(field: string, currentForm: FormGroup) {
    return {
      "has-error": this.isFieldValid(field, currentForm),
      "has-feedback": this.isFieldValid(field, currentForm)
    };
  }

  public getFormattedYear(exp_year: number) {
    return moment(exp_year, "YYYY").format("YY");
  }
  public getFormattedMonth(exp_month: number) {
    return moment(exp_month, "M").format("MM");
  }
}

type CardType = {
  id: string;
  object: string;
  address_city: null;
  address_country: null;
  address_line1: null;
  address_line1_check: null;
  address_line2: null;
  address_state: null;
  address_zip: null;
  address_zip_check: null;
  brand: string;
  country: string;
  customer: string;
  cvc_check: string;
  dynamic_last4: null;
  exp_month: number;
  exp_year: number;
  fingerprint: string;
  funding: string;
  last4: string;
  metadata: {};
  name: null;
  redaction: null;
  tokenization_method: null;
  wallet: null;
  isPaymentIntent?: boolean;
};
