// ical.service.ts

import { Injectable } from '@angular/core';
import {DataService} from './data.service';

@Injectable({
    providedIn: 'root',
})
export class IcalService {
    constructor(
        private dataService: DataService,
    ) {}
    public async parseIcal(combined: any, name:string, taskActivityNotes: string) {
        try {
            const response = await this.dataService.postToServer(
                'functions/getIcalFile',
                {date: combined, title: name, description: taskActivityNotes},
            );
            return response.result;
        } catch (error) {
            console.error('Error parsing iCalendar data:', error);
            throw error; // Rethrow the error for further handling
        }
    }
}
