import {Component, OnInit} from '@angular/core';
//import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss']
})
export class EventDetailsComponent implements OnInit {
  public isViewMode: boolean;
  public visitorData: any;
  
  constructor(
    // private router: Router, 
    private _location: Location) {
  }

  public ngOnInit() {
    const state = window.history.state;
    this.isViewMode = state.isView;
    this.visitorData = state.visitorData;
    if (this.isViewMode === undefined || !this.visitorData) {
      this.backButtonClick();
    }
  }

  public backButtonClick() {
    //this.router.navigate(["/contact-details"]);
    this._location.back();
  }

}


