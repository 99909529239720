import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { RegisterParameters, ForgotParameters } from '../types/auth';
import { init, setUser, track } from '@inleads/event-logger';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthService {
  public sharingSpaceData: any;
  constructor(
    private dataService: DataService,
    private router: Router,
    private notifier: NotifierService,
  ) {
    let apiKey;
    const stagingApiKey = '12e3259b-7710-48cf-8a3c-3a7efd4d353b';
    const productionApiKey = '577d77fe-d5bc-4ea1-8acd-b738f317f8ff';
    if(environment.production === true) {
      apiKey = productionApiKey;
    } else {
      apiKey = stagingApiKey;
    }
    init(apiKey);
  }
  public async login(emailId: string, password: string) {
    try {
      /* const response = await this.dataService.getFromServer(
        'login?username=' + emailId + '&password=' + password,
      ); */
      // tslint:disable-next-line:no-any
      let response: any = await this.dataService.http
        .get(
          this.dataService.baseURL +
            'login?username=' +
            emailId +
            '&password=' +
            password
        )
        .toPromise();
      response = JSON.parse(JSON.stringify(response));
      if (response) {
        // set user and set event
        setUser(response.email || '', response.name);
        // create event
        track('LOGIN', { loginMethod: 'email' });
        if (response.isActive === false) {
          // this.notifier.notify('error', 'Your Account is Deactivated');
          this.notifier.notify(
            'error',
            'Your Account has been Blocked, Please Contact Administrator',
          );
        } else {
          // await this.dataService.updateSessionHeaders(response.sessionToken);
          // await this.getEntityDetails(response);
          // localStorage.setItem('user', JSON.stringify(response));
          // sessionStorage.setItem('user', JSON.stringify(response));
          // this.router.navigate(['/dashboard']);
          // this.notifier.notify('success', 'Welcome ' + response.name);
          return response;
        }
      }
    } catch (error) {
      this.notifier.notify('error', error.error.error);
    }
  }

  public async doLoginWithGoogle(response: any) {
    await this.dataService.updateSessionHeaders(response.sessionToken);
    localStorage.setItem('user', JSON.stringify(response));
    sessionStorage.setItem('user', JSON.stringify(response));
    await this.getEntityDetails(response);
    this.router.navigate(['/dashboard']);
    // set user and set event
    setUser(response.email || '', response.name);
    // create event
    track('LOGIN', { loginMethod: 'logInWithGoogle' });
    // this.notifier.notify("success", "Welcome " + response.name);
  }
  public async doSignupWithGoogle(response: any) {
    try {
      await this.dataService.updateSessionHeaders(response.sessionToken);
      const getCurrentUser = await this.newSignUpUser();
      localStorage.setItem('user', JSON.stringify(getCurrentUser));
      sessionStorage.setItem('user', JSON.stringify(getCurrentUser));
      await this.getEntityDetails(getCurrentUser);
      await this.router.navigate(['/dashboard']);
      // set user and set event
      setUser(getCurrentUser.email || '', getCurrentUser.name);
      track('SIGNUP');
    } catch (error) {
      this.notifier.notify('error', error.message);
    }
  }

  public async register(userDetails: RegisterParameters) {
    const { companyName, ...userDetailsWithoutCompany } = userDetails;
    const response = await this.dataService.postToServer('users', userDetailsWithoutCompany);
    try {
      await this.dataService.updateSessionHeaders(response.sessionToken);
      const getCurrentUser = await this.newSignUpUser();
      getCurrentUser.companyName = companyName;
      localStorage.setItem('user', JSON.stringify(getCurrentUser));
      sessionStorage.setItem('user', JSON.stringify(getCurrentUser));
      await this.getEntityDetails(getCurrentUser);
      await this.router.navigate(['/dashboard']);
      this.notifier.notify('success', 'Welcome ' + getCurrentUser.name);
      // set user and set event
      setUser(getCurrentUser.email || '', getCurrentUser.name);
      track('SIGNUP');
      // return getCurrentUser;
    } catch (error) {
      this.notifier.notify('error', error.error.error);
    }
  }

  public async syncUser() {
    const getCurrentUser = await this.newSignUpUser();
    localStorage.setItem('user', JSON.stringify(getCurrentUser));
    sessionStorage.setItem('user', JSON.stringify(getCurrentUser));
    await this.getEntityDetails(getCurrentUser);
  }

  public async newSignUpUser() {
    return await this.dataService.getFromServer('users/me');
  }

  public async forgotPassword(forgotDetails: ForgotParameters) {
    const response = await this.dataService.postToServer(
      'requestPasswordReset',
      forgotDetails,
    );
    if (response) {
      this.notifier.notify(
        'success',
        'Successfully sent reset link to your email'
      );
    } else {
      this.notifier.notify('error', 'No user found with email');
    }
  }

  public getUser() {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : undefined;
  }

  public isSignedIn() {
    const userToken = this.getUser();
    return !!userToken;
  }

  public isSuperAdmin() {
    const user = this.getUser();
    return user.userType === 'super-admin';
  }

  public isAdmin() {
    const user = this.getUser();
    return user.userType === 'admin';
  }

  public isAgent() {
    const user = this.getUser();
    return user.userType === 'agent';
  }

  public isCustomer() {
    const user = this.getUser();
    return user.userType === 'customer';
  }

  public isManager() {
    const user = this.getUser();
    return user.userType === 'manager';
  }

  public saveData(str: any) {
    this.sharingSpaceData = str;
  }

  public getData() {
    return this.sharingSpaceData;
  }

  public isCSPUser() {
    const user = this.getUser();
    return user && user.entityId && user.entityId.objectId === 'ZFwqCNUXv2';
  }

  public isPrivateUser() {
    const user = this.getUser();
    return user && user.isPrivate === true;
  }

  public async getEntityDetails(response: any) {
    try {
      if (response.entityId && response.entityId.objectId) {
        const entityResponse = await this.dataService.getFromServer(
            'classes/Entity/' + response.entityId.objectId,
        );
        if (entityResponse) {
          localStorage.setItem('entity', JSON.stringify(entityResponse));
        }
      }
      return;
    } catch (e) {
      console.log(`${e}`);
      return;
    }
  }
}
