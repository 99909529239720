import {Injectable} from '@angular/core';
import {DataService} from './data.service';
import {
  nomineeRequestParams,
  User,
  userRequestParams,
  updateUserRequestParams,
} from '../types/user';
import {NotifierService} from 'angular-notifier';
import {EmitterService} from './emitter.service';
import {AuthService} from './auth';

@Injectable()
export class UserService {
  constructor(private dataService: DataService, private auth: AuthService, private notifier: NotifierService) {
  }

  public getUser(): User | undefined {
    const user = localStorage.getItem('user');
    return user ? (JSON.parse(user)) : undefined;
  }

  public updateUser(key: string, value: any) {
    const localUser = localStorage.getItem('user') || '{}';
    const user = JSON.parse(localUser);
    user[key] = value;
    localStorage.setItem('user', JSON.stringify(user));
    sessionStorage.setItem('user', JSON.stringify(user));
    return user;
  }

  public updateSessionToken(token: string) {
    const user = this.getUser();
    if (!user) {
      return;
    }
    user.sessionToken = token;
    localStorage.setItem('user', JSON.stringify(user));
    sessionStorage.setItem('user', JSON.stringify(user));
  }

  public async createNominee(nomineeDetails: nomineeRequestParams) {
    const response = await this.dataService.postToServer(
      'users',
      nomineeDetails,
    );
    return response;
  }

  public async createUser(userDetails: userRequestParams) {
    const response = await this.dataService.postToServer('users', userDetails);
    return response;
  }

  /*public async updateUser(
    userDetails: updateUserRequestParams,
    objectId: string,
  ) {
    const response = await this.dataService.updateToServer(
      `users/${objectId}`,
      userDetails,
    );
    return response;
  }*/

  public async editUserSave(userDetails: updateUserRequestParams) {
    const userDetails1 = {
      name: userDetails.name,
      gender: userDetails.gender,
      dateOfBirth: userDetails.dateOfBirth,
      relation: userDetails.relation,
      phoneNumber: userDetails.phoneNumber,
      isActive: userDetails.isActive,
      profilePhoto: userDetails.profilePhoto,
    };
    const response = await this.dataService.updateToServer(
      `users/` + userDetails.objectId,
      userDetails1,
    );

    try {
      /*if (response.result === 'success') {*/
      const localUser = localStorage.getItem('user') || '{}';
      const user = JSON.parse(localUser);
      if (user.objectId === userDetails.objectId) {
        Object.keys(userDetails).forEach(key => {
          user[key] = userDetails[key as keyof updateUserRequestParams];
        });
        localStorage.setItem('user', JSON.stringify(user));
        sessionStorage.setItem('user', JSON.stringify(user));
        EmitterService.get('headerUpdate').emit(user);
        return user;
      } else {
        return response;
      }
      // }
    } catch (e) {
      this.notifier.notify('error', e.error.error);
    }

  }


  get userType(): string {
    const user = this.getUser();
    return user ? user!.userType : '';
  }

  get userId(): string {
    return this.getUser()!.objectId;
  }

  get sessionToken(): string {
    return this.getUser()!.sessionToken || '';
  }

  get isCustomer(): boolean {
    return this.userType === 'customer';
  }

  get isAgent(): boolean {
    return this.userType === 'agent';
  }

  get isManager(): boolean {
    return this.userType === 'manager';
  }

  get isAdmin(): boolean {
    return this.userType === 'admin';
  }

  get isSuperAdmin(): boolean {
    return this.userType === 'super-admin';
  }

  public async getAllUsers(type: boolean) {
    const userParams = {
      where: {
        isActive: type,
      },
      include: 'nominee_referral',
      order: '-createdAt',
    };
    const users = await this.dataService.getFromServer(
      'classes/_User',
      userParams,
    );
    let filteredUser: User[];
    const currentUser = this.getUser();
    if (this.isSuperAdmin) {
      filteredUser = users.results;
    } else if (this.isAdmin) {
      filteredUser = users.results.filter((u: User) => {
        return (
          u.entityId && u.entityId.objectId === this.getUser()!.entityId!.objectId
        );
      });
    } else {
      filteredUser = users.results.filter((u: User) => {
        return (
          u.agent && u.agent.objectId === this.userId ||
          (u.nominee_referral && u.nominee_referral.objectId === this.userId)
        );
      });
      if (this.isCustomer && currentUser && type === currentUser.isActive) {
        filteredUser.unshift(currentUser);
      }
    }
    return filteredUser;
  }


  public async userEntityData() {
    if(!this.auth.getUser()){
      return
    }
    const entityParams = {
      where: {
        objectId: this.auth.getUser().objectId
      },
      include: 'entityId',

    };
    const entityObj = await this.dataService.getFromServer(
      'users/',
      entityParams,
    );
    return entityObj && entityObj.results[0] || [];
    // localStorage.setItem('user',JSON.stringify(usersEntityData))
  }

  public async getChatDocSetting() {
    const botParams = {
      where: {
        user: {__type: 'Pointer', className: '_User', objectId: this.auth.getUser().objectId},
      },
    };
    const botSettingsObj = await this.dataService.getFromServer(
      'classes/ChatBotSettings/',
      botParams,
    );
    const botSettingsData = botSettingsObj && botSettingsObj.results[0] || [];
    return botSettingsData;
  }

  public async saveChatDocSetting(formData: any) {

    const botSettingsObj = await this.dataService.postToServer(
      'functions/save-chat-doc-settings',
      formData,
    );


    return botSettingsObj;
  }

  


  public async getCustomersCount() {
    const users: User[] = await this.getAllUsers(true);
    return users.length || 0;
  }

  public async updateUserPassword(password: string) {
    const targetUrl = `users/${this.userId}`;
    const user = this.getUser();
    if (!user || !user.sessionToken) {
      throw new Error('Unable to fetch user');
      // return;
    }
    await this.dataService.updateSessionHeaders(user.sessionToken);
    const result = await this.dataService.updateToServer(targetUrl, {
      password,
    });
    this.updateSessionToken(result.sessionToken);
    await this.dataService.updateSessionHeaders(result.sessionToken);
    return result;
  }
}
