import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { ModalDirective } from 'ngx-bootstrap';
import { AuthService } from 'src/app/services/auth';
import { DataService } from 'src/app/services/data.service';
import { UserService } from 'src/app/services/user.service';
import { DatatableSettings } from 'src/app/types/datatables';
import { User } from 'src/app/types/user';
import * as moment from 'moment';
// import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-spaces',
  templateUrl: './spaces.component.html',
  styleUrls: ['./spaces.component.scss'],
})
export class SpacesComponent implements OnInit {
  public isLoading: boolean;
  public dtInstance: DataTables.Api;
  public dtOptions: DatatableSettings = {};
  public spacesData: any[] = [];
  public spacesForm: FormGroup;
  public currentUser: User | undefined;
  public selectedSpace: any;
  public archiveModal = false;
  public unArchiveModal = false;
  public btnClicked = false;
  public typeEditable = false;
  // @ViewChild('newSpace') public newSpace: TemplateRef<any>;
  @ViewChild('newSpace')
  public newSpace: ModalDirective;

  constructor(
    public userService: UserService,
    private dataService: DataService,
    private fb: FormBuilder,
    private notifier: NotifierService,
    public authService: AuthService,
    // private helperService: HelperService,
    ) { }

  public ngOnInit() {
    this.currentUser = this.userService.getUser();
    this.loadSpaces().then();
    this.initializeSpacesForm();
  }

  public async loadSpaces() {
    this.isLoading = true;
    let requestSpaceParams:any = {};
    if (this.authService.isSuperAdmin()) {
      requestSpaceParams = {
        include: 'stage,entity',
        limit: 10000,
        order: '-createdAt',
        // where:{
        //   isArchived: { $ne : true },
        // }
      };
    } else if (this.authService.isAdmin()) {
      requestSpaceParams = {
        include: 'stage,entity',
        limit: 10000,
        order: '-createdAt',
        where: {
          entity: {__type: 'Pointer', className: 'Entity', objectId: this.authService.getUser().entityId.objectId},
         // isArchived: { $ne : true },
        },
      };
    } else if (this.authService.isAgent()) {
    } else if (this.authService.isManager()) {
    }

    const response = await this.dataService.getFromServer('classes/Spaces', requestSpaceParams);
    this.spacesData = response.results || [];
    this.isLoading = false;


    this.dtOptions = {
      order: [[1, 'desc'], [3, 'desc']],
      dom: '<"appRequestsFilter"lf>tipr',
      columnDefs: [
        {
          orderable: false,
          className: 'responsive-checkbox',
          targets: 0,
          responsivePriority: 1,
        },
        {
          defaultContent: 'NA',
          targets: '_all',
        },
      ],
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search...',
        lengthMenu: '<span>Show</span> _MENU_',
      },
      responsive: true,
      data: this.spacesData,
      columns: [
        {data: ''},
        {
          data: (row: any) => {
            return row.name || 'NA';
          },
        },
        {
          data: (row: any) => {
            return row.type || 'NA';
          },
        },
        {
          data: (row: any) => {
            return moment(row.createdAt).format('DD MMM, YYYY');
          },
        },
        {
          data: (row: any) => {
            const archiveClass = row.isArchived ? 'unarchive' : 'archive';
            return `<span class="fa fa-pencil"></span>&nbsp;&nbsp;&nbsp;&nbsp; <span data-toggle="tooltip" data-placement="top" title="${archiveClass}"><img class="${archiveClass}" style="width:15px;" src="../../../../assets/images/New/${archiveClass}.svg" /></span>`;
          },
        },
      ],
      rowCallback: (row: Node, data: object) => {
        row.removeEventListener('click', () => {
        });
        row.addEventListener('click', e => {
          this.selectedSpace = data;
          if ((e.target as HTMLElement).classList.contains('fa-pencil')) {
            this.typeEditable = true;
            this.addSpace(e);
          } else if ((e.target as HTMLElement).classList.contains('archive')) {
            this.archiveSpace();
          }
          else if ((e.target as HTMLElement).classList.contains('unarchive')) {
            this.unArchiveSpace();
          }
        });
        return row;
      },
    };
  }

  public renderTable() {
    this.loadSpaces().then();
  }

  private initializeSpacesForm() {
    const space = {
      name: this.selectedSpace ? this.selectedSpace.name : '',
      type: this.selectedSpace ? this.selectedSpace.type : '',
      entity: this.selectedSpace ? this.selectedSpace.entity.name : '',
    }
    this.spacesForm = this.fb.group({
      // objectId: ['', Validators.required],
      name: [space.name, Validators.required],
      type: [space.type, Validators.required],
      entity: [space.entity.name],
    });
  }

  public addSpace(event: Event) {
    event.stopImmediatePropagation();
    this.initializeSpacesForm();
    this.newSpace.show();
  }

  public async createSpace() {
    const spacesParams = {
      name: this.spacesForm.get('name')!.value,
      type: this.spacesForm.get('type')!.value,
      entity: {
        __type: 'Pointer',
        className: 'Entity',
        objectId: this.currentUser!.entityId!.objectId,
      },
    };
    try{
      if(this.selectedSpace) {
        this.btnClicked = true;
        await this.dataService.updateToServer('classes/Spaces/'+this.selectedSpace.objectId, spacesParams);
        this.notifier.notify('success', 'Space Updated');
        this.newSpace.hide();
        this.selectedSpace = null;
        this.typeEditable = false;
        this.renderTable();
      } else {
        this.btnClicked = true;
        // const data = await this.dataService.postToServer('classes/Spaces', spacesParams);
        // const stageParams = {
        //   entity: {
        //     __type: 'Pointer',
        //     className: 'Entity',
        //     objectId: this.currentUser!.entityId!.objectId,
        //   },
        //   spaceId: {
        //     __type: 'Pointer',
        //     className: 'Spaces',
        //     objectId: data.objectId,
        //   },
        // };
        const funnelParams = {
          type:  this.spacesForm.get('type')!.value,
        };
          await this.dataService.postToServer(
            'functions/createFunnelTemplate',
           funnelParams,
          );
        this.newSpace.hide();
        this.renderTable();
        this.notifier.notify('success', 'New Space Created');
      }
      this.btnClicked = false;
      
      // this.helperService.setLiveQueryDataSpaces();
      
    } catch(e) {
      this.notifier.notify('error', e.error.error);
      this.newSpace.hide();
      this.isLoading = false;
    }
  }

  public async closeSpaceForm(){
    this.typeEditable = false;
    this.newSpace.hide();
  }
  public async archiveSpace() {
    this.archiveModal = true;
  }

  public async unArchiveSpace() {
    this.unArchiveModal = true;
  }

  public async clickYes() {
    try{
      await this.dataService.updateToServer('classes/Spaces/' + this.selectedSpace.objectId, {
        isArchived: true,
      });
      this.renderTable();
      this.archiveModal = false;
      this.notifier.notify('success', 'Space Archived');
    } catch(e) {
      this.notifier.notify('error', e.error.error);
    }
  }

  public async clickUnArchive() {
    try{
      await this.dataService.updateToServer('classes/Spaces/' + this.selectedSpace.objectId, {
        isArchived: false,
      });
      this.renderTable();
      this.unArchiveModal = false;
      this.notifier.notify('success', 'Space Unarchived');
    } catch(e) {
      this.notifier.notify('error', e.error.error);
    }
  }

  public decline() {
    this.archiveModal = false;
  }

  public declineUnArchive() {
    this.unArchiveModal = false;
  }

}
