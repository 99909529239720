import { Component, OnInit } from "@angular/core";
const GaugeChart = require("gauge-chart");
import { AuthService } from "src/app/services/auth";
import { DataService } from "src/app/services/data.service";
@Component({
  selector: "app-net-promoter-score-widget",
  templateUrl: "./net-promoter-score-widget.component.html",
  styleUrls: ["./net-promoter-score-widget.component.scss"],
})
export class NetPromoterScoreWidgetComponent implements OnInit {
  public nps: number;
  constructor(
    public authService: AuthService,
    public dataService: DataService
  ) {}
  ngOnInit() {
    this.getNPS().then(() => {
      this.npsGuageWidget();
    });
  }

  public async getNPS() {
    const npsScore = await this.dataService.getFromServer("classes/Entity", {
      where: { objectId: this.authService.getUser().entityId.objectId },
    });
    this.nps = npsScore.results[0].netPromoterScore;
  }

  public async npsGuageWidget() {
    // Element inside which you want to see the chart
    let element = document.querySelector("#gaugeArea");

    if(this.nps !== undefined && element){    
      // Calculate the mapped value for the gauge (-100 to 100 range)
      let mappedNpsValue = (this.nps + 100) / 2; // Map -100 to 0, 100 to 100
    
    // Properties of the gauge
    let gaugeOptions = {
      hasNeedle: true,
      needleColor: "gray",
      needleUpdateSpeed: 1000,
      arcColors: ["#E7E7F2", "#C2C4E0", "#585CA8", "#1B1464"],
      arcDelimiters: [25, 50, 75],
      arcLabels: ["-50", "0", "50"],
      rangeLabel: ["-100", "100"],
      // centralLabel: this.nps.toString(),
    };
 

    // Drawing and updating the chart
    GaugeChart.gaugeChart(element, 300, gaugeOptions).updateNeedle(mappedNpsValue);
  }
  }
}
