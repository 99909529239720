import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth';
@Component({
  selector: 'app-eventlogger-installation-guide',
  templateUrl: './eventlogger-installation-guide.component.html',
  styleUrls: ['./eventlogger-installation-guide.component.scss']
})

export class EventloggerInstallationGuideComponent implements OnInit {
  @ViewChild('copyEventLoggerScript') copyEventLoggerScript: ElementRef;
  @ViewChild('copyEventLoggerVariable') copyEventLoggerVariable: ElementRef;
  @ViewChild('copyEventLoggerFuctionCall') copyEventLoggerFuctionCall: ElementRef;
  public businessId: string;
  nodeJsCode = `
  const InLeads = require('@inleads/event-logger')
  // The only required field is the api token
  InLeads.init('api-token');

  // Only email is Mandatory
  InLeads.setUser('email', 'name', { role: 'some-role' });

  // track should only be called once init and setUser is completed
  // event-name is mandatory
  InLeads.track('event-name', { from: 'screen-name' });`;
  es6ModuleCode = `
  import { init, track, setUser } from '@inleads/event-logger'
  // The only required field is the api token
  init('api-token');

  // Only email is Mandatory
  setUser('email', 'name', { role: 'some-role' });

  // track should only be called once init and setUser is completed
  // event-name is mandatory
  track('event-name', { from: 'screen-name' });
  `;
  generalJsCode = `
  // The only required field is the api token
  inleadsEvents.init('api-token');
          
  // Only email is Mandatory
  inleadsEvents.setUser('email', 'name', { role: 'some-role' });
          
  // track should only be called once init and setUser is completed
  // event-name is mandatory
  inleadsEvents.track('event-name', { from: 'screen-name' });
  `;
  initCode = `init('api-token')`;
  setUserCode = `setUser('email', 'name', {role: "userRole"})`;
  trackCode = `track('event-name', {from: "screenName"})`;

  constructor(
    public authService: AuthService,
  ) { }

  ngOnInit() {
    this.businessId = this.authService.getUser().entityId.objectId;
  }

  copyEventLoggerScriptCode() {
    const textArea = this.copyEventLoggerScript.nativeElement;
    textArea.select();
    document.execCommand('copy');
    textArea.setSelectionRange(0, 0);
  }

  copyEventLoggerVariableCode() {
    const textArea = this.copyEventLoggerVariable.nativeElement;
    textArea.select();
    document.execCommand('copy');
    textArea.setSelectionRange(0, 0);
  }

  copyEventLoggerFuctionCallCode() {
    const textArea = this.copyEventLoggerFuctionCall.nativeElement;
    textArea.select();
    document.execCommand('copy');
    textArea.setSelectionRange(0, 0);
  } 
  

}
