import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { ModalDirective } from "ngx-bootstrap";
import { DataService } from "../../../services/data.service";
import { NgxUiLoaderService } from "ngx-ui-loader";

declare var Stripe: any;

@Component({
  selector: "app-payment-methods",
  templateUrl: "./payment-methods.component.html",
  styleUrls: ["./payment-methods.component.scss"]
})
export class PaymentMethodsComponent implements OnInit {
  @Input() public cards: any = [];
  @Input() public subscriptionDetails: any;
  @Output() public updateCards: EventEmitter<any> = new EventEmitter();
  @Output() public reloadCards: EventEmitter<any> = new EventEmitter();
  public cardForm: any;

  @ViewChild("paymentTemplate") public paymentTemplate: ModalDirective;
  private stripeKey =
    "pk_live_51JNCynSGwJP69Nw9jMulMlED1pzPQos6GNFJLuLW6CvdbK1ye3AHSc2z5Z3GZT9RngFdo9JBwDQXWfbLPQL9i5PV00pubGJILK";

  /*private stripeKey =
    "pk_test_51JNCynSGwJP69Nw9ZsCoQaqKb1VbHcWqax4K0VwTFlbcijP141O0jdqMpd1Vr9sXzsI4TUkHvSHgyp8MetdmKp9q004SX1lUEd";*/
  public stripe: any;

  constructor(
    private dataService: DataService,
    private ngxService: NgxUiLoaderService
  ) {}

  ngOnInit() {}

  public async showAddStripeCard() {
    if (!this.stripe) {
      this.stripe = Stripe(this.stripeKey);
    }
    const elements = this.stripe.elements();
    this.cardForm = elements.create("card", {
      hidePostalCode: true
    });
    this.cardForm.mount("#add-card-element");
  }

  public async addCardToCustomer() {
    try {
      this.ngxService.start();
    const tokenResponse = await this.stripe.createToken(this.cardForm);
    const response = await this.dataService.postToServer(
      "functions/AddCustomerCard",
      { token: tokenResponse.token.id }
    );
      if (response.result.type && response.result.type === "makePayment") {
        await this.makeTestPayment(
          response.result.paymentIntent,
          tokenResponse.token.id
        );
        this.paymentTemplate.hide();
      } else {
        this.ngxService.stop();
    this.updateCards.emit([...this.cards, response.result]);
        this.paymentTemplate.hide();
      }
    } catch (e) {
      alert(e.message);
      this.ngxService.stop();
    }
  }

  public async makeTestPayment(paymentIntent: any, token: string) {
    const response = await this.stripe.confirmCardPayment(
      paymentIntent.client_secret,
      {
        payment_method: {
          type: "card",
          card: { token }
        },
        setup_future_usage: "off_session"
      }
    );
    if (response && response.error) {
      alert(response.error.message);
    }
    this.reloadCards.emit();
    this.ngxService.stop();
    this.paymentTemplate.hide();
  }

  public onCardUpdated(event: any) {
    const cards = this.cards.map((card: any) => {
      if (card.id === event.id) {
        return event;
      }
      return card;
    });
    this.updateCards.emit(cards);
    this.ngxService.stop();
  }

  public onCardDeleted(event: string) {
    this.updateCards.emit(this.cards.filter((card: any) => card.id !== event));
    this.ngxService.stop();
  }
}
