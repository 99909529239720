import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-visitor-deatils',
  templateUrl: './visitor-deatils.component.html',
  styleUrls: ['./visitor-deatils.component.scss']
})
export class VisitorDeatilsComponent implements OnInit {
  public isViewMode: boolean;
  public visitorData: any;
  
  constructor(private router: Router) {
  }

  public ngOnInit() {
    const state = window.history.state;
    this.isViewMode = state.isView;
    this.visitorData = state.visitorData;
    if (this.isViewMode === undefined || !this.visitorData) {
      this.backButtonClick();
    }
  }

  public backButtonClick() {
    this.router.navigate(["/visitors"]);
  }

}

