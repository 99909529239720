import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { ContactsParams } from '../types/contacts';
import { PostResponse } from '../types/common';

@Injectable({
  providedIn: 'root',
})
export class ContactsService {

  constructor(private dataService: DataService) { }

  public async addContact(obj: ContactsParams): Promise<PostResponse> {
    return await this.dataService.postToServer(
      'classes/Contacts',
      obj,
    );
  }
}
