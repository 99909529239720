import {Component, ElementRef, OnInit, ViewChild, HostListener, AfterViewInit, Input} from '@angular/core';
import { AuthService } from "../../../services/auth";
import { DataService } from "../../../services/data.service";

@Component({
  selector: "app-engaged-user-metrics-widget",
  templateUrl: "./engaged-user-metrics-widget.component.html",
  styleUrls: ["./engaged-user-metrics-widget.component.scss"]
})
export class EngagedUserMetricsWidgetComponent implements OnInit, AfterViewInit {
  @ViewChild('dashboardChartContainer') dashboardChartContainer: ElementRef;
  @Input() dashboardEngagementMetrics: any[];

  public isPaidUser: boolean;
  public chartData = {
    data: [] as any,
    title: '',
    type: 'LineChart',
    columnNames: ['Month', 'New Leads'],
    options: {
      minValue: 0,
      lineWidth: 2,
      colors: ['#2C7BE5', '#714d3f', '#0F0', '#F00'],
      chartArea: {left: '20px', top: '20px', width: '88%', height: '55%', bottom: '50px'},
      legend: {position: 'bottom', top: 10, alignment: 'center', textStyle: {fontSize: 12,}},
      intervals: {style: 'area'},
      hAxis: {
        textStyle: {
          color: 'black',
          fontSize: 10,
        },
        slantedText: true,
        slantedTextAngle: 45,
      },
      vAxis: {
        minValue: 0,
        gridlines: {
          color: 'transparent',
        },
      },
      curveType: 'function',
    },
    width: 800,
    height: 300,
  };

  constructor(
      public dataService: DataService,
      public authService: AuthService,
  ) {
  }

  public ngOnInit() {
    const user = localStorage.getItem("user");
    const currentUser = user ? JSON.parse(user) : {};
    this.isPaidUser = currentUser.paidUser;
    const chartWidth = $('.usersActivity').width();
    let maxValue = 20;
    if (this.dashboardEngagementMetrics) {
      const flattenedMetrics = this.dashboardEngagementMetrics.reduce((acc, val) => acc.concat(val), []);
      maxValue = Math.max(...flattenedMetrics.filter((item: any) => typeof item === 'number'));
      // Add 25% to maxValue
      maxValue = Math.ceil(maxValue * 1.5)
    }
    if (chartWidth) {
      this.chartData = {
        ...this.chartData,
        columnNames: this.authService.isAdmin() ?
            ['Month', 'Daily Active Users', 'New Signup', 'New Leads'] :
            ['Month', 'New Leads'],
        width: chartWidth - 20,
        options: {
          ...this.chartData.options,
          colors: this.authService.isAdmin() ? ['#2C7BE5', '#714d3f', '#0F0', '#F00'] : ['#2C7BE5'],
          vAxis: {
            ...this.chartData.options.vAxis,
            gridlines: { color: '#ccc' },
            viewWindow: { min: 0, max: maxValue } as any // Type assertion to bypass type checking
            } as any
        },
        data: this.dashboardEngagementMetrics || []
      };
    }
  }

  ngAfterViewInit() {
    this.adjustChartWidth();
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.adjustChartWidth();
  }

  private adjustChartWidth() {
    // if (this.dashboardChartContainer) {
    //   const containerWidth = this.dashboardChartContainer.nativeElement.offsetWidth;
    //   this.chartData.width = containerWidth - 20;
    // }
  }
}