import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Fields } from '../../../../types/forms';

@Component({
  selector: 'select2',
  template: `
    <div [formGroup]="form">
      <ng-select2 [id]="formField.name" [formControlName]="formField.name"
        [data]="formField.select2Data"
        [options]="formField.select2Options"
        [placeholder]="formField.placeholder"
        [disabled]="formField.disabled"
        [width]="'100%'"
      ></ng-select2>

      <label
        class="form-control-label sr-only"
        [ngClass]="formField.labelClass"
        [attr.for]="formField.label"
        >{{ formField.label }}
        <strong class="text-danger" *ngIf="formField.required">*</strong>
      </label>
    </div>
  `,
})
export class Select2Component {
  @Input() public formField: Fields;
  @Input() public form: FormGroup;
}
