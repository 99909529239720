// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://devserver.inleads.ai/parse/',
  // apiUrl: 'http://localhost:8081/parse/',
  appId: 'APbfI6sy1gTrfGg2uM6sOeLYW0ROpd2jYX2T1WAP',
  restApiKey: 'eHVPrWXrQu5eQEInMRJgKoegRVLsnKPz0w2nBax0',
  javaScriptKey:'4guF0DdWC8Om90IVwM1hNYRtBy9TjU9DWGT4U9iC',
  masterKey: '',
};
