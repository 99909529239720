import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

// components
import { FormBuilderComponent } from './form-builder.component';
import { FieldBuilderComponent } from './field-builder/field-builder.component';
import { TextBoxComponent } from './fields/textbox';
import { DropDownComponent } from './fields/dropdown';
import { FileComponent } from './fields/file/file';
import { CheckBoxComponent } from './fields/checkbox';
import { RadioComponent } from './fields/radio';
import { DateComponent } from './fields/date';
import { NumberComponent } from './fields/number';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { Select2Component } from './fields/select2';
import { NgSelect2Module } from 'ng-select2';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BsDatepickerModule,
    NgSelect2Module,
  ],
  declarations: [
    FormBuilderComponent,
    FieldBuilderComponent,
    TextBoxComponent,
    DropDownComponent,
    CheckBoxComponent,
    FileComponent,
    RadioComponent,
    DateComponent,
    NumberComponent,
    Select2Component,
  ],
  exports: [FormBuilderComponent,Select2Component ],
  providers: [],
})
export class FormBuilderModule {}
