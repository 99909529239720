import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  private slickDataSource = new BehaviorSubject<any[]>([]);
  currentSlickData = this.slickDataSource.asObservable();

  private chartDataSource = new BehaviorSubject<any[]>([]);
  currentChartData = this.chartDataSource.asObservable();

  private chartImageSource = new BehaviorSubject<string>('');
  currentChartImage = this.chartImageSource.asObservable();

  private retentionRateSource = new BehaviorSubject<any>('');
  currentRetentionRate = this.retentionRateSource.asObservable();

  private churnRateSource = new BehaviorSubject<any>('');
  currentChurnRate = this.churnRateSource.asObservable();

  private dauDataSource = new BehaviorSubject<any>(0);
  currentDau = this.dauDataSource.asObservable();

  private totalLeadsSource = new BehaviorSubject<any>('');
  currentTotalLeads = this.totalLeadsSource.asObservable();

  private totalContactsSource = new BehaviorSubject<any>('');
  currentTotalContacts = this.totalContactsSource.asObservable();

  private totalDealAmountSource = new BehaviorSubject<any>('');
  currentTotalDealAmount = this.totalDealAmountSource.asObservable();

  constructor() { }

  changeSlickData(data: any[]) {
    this.slickDataSource.next(data);
  }

  changeChartData(data: any[]) {
    this.chartDataSource.next(data);
  }

  changeChartImage(image: string) {
    this.chartImageSource.next(image);
  }

  changeRetentionRate(value: any) {
    this.retentionRateSource.next(value);
  }

  changeChurnRate(value: any) {
    this.churnRateSource.next(value);
  }

  changeDau(value: any) {
    this.dauDataSource.next(value);
  }

  changeLeads(value: any) {
    this.totalLeadsSource.next(value);
  }

  changeContacts(value: any) {
    this.totalContactsSource.next(value);
  }

  changeDealAmount(value: any) {
    this.totalDealAmountSource.next(value);
  }
}
