import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Fields } from '../../../../types/forms';

@Component({
  selector: 'textbox',
  template: `
    <div [formGroup]="form">
      <input
        *ngIf="!formField.multiline"
        [attr.type]="formField.type"
        class="form-control"
        [ngClass]="formField.class"
        [id]="formField.name"
        [name]="formField.name"
        [class]="formField.borderClass"
        [formControlName]="formField.name"
        [disabled]="formField.disabled"
        (click)="onClick($event)"
        autocomplete="off"
        required
      />
      <textarea
        *ngIf="formField.multiline"
        [class.is-invalid]="isDirty && !isValid"
        [formControlName]="formField.name"
        [id]="formField.name"
        rows="9"
        class="form-control"
        [placeholder]="formField.placeholder"
      ></textarea>
      <label
        class="form-control-label"
        [ngClass]="formField.labelClass"
        [attr.for]="formField.label"
        >{{ formField.label }}
        <strong class="text-danger" *ngIf="formField.required">*</strong>
      </label>
    </div>
  `,
})
export class TextBoxComponent {
  @Input() public formField: Fields;
  @Input() public form: FormGroup;

  get isValid() {
    return this.form.controls[this.formField.name].valid;
  }

  get isDirty() {
    return this.form.controls[this.formField.name].dirty;
  }

  constructor() {}

  public onClick(event: Event) {
    if (this.formField.onClick) {
      this.formField.onClick(event);
    }
  }
}
