import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder
} from "@angular/forms";
import { Router } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { DataService } from "src/app/services/data.service";
import { UserService } from "src/app/services/user.service";
import { ContactsParams } from "src/app/types/contacts";
import { User } from "src/app/types/user";
import { AuthService } from "../../services/auth";
import * as moment from "moment";
import { ModalDirective } from "ngx-bootstrap/modal";
import { validationPattern } from "../../constants/regexp";
import { Products } from "src/app/types/products";
import { LeadParams } from "../../types/leads";
import { LeadsService } from "../../services/leads.service";
import { track } from '@inleads/event-logger';
import { Location } from '@angular/common';


@Component({
  selector: "app-contact-detail",
  templateUrl: "./contact-detail.component.html",
  styleUrls: ["./contact-detail.component.scss"]
})
export class ContactDetailComponent implements OnInit {
  public form: FormGroup;
  public isViewMode: boolean;
  public contactObjectId: string;
  public contactResponse: any;
  public lastEventsMeta: any;
  public optionsData: any = {};
  public currentUser: User | undefined;
  public imgURL: any;
  public profilePhotoLoader: boolean;
  public formattedaddress: string;
  public newLeadForm: FormGroup;
  public newProductForm: FormGroup;
  public notesForm: FormGroup;
  public disableFields: boolean;
  public productID: any;
  public productList: any[] = [];
  public spacesList: any[];
  public stagesList: any[];
  public selectedSpace: any;
  @ViewChild("newlead") public newleadModal?: ModalDirective;
  @ViewChild("newProduct") public newProductModal?: ModalDirective;
  @ViewChild("editCustomer") public editCustomerModal?: ModalDirective;
  public addressComponents = {
    address: "",
    city: "",
    state: "",
    country: "",
    pincode: ""
  };
  public leadsData: any = [];
  public activityData: any;
  public notesData: any;
  public eventsData: any;
  public minDate: any;
  public selected: any;
  public allStagesList: any = [];
  constructor(
    private dataService: DataService,
    private router: Router,
    public userService: UserService,
    private notifier: NotifierService,
    public authService: AuthService,
    private fb: FormBuilder,
    private leadsService: LeadsService,
    private _location: Location
  ) {}

  public ngOnInit() {
    this.formattedaddress = '';
    this.contactObjectId = window.history.state.contactObjectId;
    this.isViewMode = window.history.state.isView;
    if (this.isViewMode === undefined) {
      this.backButtonClick();
    }

    this.currentUser = this.userService.getUser();
    this.intializeLeadForm();
    this.form = new FormGroup({
      name: new FormControl(""),
      email: new FormControl(""),
      phone: new FormControl("", [
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern(validationPattern.phone)
      ]),
      address: new FormControl(""),
      gender: new FormControl(""),
      city: new FormControl(""),
      state: new FormControl(""),
      pincode: new FormControl("")
    });
    this.notesForm = new FormGroup({
      notesText: new FormControl(''),
    });
    this.getContactDetails(this.contactObjectId);
    this.getSpaces();
    this.getProducts();
  }

  id: string = "contact-info";
  public tabChange(tabId: any) {
    this.id = tabId;
  }

  getObjectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  public async getContactDetails(contactID: string) {
    const contactParams = {
      include: "agent,assignTo,lastEvent"
    };
    this.contactResponse = await this.dataService.getFromServer(
      "classes/Contacts/" + contactID,
      contactParams,
    );
    // const eventsInfoData = await this.dataService.eventsInfo(contactID);
    // if (eventsInfoData) {
    //   this.lastEventsMeta = eventsInfoData.toJSON();
    // }
    this.lastEventsMeta = this.contactResponse.lastEvent;
    this.optionsData = this.contactResponse.options || {};
    this.imgURL = this.contactResponse && this.contactResponse.profilePhoto;
    this.form.patchValue({
      name: this.contactResponse.name || "",
      email: this.contactResponse.email || "",
      phone: this.contactResponse.phoneNumber || "",
      address: this.contactResponse.address || "",
      gender: this.contactResponse.gender || "male",
      city: this.contactResponse.city || "",
      state: this.contactResponse.state || "",
      pincode: this.contactResponse.pincode || ""
    });
  }

  public getLastActivity(lastActivityDate: any) {
    if (lastActivityDate) {
      return moment(lastActivityDate.iso).format("DD MMM YYYY hh:mm:ss A");
    }
    return "NA";
  }

  public viewEventDetails(event: any) {
    this.router.navigate(['/event-details'], {
      state: {
        isView: true,
        visitorData: event
        // visitorData: {
        //   name: event.name || 'NA',
        //   createdAt: event.createdAt || 'NA',
        //   location: event.location || 'NA',
        // }
      }
    });
  }

  public editButtonClick() {
    // this.isViewMode = false;
    this.editCustomerModal!.show();
  }

  public async saveButtonClick() {
    const name = this.form.get("name")!.value;
    const email = this.form.get("email")!.value;
    const phoneNumberControl = this.form.get("phone");
    const phoneNumber = phoneNumberControl && phoneNumberControl.value ? phoneNumberControl.value.toString() : ''
    const address = this.addressComponents.address;
    const city = this.addressComponents.city;
    const country = this.addressComponents.country;
    const state = this.addressComponents.state;
    const pincode = +this.addressComponents.pincode || undefined;
    const gender = this.form.get("gender")!.value;

    if (!name) {
      this.notifier.notify("error", "Name cannot be empty");
      return;
    }

    // if (!phoneNumber) {
    //   this.notifier.notify("error", "Phone Number cannot be empty");
    //   return;
    // }

    // if(!address){
    //   this.notifier.notify('error', 'Address cannot be empty');
    //   return;
    // }

    const contactsParams: ContactsParams = {
      name,
      email,
      phoneNumber,
      address,
      gender,
      city,
      state,
      pincode,
      country
    };
    if (this.imgURL && this.imgURL.name && this.imgURL.url) {
      contactsParams.profilePhoto = {
        name: this.imgURL.name,
        url: this.imgURL,
        __type: "File"
      };
    }
    await this.dataService.updateToServer(
      "classes/Contacts/" + this.contactObjectId,
      contactsParams
    );
    this.getContactDetails(this.contactObjectId);
    this.editCustomerModal!.hide();
    this.isViewMode = true;
    this.notifier.notify("success", "Contact Edited Successfully!!");
  }

  public backButtonClick() {
    //this.router.navigate(["/contacts"]);
    this._location.back();
  }

  public async onSelectFile(event: Event) {
    this.profilePhotoLoader = true;
    const files = (event.target as HTMLInputElement).files;
    if (files) {
      if (files[0].size / (1024 * 1024) > 1) {
        (event.target as HTMLInputElement).value = '';
        this.notifier.notify('error', 'Please select a file size less than 1 MB.');
        this.profilePhotoLoader = false;
        return;
      } else {
        this.imgURL = await this.dataService.uploadFile(files[0]);
      }
    }
    this.profilePhotoLoader = false;
  }

  public addressChange(address: any) {
    // setting address from API to local variable
    this.formattedaddress = address.formatted_address;

    this.addressComponents.address = address.formatted_address;
    for (const component of address.address_components) {
      // @ts-ignore remove once typings fixed
      const componentType = component.types[0];
      switch (componentType) {
        case "postal_code": {
          this.addressComponents.pincode = component.long_name;
          break;
        }
        case "locality":
          this.addressComponents.city = component.long_name;
          break;
        case "administrative_area_level_1": {
          this.addressComponents.state = component.long_name;
          break;
        }
        case "country":
          this.addressComponents.country = component.long_name;
          break;
      }
    }
  }

  public async getLeads(contactID: string) {
    const leadParams = {
      include: 'contact,status',
      where: {
        contact: {
          __type: 'Pointer',
          className: 'Contacts',
          objectId: contactID,
        },
      },
    };
    const lead = await this.dataService.getFromServer(
      'classes/Leads/',
      leadParams,
    );
    await this.getAllStagesList(lead.results);
    this.leadsData = lead.results || [];
  }

  public async getAllStagesList(leads: any){
    if(leads.length > 0) {
      const stageQuery = {
        where: {
          space: {
            __type: 'Pointer',
            className: 'Spaces',
            objectId: leads[0].space.objectId,
          },
        },
        order: 'order',
      };
      const resp = await this.dataService.getFromServer(
          'classes/Stages', stageQuery,
      );
      this.allStagesList = resp.results || [];
    }
  }

  public async getActivity(contactID: string) {
    const activityParams = {
      include: 'contact',
      where: {
        contact: {
          __type: 'Pointer',
          className: 'Contacts',
          objectId: contactID
        },
      },
    };
    const activity = await this.dataService.getFromServer(
      'classes/Activity',
      activityParams,
    );
    this.activityData = activity.results || [];
    console.log("activity data::::::",this.activityData)
  }

  public async getNotes(contactID: string) {
    const activityParams = {
      include: 'user',
      order: '-createdAt',
      where: {
        contact: {
          __type: 'Pointer',
          className: 'Contacts',
          objectId: contactID,
        },
        user: {
          __type: 'Pointer',
          className: '_User',
          objectId: this.userService.userId,
        },
      },
    };
    const resp = await this.dataService.getFromServer(
        'classes/Notes',
        activityParams,
    );
    this.notesData = resp.results || [];
  }

  public async getEvents(contactID: string) {
    const activityParams = {
      include: 'contact',
      where: {
        contact: {
          __type: 'Pointer',
          className: 'Contacts',
          objectId: contactID,
        },
      },
      order: 'createdAt',
    };
    const events = await this.dataService.getFromServer(
      'classes/Events',
      activityParams,
    );
    const filteredResults = events.results.filter((event: { name: string }) => event.name !== 'INIT');
    this.eventsData = await this.groupBy(filteredResults, 'createdAt');
  }

  public groupBy(objectArray: any, property: any) {
    const eventData =  objectArray.reduce((acc: any, obj: any) => {
      const key = moment(obj[property]).format('MMM DD, yyyy');
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
    return eventData && Object.entries(eventData).reverse();
  }

  public getTitle() {
    const titles: Record<string, string> = {
      "contact-info": "Customer Info",
      leads: "Leads",
      activity: "Activity"
    };
    return titles[this.id];
  }

  private intializeLeadForm() {
    this.newLeadForm = this.fb.group({
      objectId: ["", Validators.required],
      stage: [""],
      space: [""],
      product: [""],
      fullName: ["", Validators.required],
      email: [
        "",
        [Validators.pattern(validationPattern.email)]
      ],
      phone: [
        "",
        [
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern(validationPattern.phone)
        ]
      ],
      dealValue: [""],
      gender: [""],
      city: [""],
      address: [""],
      state: [""],
      pincode: [""],
      description: [""],
      nextActivity: [new Date(), Validators.required],
      activityNotes: [''],
      assignTo: [''],
      creator: [''],
      leadAssignTo: [''],
      contactId: [''],
      noOfSeats: ['', Validators.required],
      estimatedContractLength: ['', Validators.required],
      closingDate: ['', Validators.required],
    });

    this.newProductForm = this.fb.group({
      product_name: ['', Validators.required],
      product_description: ['', Validators.required],
      price_type: ['', Validators.required],
      price_period: ['', Validators.required],
      new_price: [, Validators.required],
      new_sheets: [, Validators.required],
    });
  }
  public async openNewLeadModal() {
    this.newleadModal!.show();
  }

  public addProduct(event: Event) {
    event.preventDefault();
    this.newProductModal!.show();
  }

  public async createProduct(){
    const productName = this.newProductForm.get('product_name')!.value;
    const productDescription = this.newProductForm.get('product_description')!.value;
    const productPriceType = this.newProductForm.get('price_type')!.value;
    const productPricePeriod = this.newProductForm.get('price_period')!.value;
    const productPrice = this.newProductForm.get('new_price')!.value;
    const productNoOfSeats = this.newProductForm.get('new_sheets')!.value;
    
    if(!productName){
      this.notifier.notify('error', 'Name cannot be empty');
      return
    }
    if (!productDescription) {
      this.notifier.notify("error", "Description cannot be empty");
      return;
    }
    if(!productPriceType){
      this.notifier.notify('error', 'Price Type cannot be empty');
      return
    }
    // if(!productPricePeriod){
    //   this.notifier.notify('error', 'Price Period cannot be empty');
    //   return
    // }
    if(!productPrice){
      this.notifier.notify('error', 'Price cannot be empty');
      return
    }
    // if(!productNoOfSeats){
    //   this.notifier.notify('error', 'No Of Seats cannot be empty');
    //   return
    // }
    const productParams: Products = {
      name: productName,
      description: productDescription,
      entity: {
        __type: "Pointer",
        className: "Entity",
        objectId: this.authService.getUser().entityId.objectId
      },
      website: '',
      objectId: '',
      priceType: productPriceType,
      pricePeriod: productPricePeriod,
      price: productPrice,
      noOfSeats: productNoOfSeats,
    };
    const productResponse = await this.dataService.postToServer(
      "classes/Products/",
      productParams
    );
    if (productResponse) {
      this.notifier.notify("success", "Product created Successfully!!!");
      this.productID = productResponse.objectId;
      this.newProductForm.reset();
      this.newProductModal!.hide();
    }
    //this.ngxLoader.start();
    this.getProducts();
    // this.initializeLeadForm();
    this.newLeadForm.get("product")!.setValue(productName);
    //this.ngxLoader.stop();
  }

  public async getProducts() {
    let productsQuery;
    if (!this.authService.isSuperAdmin()) {
      productsQuery = {
        where: {
          entity: {
            __type: "Pointer",
            className: "Entity",
            objectId: this.authService.getUser().entityId.objectId
          }
        }
      };
    }
    const resp = await this.dataService.getFromServer(
      "classes/Products",
      productsQuery
    );
    this.productList = resp.results;
  }

  public async getSpaces() {
    const spacesQuery = {
      where: {
        entity: {
          __type: "Pointer",
          className: "Entity",
          objectId: this.authService.getUser().entityId.objectId
        },
        isArchived: { $ne: true }
      }
    };
    const resp = await this.dataService.getFromServer(
      "classes/Spaces",
      spacesQuery
    );
    this.spacesList = resp.results;
    if (this.spacesList.length > 0) {
      this.selectedSpace = this.spacesList[0].objectId;
      this.getStagesList();
    }
  }

  public async getStagesList() {
    const stageQuery = {
      where: {
        space: {
          __type: "Pointer",
          className: "Spaces",
          objectId: this.selectedSpace
        }
      }
    };
    const resp = await this.dataService.getFromServer(
      "classes/Stages",
      stageQuery
    );
    this.stagesList = resp.results;
  }

  public async createLead() {
    try {
      const dealValue = parseInt(this.newLeadForm.get('dealValue')!.value);
      const description = this.newLeadForm.get('description')!.value;
      const nextActivity = this.newLeadForm.get('nextActivity')!.value;
      const activityNotes = this.newLeadForm.get('activityNotes')!.value;
      let assignTo = '';
      const leadAssignTo = this.newLeadForm.get('leadAssignTo')!.value;
      const stageObjectId = this.newLeadForm.get('stage')!.value;
      const closingDate = this.newLeadForm.get('closingDate')!.value;
      let productId = this.newLeadForm.get('product')!.value;
      let noOfSeats = this.newLeadForm.get('noOfSeats')!.value;
      let estimatedContractLength = this.newLeadForm.get('estimatedContractLength')!.value;
      const category = 'Lead';

      if (this.productID) {
        productId = this.productID;
      }
      if (!dealValue) {
        return this.notifier.notify("error", "Please Enter Valid Deal Value");
      }
      if (!closingDate) {
        return this.notifier.notify("error", "Please Enter Valid Closing Date");
      }
      if (!stageObjectId) {
        return this.notifier.notify("error", "Please Select Stage");
      }
      if (!description) {
        return this.notifier.notify("error", "Please Enter Description");
      }
      if(this.isRecurringPrice()){
        if (!noOfSeats) {
          return this.notifier.notify('error', 'Please Enter Number of Subscriptions');
        }
      }
      if(this.isRecurringPrice()){
        if (!estimatedContractLength) {
          return this.notifier.notify('error', 'Please Enter Estimated Contract Length');
        }
      }
      if (this.authService.isAgent()) {
        assignTo = this.authService.getUser().objectId;
      } else {
        assignTo = this.newLeadForm.get("assignTo")!.value;
      }
      this.newleadModal!.hide();
      const leadParams: LeadParams = {
        dealValue,
        description,
        stage: {
          __type: "Pointer",
          className: "Stages",
          objectId: stageObjectId
        },
        product: {
          __type: "Pointer",
          className: "Products",
          objectId: productId
        },
        category,
        assignTo: {
          __type: "Pointer",
          className: "_User",
          objectId: leadAssignTo ? leadAssignTo : this.currentUser!.objectId
        },
        contact: {
          __type: "Pointer",
          className: "Contacts",
          objectId: this.contactObjectId
        },
        activityNotes,
        nextActivity: {
          __type: "Date",
          iso: new Date(nextActivity)
        },
        creator: {
          __type: "Pointer",
          className: "_User",
          objectId: this.currentUser!.objectId
        },
        entity: {
          __type: "Pointer",
          className: "Entity",
          objectId: this.authService.getUser().entityId.objectId
        },
        closingDate: {
          __type: "Date",
          iso: new Date(closingDate)
        },
        space: {
          __type: "Pointer",
          className: "Spaces",
          objectId: this.selectedSpace
        }
      };

      if (assignTo) {
        leadParams.agent = {
          __type: "Pointer",
          className: "_User",
          objectId: assignTo
        };
      }
      if(noOfSeats){
        leadParams.noOfSeats = noOfSeats
      }      
      if(estimatedContractLength){
        leadParams.estimatedContractLength = estimatedContractLength
      }      
      await this.leadsService.addLead(leadParams);
      track("NEW_LEAD");
      await this.dataService.updateToServer(
        "classes/Contacts/" + this.contactObjectId,
        {
          leadsCount: { __op: "Increment", amount: 1 },
          lastActivity: {
            __type: "Date",
            iso: new Date()
          }
        }
      );
      this.router.navigate(["/leads/" + this.selectedSpace]);
    } catch (e) {
      this.notifier.notify("error", e.error.error);
    }
  }

  isRecurringPrice(): boolean {
    const productId = this.newLeadForm.get('product')!.value;
    if(productId && this.productList){
      const product = this.productList.find(product => product.objectId === productId);
      return product && product.priceType === 'recurring' ? true : false;
    } 
    else{
      return false;
    }
  }

  public isFieldValid(field: string, currentForm: FormGroup) {
    if (currentForm.get(field) && !this.disableFields) {
      return !currentForm.get(field)!.valid && currentForm.get(field)!.touched;
    }
    return;
  }

  public displayFieldCss(field: string, currentForm: FormGroup) {
    return {
      "has-error": this.isFieldValid(field, currentForm),
      "has-feedback": this.isFieldValid(field, currentForm)
    };
  }

  public convertDate(date: string) {
    if(date) {
      return moment(date).format('DD-MMM-YYYY');
    }
    return;
  }

  public convertNotesDate(date: string) {
    if(date) {
      return moment(date).fromNow();
    }
    return 'NA';
  }

  public async saveNotes() {
    try {
      const userParams: any = {
        contact: {
          __type: 'Pointer',
          className: 'Contacts',
          objectId: this.contactObjectId,
        },
        user: {
          __type: 'Pointer',
          className: '_User',
          objectId: this.userService.userId,
        },
        content: this.notesForm.get('notesText')!.value,
      };
      if(this.notesForm.get('notesText')!.value) {
        await this.dataService.postToServer('classes/Notes', userParams);
        const localUser = sessionStorage.getItem('user') || localStorage.getItem('user') || '{}';
        const user = JSON.parse(localUser);
        this.notesData = [{
            content: this.notesForm.get('notesText')!.value,
            createdAt: new Date().toISOString(),
            user: {
              name: user.name || 'NA',
              profilePhoto: user.profilePhoto || '',
            },
          },...this.notesData];
        this.notifier.notify('success', 'Notes Added Successfully!!');
      }else {
        this.notifier.notify('error', 'Notes cannot be empty');
      }
      this.notesForm.reset();
    } catch (e) {
      this.notifier.notify('error', e.error.error);
    }
  }

  //calculating Expected revenue based on no.of subscriptions and estimated contract length
  public onChangeSubsVal(event: Event, prod: any, form: FormGroup) {
    const subsValue = +(event.target as HTMLInputElement).value;
    const eclValue = form.get('estimatedContractLength')!.value;
    this.calcExpRevenue(prod, form, subsValue, eclValue ? eclValue : 1 );
  }

  public onChangeEclVal(event: Event, prod: any, form: FormGroup) {
    const eclValue = +(event.target as HTMLInputElement).value;
    const subsValue = form.get('noOfSeats')!.value;
    this.calcExpRevenue(prod, form, subsValue ? subsValue : 1, eclValue);
  }

  public calcExpRevenue(prod: any, form: FormGroup, subsValue:any, eclValue:any) {
    const selectedProduct = this.productList.find((product) => product.objectId === prod);
    let factor = 1;
    if(selectedProduct.pricePeriod === 'monthly'){
      factor = 12;
    } else if(selectedProduct.pricePeriod === 'weekly'){
      factor = 52;
    } else if(selectedProduct.pricePeriod === 'quarterly'){
      factor = 4;
    } else if(selectedProduct.pricePeriod === 'halfyearly'){
      factor = 2;
    }
    const finalExpRevenue = (subsValue * eclValue * selectedProduct.price * factor);
    form.get('dealValue')!.patchValue(finalExpRevenue);
  }

}
