import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public configData: any;

  constructor(
    public dataService: DataService,
  ) { }

  public async getConfigData() {
    const resp = await this.dataService.getFromServer("Config");
    this.configData = resp.params;
    return this.configData;
  }
}
