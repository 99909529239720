import { Injectable } from '@angular/core';
import { PostResponse } from '../types/common';
import {AllDocumentParams} from '../types/documents';
import { DataService } from './data.service';
import { UserService } from './user.service';

@Injectable()
export class DocumentsService {
  constructor(
    private dataService: DataService,
    private userService: UserService,
  ) {}

  public async loadDocuments(): Promise<AllDocumentParams[]> {
    const documentParams = {
      include: 'user',
    };
    const documents = await this.dataService.getFromServer(
      'classes/Documents',
      documentParams,
    );
    let filteredDocuments: AllDocumentParams[];
    if (this.userService.isAdmin) {
      filteredDocuments = documents.results;
    } else {
      filteredDocuments = documents.results.filter((u: AllDocumentParams) => {
        return u.user && u.user!.objectId === this.userService.userId;
      });
    }
    return filteredDocuments;
  }

  public async addDocument(documentDetails: AllDocumentParams): Promise<PostResponse> {
    const response = await this.dataService.postToServer(
      'classes/Documents',
      documentDetails,
    );
    return response;
  }

  public async uploadDocument(file: File) {
    return await this.dataService.uploadFile(file);
  }

  public async deleteDocument(documentId: string) {
    return await this.dataService.deleteOnServer(
      'classes/Documents/' + documentId,
    );
  }
}
